import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CategoryBreadcrumbs = (props) => {

    return (
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="category-bredcrumbs">
                    <div className="breadcums-info">
                        <div className="breadcrumbs-arrow">
                            <Link to="">
                                <img src="../../../../images/Icons/bredcumes-back.png" />
                            </Link>
                        </div>
                        <div className="breadcrums-titles">
                            <Link to="/">Home </Link>
                            <Link> / {props.catename}</Link>
                            
                        </div>
                    </div>
                    <div className="category-search">
                        <img src="../../../../images/Icons/search.svg" />
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategoryBreadcrumbs;
