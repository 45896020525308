import React, {} from 'react';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import CourseDetailItems from './components/CourseDetailItems';

const CourseDetail =(props)=> {
    return (
        <>
            <MainHeader/>
            <CourseDetailItems/>
            <Footer/>
        </>
    );

}

export default CourseDetail;