import React from 'react';
import {Form} from "react-bootstrap";

const InputWithIcon =(props)=> {

        return (
            <>
                <Form.Group controlId="formBasicEmail" className="label-input">
                    <Form.Label>{props.label}</Form.Label>
                    <div className={props.errors ? "inputwith-icon error" : "inputwith-icon"}>
                        <input className={props.className} onChange={props.onChange} type={props.type} name={props.name} value={props.value} placeholder={props.placeholder} disabled={props.disabled} />
                        <img className="cursr" onClick={props.onClick} src={props.icon}/>
                    </div>
                    <span className="validation-error">{props.errors}</span>
                </Form.Group>
            </>
        );
}

export default InputWithIcon;