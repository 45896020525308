import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const QuizBreadcrumbs = (props) => {

    return (
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="breadcums-info">
                    <div className="breadcrumbs-arrow">
                        <Link to="">
                            <img src="../../../../images/Icons/bredcumes-back.png" />
                        </Link>
                    </div>
                    <div className="breadcrums-titles">
                        <Link>Courses </Link>
                        <Link> / {props.courses.course_category.name}</Link>
                        <Link> / {props.courses.Name}</Link>
                        <Link> / Quiz Questions</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizBreadcrumbs;
