import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import HelpModal from '../../../components/Modals/HelpModal';

const DashboardBredcrumbs = (props) => {

    const [helpModal, sethelpModal] = useState(false);
    const helpModalClose = () => sethelpModal(false);
    const helpModalShow = () => sethelpModal(true);

    return (
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="category-bredcrumbs">
                    <div className="breadcums-info">
                        <div className="breadcrumbs-arrow">
                            <Link to="">
                                <img src="../../../../images/Icons/bredcumes-back.png" />
                            </Link>
                        </div>
                        <div className="d-flex align-items-center breadcrums-titles">
                            <Link to="/">Home </Link>
                            <div className="d-flex align-items-center ml-2">
                            <Link> / {props.dashname}</Link>
                            <span onClick={helpModalShow} className="tooltip-icons-new"><img src={props.tooltip}/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             {/*  Help Modal */}
             <Modal show={helpModal} onHide={helpModalClose}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   size="md"
                   className="authModal cardModal helppopup"
            >
                <HelpModal
                 close={helpModalClose}
                 onClick={helpModalClose}
                />
            </Modal>
        </div>
    );
}

export default DashboardBredcrumbs;
