import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Gdc5YearBreadcrumbs = (props) => {

    return (
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="category-bredcrumbs">
                    <div className="breadcums-info">
                        <div className="breadcrumbs-arrow">
                            <Link to="">
                                <img src="../../../../images/Icons/bredcumes-back.png" />
                            </Link>
                        </div>
                        <div className="d-flex align-items-center breadcrums-titles">
                            <Link to="/">Home </Link>
                            <div className="d-flex align-items-center ml-2">
                            <Link to="/pdphub"> / Personal Development Planing </Link>
                            <Link className="pl-2"> /  GDC 5-year Cycle Statement</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gdc5YearBreadcrumbs;
