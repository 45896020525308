import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import UrlResolver from '../../../../../helpers/UrlResolver';



const AspireNewBoxes = (props) => {

    if (!props.talkDentals) {
        return <></>
    }
    return (
        <div className="dental-boxes">
            <div className='dental-new-design'>
                {props.talkDentals.map((talkDental, index) => {
                    if (((index + 1) % 3) != 0) {
                        return <div className='new-news-boxes'>
                            <div className="dental-images" >
                                <img src={UrlResolver.generateMediaUrl(talkDental.banner.url)} alt="" />
                            </div>
                            <div className="boxes-metas">
                                <Link to={`/aspirenews/${talkDental.id}`}>
                                    <h4>{talkDental.title}</h4>
                                    <h6>FEBRUARY 11, 2022</h6>
                                    <p>{`${talkDental.description.substring(0, 160)}...`}
                                    </p>
                                    <span>Read More</span>
                                </Link>
                            </div>
                        </div>
                    } else {
                        return <div className='new-news-boxes height-long'>
                            <div className="dental-images" style={{ backgroundImage: `url(${UrlResolver.generateMediaUrl(talkDental.banner.url)})` }}>
                                {/* <img src={UrlResolver.generateMediaUrl(talkDental.banner.url)} alt="" /> */}
                            </div>
                            <div className="boxes-metas">
                                <Link to={`/aspirenews/${talkDental.id}`}>
                                    <h4>{talkDental.title}</h4>
                                    <h6>FEBRUARY 11, 2022</h6>
                                    <p>{`${talkDental.description.substring(0, 160)}...`}
                                    </p>
                                    <span>Read More</span>
                                </Link>
                            </div>
                        </div>
                    }
                })}
            </div>
        </div>
    );

}

export default AspireNewBoxes;