import React from 'react';
import DefaultHeading from "../../../components/Heading/DefaultHeading";
import CourseSlider from "../../../components/Sliders/CourseSlider";


const PopularCourseSection =(props)=> {
        return (
            <>
                <div className="popular-courses-section">
                    <DefaultHeading
                        heading="Popular Courses"
                    />
                    <div className="container w-90">
                        <div className="courseslider-boxes">
                            <CourseSlider courses={props.courses}/>
                        </div>
                    </div>
                </div>
            </>
        );
}

export default PopularCourseSection;