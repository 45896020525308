import React, {useState} from 'react';
import QuizHeading from './QuizHeading';
import QuizQuestions from './QuizQuestions';

const QuizItems = (props) => {

    console.log(props.courses.quizs);
    return (
        <div className="quiz-question-section">
            <div className="container">
                <QuizHeading />
                <div className="quiz-questions">
                    <QuizQuestions  Quizs={props.courses.quizs} courseId={props.courses.id}/>
                </div>
            </div>
        </div>
    );

}

export default QuizItems;