import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Form } from "react-bootstrap";
import ApiService from '../../../helpers/ApiService';
import SimpleButton from '../Buttons/SimpleButton';
import rehypeFilter from 'react-markdown/lib/rehype-filter';

const AddSlotsModal = (props) => {
    //console.log(props.mentorId);
    let api = new ApiService();

    const [addTimeSlots, setAddTimeSlots] = React.useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    

    const [inputFields, setInputFields] = useState([{
        starttimem: '', endtimem: '',
    }]);

    const [inputFieldst, setInputFieldst] = useState([{
        starttimet: '', endtimet: '',
    }]);

    const [inputFieldsw, setInputFieldsw] = useState([{
        starttimew: '', endtimew: '',
    }]);

    const [inputFieldsth, setInputFieldsth] = useState([{
        starttimeth: '', endtimeth: '',
    }]);

    const [inputFieldsf, setInputFieldsf] = useState([{
        starttimef: '', endtimef: '',
    }]);

    const [inputFieldssa, setInputFieldssa] = useState([{
        starttimesa: '', endtimesa: '',
    }]);

    const [inputFieldssu, setInputFieldssu] = useState([{
        starttimesu: '', endtimesu: '',
    }]);



   

    //console.log(inputFields);

   const addInputField = () => {
        setInputFields([...inputFields, {
            starttimem: '', endtimem: '',
        }])

    }
    const addInputFieldt = () => {
        setInputFieldst([...inputFieldst, {
            starttimet: '', endtimet: '',
        }])

    }
    const addInputFieldw = () => {
        setInputFieldsw([...inputFieldsw, {
            starttimew: '', endtimew: '',
        }])

    }
    const addInputFieldth = () => {
        setInputFieldsth([...inputFieldsth, {
            starttimeth: '', endtimeth: '',
        }])

    }
    const addInputFieldf = () => {
        setInputFieldsf([...inputFieldsf, {
            starttimef: '', endtimef: '',
        }])

    }
    const addInputFieldsa = () => {
        setInputFieldssa([...inputFieldssa, {
            starttimesa: '', endtimesa: '',
        }])

    }
    const addInputFieldsu = () => {
        setInputFieldssu([...inputFieldssu, {
            starttimesu: '', endtimesu: '',
        }])

    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    const removeInputFieldst = (index) => {
        const rows = [...inputFieldst];
        rows.splice(index, 1);
        setInputFieldst(rows);
    }

    const removeInputFieldsw = (index) => {
        const rows = [...inputFieldsw];
        rows.splice(index, 1);
        setInputFieldsw(rows);
    }

    const removeInputFieldsth = (index) => {
        const rows = [...inputFieldsth];
        rows.splice(index, 1);
        setInputFieldsth(rows);
    }

    const removeInputFieldsf = (index) => {
        const rows = [...inputFieldsf];
        rows.splice(index, 1);
        setInputFieldsf(rows);
    }

    const removeInputFieldssa = (index) => {
        const rows = [...inputFieldssa];
        rows.splice(index, 1);
        setInputFieldssa(rows);
    }

    const removeInputFieldssu = (index) => {
        const rows = [...inputFieldssu];
        rows.splice(index, 1);
        setInputFieldssu(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        let error = '';
        list[index][name] = value;
       
        if(name == 'starttimem'){
            list.map((x, ind)=>{
                if(index != ind){}
                if((x.starttimem <= list[index][name] && x.endtimem > list[index][name] )){
                  alert('This time slot already taken');
                  list[index][name] = ''; 
                 }
            })
        }

        if(name == 'endtimem'){
            list.map((x, ind)=>{
                if(index != ind){}
                if((x.starttimem < list[index][name] && x.endtimem > list[index][name] )){
                    alert('This time slot already taken');
                    list[index][name] = '';
                  }
            })
        }
        
        setInputFields(list);
    }

    const handleChanget = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFieldst];
        list[index][name] = value;
        setInputFieldst(list);
    }

    const handleChangew = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFieldsw];
        list[index][name] = value;
        setInputFieldsw(list);
    }

    const handleChangeth = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFieldsth];
        list[index][name] = value;
        setInputFieldsth(list);
    }

    const handleChangef = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFieldsf];
        list[index][name] = value;
        setInputFieldsf(list);
    }

    const handleChangesa = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFieldssa];
        list[index][name] = value;
        setInputFieldssa(list);
    }

    const handleChangesu = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFieldssu];
        list[index][name] = value;
        setInputFieldssu(list);
    }

    useEffect( () => {
        if(props.mentorId === 0){

            setInputFields([{ starttimem: '', endtimem: '' }]);

            setInputFieldst([{ starttimet: '', endtimet: '' }]);

            setInputFieldsw([{ starttimew: '', endtimew: '' }]);

            setInputFieldsth([{ starttimeth: '', endtimeth: '' }]);

            setInputFieldsf([{ starttimef: '', endtimef: '' }]);

            setInputFieldssa([{ starttimesa: '', endtimesa: '' }]);

            setInputFieldssu([{ starttimesu: '', endtimesu: '' }]);

        } else {
            //console.log(props.mentorId[0].bookslots);
            setInputFields(props.mentorId[0].bookslots);

            if(props.mentorId[1]) {
              setInputFieldst(props.mentorId[1].bookslots);
            }

            if(props.mentorId[2]) {
                setInputFieldsw(props.mentorId[2].bookslots);
              }

              if(props.mentorId[3]) {
                setInputFieldsth(props.mentorId[3].bookslots);
              }

              if(props.mentorId[4]) {
                setInputFieldsf(props.mentorId[4].bookslots);
              }

              if(props.mentorId[5]) {
                setInputFieldssa(props.mentorId[5].bookslots);
              }

              if(props.mentorId[6]) {
                setInputFieldssu(props.mentorId[6].bookslots);
              }
        }

        let api = new ApiService();

        api.getSlots().then(_slots => {
            setAddTimeSlots(_slots);
          }).catch((e) => {

          });

    }, [])

    const handleSubmit = async (day) => {

        //event.preventDefault();
        if (validate()) {
            setLoading(true);

            //console.log(inputFields);

            if(day == 1){
                let data = {
                    bookslots: inputFields,
                    day:day,
                }

                if(props.mentorId[0]) {

                    api.updateDactorSlots(data) 
                    .then(response => {
                        alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

                } else {

                    api.setDactorSlots(data)
                    .then(response => {
                        alert("added");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })
                }
            } else if(day == 2){
                let data = {
                    bookslots: inputFieldst,
                    day:day,
                }

                if(props.mentorId[1]) {

                    api.updateDactorSlots(data)
                    .then(response => {
                        alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

                } else {
                    
                    api.setDactorSlots(data)
                    .then(response => {
                        alert("added");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })
                }
            }  else if(day == 3){
                let data = {
                    bookslots: inputFieldsw,
                    day:day,
                }

                if(props.mentorId[2]) {

                    api.updateDactorSlots(data)
                    .then(response => {
                        alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

                } else {
                    
                    api.setDactorSlots(data)
                    .then(response => {
                        alert("added");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })
                }
            }  else if(day == 4){
                let data = {
                    bookslots: inputFieldsth,
                    day:day,
                }

                if(props.mentorId[3]) {

                    api.updateDactorSlots(data)
                    .then(response => {
                        alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

                } else {
                    
                    api.setDactorSlots(data)
                    .then(response => {
                        alert("added");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })
                }
            }  else if(day == 5){
                let data = {
                    bookslots: inputFieldsf,
                    day:day,
                }

                if(props.mentorId[4]) {

                    api.updateDactorSlots(data)
                    .then(response => {
                        alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

                } else {
                    
                    api.setDactorSlots(data)
                    .then(response => {
                        alert("added");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })
                }
            }   else if(day == 6){
                let data = {
                    bookslots: inputFieldssa,
                    day:day,
                }

                if(props.mentorId[5]) {

                    api.updateDactorSlots(data)
                    .then(response => {
                        alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

                } else {
                    
                    api.setDactorSlots(data)
                    .then(response => {
                        alert("added");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })
                }
            }   else if(day == 7){
                let data = {
                    bookslots: inputFieldssu,
                    day:day,
                }

                if(props.mentorId[6]) {

                    api.updateDactorSlots(data)
                    .then(response => {
                        alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

                } else {
                    
                    api.setDactorSlots(data)
                    .then(response => {
                        alert("added");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({ error1: errors.message[0].messages[0].message });
                        setLoading(false);
                    })
                }
            } 

        }
       };

   const validate = () => {
        let errors = {};
        let isValid = true;
        setErrors(errors);
        return isValid;
    }

    let dBookSlot = 0;
    if(props.mentorId === 0) {
        dBookSlot = 0;
    } else {
        dBookSlot = props.mentorId;
        
    }
    
    if(addTimeSlots.length === 0){
        return <>There are no Slots...</>;
    } else {
      // console.log(inputFields);
    }

    return (
        <div className="dark">
            <div className="d-flex align-items-center justify-content-between patient-header">
                <div className="pay-card">
                    <span style={{ color: '#ffffff' }}>Add Slots</span>
                </div>
                <div className="cross-photo" onClick={props.close}>
                    <img src="../../../../images/Icons/closs-icon.svg" /> 
                </div>
            </div>
            <div className='slots-tabs'>
                <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                    <Tab label="Monday">
                        <div className='add-remove-form'>
                            <div className="row">
                                <div className="col-sm-12">
                                 { inputFields.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Start Time</label>
                                                            <select className='whitecolor' onChange={(evnt) => handleChange(index, evnt)} name='starttimem' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option >Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option className='blackcolor' value={timeslots.slotName} selected={timeslots.slotName === data.starttimem && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>End Time</label>
                                                            <select className='whitecolor'  onChange={(evnt) => handleChange(index, evnt)} name='endtimem' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option className='blackcolor' value={timeslots.slotName} selected={timeslots.slotName === data.endtimem && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 remove-for-mentor">
                                                        {(inputFields.length !== 1) ? <button className="btn btn-outline-danger" onClick={() => removeInputFields(index)}>x</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                   <div className="add-more-button">
                                        <button className="btn btn-outline-success " onClick={addInputField}>Add More</button>
                                    </div>
                                    <div className="slot-button">
                                    <SimpleButton
                                        name="Save"
                                        onClick={() => handleSubmit('1')}
                                        loading={loading}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Tuesday">
                    <div className='add-remove-form'>
                            <div className="row">
                                <div className="col-sm-12">
                                 { inputFieldst.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Start Time</label>
                                                            <select onChange={(evnt) => handleChanget(index, evnt)} name='starttimet' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.starttimet && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>End Time</label>
                                                            <select onChange={(evnt) => handleChanget(index, evnt)} name='endtimet' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.endtimet && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {(inputFieldst.length !== 1) ? <button className="btn btn-outline-danger" onClick={() => removeInputFieldst(index)}>x</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                   <div className="add-more-button">
                                        <button className="btn btn-outline-success " onClick={addInputFieldt}>Add More</button>
                                    </div>
                                    <div className="slot-button">
                                    <SimpleButton
                                        name="Save"
                                        onClick={() => handleSubmit('2')}
                                        loading={loading}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Wednesday">
                    <div className='add-remove-form'>
                            <div className="row">
                                <div className="col-sm-12">
                                 { inputFieldsw.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Start Time</label>
                                                            <select onChange={(evnt) => handleChangew(index, evnt)} name='starttimew' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.starttimew && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>End Time</label>
                                                            <select onChange={(evnt) => handleChangew(index, evnt)} name='endtimew' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.endtimew && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {(inputFieldsw.length !== 1) ? <button className="btn btn-outline-danger" onClick={() => removeInputFieldsw(index)}>x</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                   <div className="add-more-button">
                                        <button className="btn btn-outline-success " onClick={addInputFieldw}>Add More</button>
                                    </div>
                                    <div className="slot-button">
                                    <SimpleButton
                                        name="Save"
                                        onClick={() => handleSubmit('3')}
                                        loading={loading}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Thursday">
                    <div className='add-remove-form'>
                            <div className="row">
                                <div className="col-sm-12">
                                 { inputFieldsth.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Start Time</label>
                                                            <select onChange={(evnt) => handleChangeth(index, evnt)} name='starttimeth' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.starttimeth && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>End Time</label>
                                                            <select onChange={(evnt) => handleChangeth(index, evnt)} name='endtimeth' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.endtimeth && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {(inputFieldsth.length !== 1) ? <button className="btn btn-outline-danger" onClick={() => removeInputFieldsth(index)}>x</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                   <div className="add-more-button">
                                        <button className="btn btn-outline-success " onClick={addInputFieldth}>Add More</button>
                                    </div>
                                    <div className="slot-button">
                                    <SimpleButton
                                        name="Save"
                                        onClick={() => handleSubmit('4')}
                                        loading={loading}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Friday">
                    <div className='add-remove-form'>
                            <div className="row">
                                <div className="col-sm-12">
                                 { inputFieldsf.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Start Time</label>
                                                            <select onChange={(evnt) => handleChangef(index, evnt)} name='starttimef' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.starttimef && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>End Time</label>
                                                            <select onChange={(evnt) => handleChangef(index, evnt)} name='endtimef' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.endtimef && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {(inputFieldsf.length !== 1) ? <button className="btn btn-outline-danger" onClick={() => removeInputFieldsf(index)}>x</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                   <div className="add-more-button">
                                        <button className="btn btn-outline-success " onClick={addInputFieldf}>Add More</button>
                                    </div>
                                    <div className="slot-button">
                                    <SimpleButton
                                        name="Save"
                                        onClick={() => handleSubmit('5')}
                                        loading={loading}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Saturday">
                    <div className='add-remove-form'>
                            <div className="row">
                                <div className="col-sm-12">
                                 { inputFieldssa.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Start Time</label>
                                                            <select onChange={(evnt) => handleChangesa(index, evnt)} name='starttimesa' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.starttimesa && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>End Time</label>
                                                            <select onChange={(evnt) => handleChangesa(index, evnt)} name='endtimesa' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.endtimesa && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {(inputFieldssa.length !== 1) ? <button className="btn btn-outline-danger" onClick={() => removeInputFieldssa(index)}>x</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                   <div className="add-more-button">
                                        <button className="btn btn-outline-success " onClick={addInputFieldsa}>Add More</button>
                                    </div>
                                    <div className="slot-button">
                                    <SimpleButton
                                        name="Save"
                                        onClick={() => handleSubmit('6')}
                                        loading={loading}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Sunday">
                    <div className='add-remove-form'>
                            <div className="row">
                                <div className="col-sm-12">
                                 { inputFieldssu.map((data, index) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Start Time</label>
                                                            <select onChange={(evnt) => handleChangesu(index, evnt)} name='starttimesu' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.starttimesu && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>End Time</label>
                                                            <select onChange={(evnt) => handleChangesu(index, evnt)} name='endtimesu' style={{backgroundImage:`url(${'../../../../images/Icons/drop-arrow.svg'})`,}} >
                                                                <option>Select Slot</option>
                                                                {addTimeSlots.map(timeslots => {
                                                                return <option value={timeslots.slotName} selected={timeslots.slotName === data.endtimessu && 'selected'}>{timeslots.slotName}</option>
                                                              })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {(inputFieldssu.length !== 1) ? <button className="btn btn-outline-danger" onClick={() => removeInputFieldssu(index)}>x</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                   <div className="add-more-button">
                                        <button className="btn btn-outline-success " onClick={addInputFieldsu}>Add More</button>
                                    </div>
                                    <div className="slot-button">
                                    <SimpleButton
                                        name="Save"
                                        onClick={() => handleSubmit('7')}
                                        loading={loading}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default AddSlotsModal;
