import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ApiService from '../../../../helpers/ApiService';

const BrowseDropDown = (props) => {

    const [categories, setCategories] = React.useState([]);

    useEffect(async () => {

        let api = new ApiService();

        api.getCategories().then(_cats => {
            let sortCat = _cats.sort((a, b) => a.verticalPosition - b.verticalPosition);
            setCategories(sortCat.filter(cat => {
                return cat.parent === null && cat.verticalPosition != 0;
            }));
        }).catch((e) => {

        });

    }, [])

        return (
                <div className="header-links-dropdown-content">
                    <ul>
                    {categories.map(category => {
                                    return <li>
                                        {category.name == 'Aspire Journal Club' ? <Link to="/journalcategory" className="">{category.name}</Link>
                                            : category.name == 'Aspire Students' ? <Link to="/students/2" className="">{category.name}</Link>
                                                : category.name == 'Aspire Foundation' ? <Link to="foudationyears/1" className="">{category.name}</Link>
                                                    : category.name == 'Aspire Live' ? <Link to="#" className="">{category.name}</Link>
                                                        : category.name == 'Aspire News' ? <Link to="/aspirenews" className="">{category.name}</Link>
                                                            : category.name == 'Aspire Mentorship' ? <Link to="/mentorship" className="">{category.name}</Link>
                                                                : <Link to={`/coursecategory/${category.id}`} className="">{category.name}</Link>
                                        }
                                    </li>
                                })}
                    </ul>
                </div>
        );
}

export default BrowseDropDown;