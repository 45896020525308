import React from 'react';
import {Link} from "react-router-dom";
import { connect } from "react-redux";
import MainHeader from "../../components/Header/MainHeader";
import Footer from "../../components/Footer/Footer";
import UserProfileItem from "./components/UserProfileItem";


const UserProfiles =(props)=> {

   return (
            <>
                <MainHeader/>
                <div className="Profile-section">
                    <div className="container">
                        <div className="bredcums-subs">
                            <div to="/" className="d-flex align-items-center">
                                <img src="../../../images/Icons/bredcumes-back.png"/>
                                <div className="d-flex profile-bredcum">
                                    <Link to="/">
                                        <span className="pl-3">Home</span>
                                    </Link>
                                    <span className="pl-2 pr-2">/</span>
                                    <Link to="/profile">
                                        <span className="active-screen">Profile</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="profile-tabs">
                            <UserProfileItem user={ props.user }/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
    );
}

function mapStateToProps(state) {
    return {user:state.user}
}



export default connect(mapStateToProps)(UserProfiles);