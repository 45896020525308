import React from 'react';
import {Link, useParams} from "react-router-dom";
import UrlResolver from '../../../../../../helpers/UrlResolver';

const CompetitionsList = (props) => {
    if(!props.Items) {
        return <></>
    }
    return (
        <>
            <div className="events-list-items">
            {props.Items.map(item => {
             return <div className="events-box">
                    <div className="events-image-col">
                        <img src={UrlResolver.generateMediaUrl(item.banner.url)} alt={item.banner.hash} />
                    </div>
                    <div className="Events-meta">
                        <div className="event-meta-info">
                            <span>Name </span>
                            <p>{item.title}</p>
                        </div>
                        <div className="event-meta-info">
                            <span>Description: </span>
                            <p>{item.description}</p>
                        </div>
                        <div className="event-meta-info">
                            <span>Location </span>
                            <p>{item.location}</p>
                        </div>
                        <div className="event-meta-info">
                            <span>Date </span>
                            <p>{item.date}</p>
                        </div>
                        <div className="event-links">
                        <a href={item.link} target="_blank" >View Detail</a>
                        </div>
                    </div>
                </div>
            })}
            </div>
        </>
    );

}

export default CompetitionsList;