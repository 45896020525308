import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SimpleHeading from '../../../../../components/Heading/SimpleHeading';



const PersonalInformation = (props) => {

    return (
        <div className="personal-info-left">
            <SimpleHeading heading="Personal Information"/>
            <div className="personalinfo-box">
                <div >
                    <span>Name -</span>
                    <p>Stacey Firman</p>
                </div>
                <div >
                    <span>Job Role -</span>
                    <p>Practice Manager</p>
                </div>
                <div >
                    <span>GDC Number -</span>
                    <p>123456789</p>
                </div>
            </div>
        </div>
    );
}

export default PersonalInformation;
