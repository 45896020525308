import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; 
import rehypeRaw from 'rehype-raw';
import { Link } from "react-router-dom";
import CategoryRightSection from "./CategoryRightSection";




const CategoryLeftSection = (props) => {
    
    return (
         <div className="category-left-section"> 
            <h3>{props.Cats.name}</h3>
             {/* {props.Cats.Details} */}
             <ReactMarkdown children={props.Cats.Details} rehypePlugins={[rehypeRaw]} />  

            <div className="cate-for-mobile">
                <div className="category-right-section">
                    <div className="video-box">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item"
                                    src={`https://player.vimeo.com/video/${props.Cats.videoLink}`} frameBorder="0"
                                    allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {props.Cats.name !== 'Aspire Dental' ?
            <div className="catename-watch">
            {props.Cats.child.map(catChild => {
                return <div className="watch-course"> 
                    <span>{catChild.name}</span>
                    <Link to={`/coursecategory/${catChild.id}`}>Watch Now</Link>
                </div>
            })}
    
            </div>
            : '' }
        </div>
    );

}

export default CategoryLeftSection;