import React, { useState } from 'react';
import UrlResolver from '../../../../../../../helpers/UrlResolver';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { Form, Modal } from "react-bootstrap";


const ConversationLeftCol = (props) => {
    //console.log(props.patient);
    let d = new Date(props.patient.created_at);
    let sdated = d.toISOString().slice(0, 10);

    const [media, setMedia] = React.useState({ media: "" });
    const [mediaType, setMediaType] = React.useState({ type: "" });

    // View Media  Modal
    const [mediaModal, setMediaModal] = useState(false);
    const mediaModalClose = () => setMediaModal(false);
    const mediaModalShow = (media, type) => {

        setMediaModal(true);

        setMedia(media);

        setMediaType(type);
    };


    console.log(props.patient.files);

    return (
        <div className='patients-information'>
            <div className='patient-title'>
                <span>Patient Details</span>
            </div>
            <div className='patients-details'>

                {/* Loop Div */}
                <div className='detail-col'>
                    <span>Name -</span>
                    <p>{props.patient.patientName}</p>
                </div>
                <div className='detail-col'>
                    <span>Age - </span>
                    <p>{props.patient.age} years</p>
                </div>
                <div className='detail-col'>
                    <span>Gender -</span>
                    <p>{props.patient.gender}</p>
                </div>
                <div className='detail-col'>
                    <span>CPD Total -</span>
                    <p>{sdated}</p>
                </div>
            </div>
            <div className='patient-description'>
                <span>Patient Information </span>
                <p>{props.patient.patientInfromation}</p>
            </div>
            <div className='patient-description'>
                <span>Question</span>
                <p>{props.patient.question}</p>
            </div>
            <div className='patient-fileupload'>
                <span>Files uploaded Mentor</span>
                <div className='filepatients'>
                    {props.patient.files.map((file, index) => {
                        return <div className='files-list'>
                            {file.mime === 'image/jpeg' || file.mime === 'image/png' || file.mime === 'image/jpg' || file.mime === 'image/svg' ?
                                <div className='file-image-lighter'>
                                    <img src={UrlResolver.generateMediaUrl(file.url)}
                                        alt={file.hash} />
                                    <div className='file-overlay' onClick={() => mediaModalShow(`${file.url}`, 'image')} >
                                        <img src='../../../../images/Icons/lightbox.svg' alt='LightboxIcon' />
                                    </div>

                                </div>
                                :
                                <div className='mmentor-video-display'>
                                    <div className='file-image-lighter'>
                                        <img src='../../../../images/Icons/video-detail.png' />
                                        <div className='file-overlay' onClick={() => mediaModalShow(`${file.url}`, 'video')} >
                                            <img src='../../../../images/Icons/video-player.svg' alt='LightboxIcon' style={{ width: 36, height: 36 }} />
                                            {/* Media Modal */}

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    })}
                </div>
            </div>

            <Modal show={mediaModal} onHide={mediaModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals "
            >
                <div className='media-modal'>
                    {mediaType === 'image'
                        ?
                        <div className='media-content'>
                            <img src={UrlResolver.generateMediaUrl(media)} alt='LightboxIcon' />
                        </div>
                        :
                        <ReactPlayer
                            className='react-player'
                            url={UrlResolver.generateMediaUrl(media)}
                            width='204'
                            height='149'
                            controls={true}

                        />
                    }
                    <div className='media-close-icon' onClick={mediaModalClose}>
                        <img src='../../../../images/Icons/close-icon.png' />
                    </div>
                </div>
            </Modal>



        </div >
    );
}

export default ConversationLeftCol;
