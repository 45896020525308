import React from 'react';
import {Link} from "react-router-dom";
import Api from '../../../../helpers/Api';

const ExternalTable =(props)=> {

    console.log(props.certs);
    
    let urllinkbutton = Api.BASE_API_URL;
    return (
            <>
                <div className="responsive-payment-table">
                    <table className="table responsive">
                        <tbody>
                        {props.certs.map((cert , index) => {
                            return <tr>
                                <td className="red-color">{index + 1}</td>
                                <td>{cert.course}</td>
                                <td>Source : {cert.source}</td>
                                <td>Issued On : {cert.issueDate}</td>
                                <td>
                                    <Link className="view" to={{ pathname:`${urllinkbutton}${cert.document.url}` }} target="_blank">View</Link>
                                        <Link className="download" to={{ pathname:`${urllinkbutton}${cert.document.url}` }} target="_blank">Download</Link>
                                </td>
                            </tr>
                        })}               
                        </tbody>
                    </table>
                </div>
            </>
    );
}

export default ExternalTable;