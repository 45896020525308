import { Link } from 'react-router-dom';
import React from 'react';
import DefaultHeading from '../../../../components/Heading/DefaultHeading';
import ArchivedTable from './Subcomponents/ArchivedTable';



const ArchivedItems = (props) => {

    return (
        <div className="foudation-years" >
            <div className="container">
                <DefaultHeading heading="Archived Patients " />
                <ArchivedTable  patients={props.patients}/>
            </div>
            
        </div>
    );
}

export default ArchivedItems;
