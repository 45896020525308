import React, {useState, useEffect } from 'react';
import SimpleInput from "../components/Input/SimpleInput";
import SimpleButton from "../components/Buttons/SimpleButton";
import {Form, Modal} from "react-bootstrap";
import LoginModal from "../components/Modals/LoginModal";
import ForgotPassword from "../components/Modals/ForgotPassword";
import ForgotPasswordEmailVerifyModal from "../components/Modals/ForgotPasswordEmailVerifyModal";
import EmailVerificationModal from "../components/Modals/EmailVerificationModal";
import {Link, useHistory} from "react-router-dom";
import InputWithIcon from "../components/Input/InputWithIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectInput from "../components/Input/SelectInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import ApiService from "../../helpers/ApiService";


const Registered = (props) => {

    const [backUrl, setBackUrl] = useState(null);

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    //console.log(goToPreviousPath);

    const eye = <FontAwesomeIcon icon={faEye}/>;

    //DatePicker
    const [startDate, setStartDate] = useState();

    // Tooltip Show
    const [showTooltip, setShowtooltip] = useState(false);

    //Checkbox
    const [checked, setChecked] = React.useState(true);

    // Login Modal
    const [loginModal, setloginModal] = useState(false);
    const loginModalClose = () => setloginModal(false);
    const loginModalShow = () => setloginModal(true) + setforgotModal(false);

    // Email Verify Modal
    const [emailverifyModal, setemailverifyModal] = useState(false);
    const emailverifyModalClose = () => setemailverifyModal(false);
    const emailverifyModalShow = () => setemailverifyModal(true);

    // Forgot Password Modal
    const [forgotModal, setforgotModal] = useState(false);
    const forgotModalClose = () => setforgotModal(false);
    const forgotModalShow = () => setforgotModal(true) + setloginModal(false);

    // Forgot Email Modal
    const [cardModal, setcardModal] = useState(false);
    const cardModalClose = () => setcardModal(false);
    const cardModalShow = () => setcardModal(true) + setforgotModal(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const [input, setInput] = useState({roleDentistry: '__select__'});
    const [errors, setErrors] = useState({});
    const [failed, setFailed] = useState({});
    const [loading, setLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const [subcrib, setSubcrib] = useState(false);

    const RoleDentistry = [
        {value: '__select__', label: 'Role within dentistry', isDisabled: true, },
        {value: 'Principal dentist', label: 'Principal dentist'},
        {value: 'Associate Dentist', label: 'Associate Dentist'},
        {value: 'Dental Nurse', label: 'Dental Nurse'},
        {value: 'Dental Therapist', label: 'Dental Therapist'},
        {value: 'Dental Hygienist', label: 'Dental Hygienist'},
        {value: 'Dental Technician', label: 'Dental Technician'},
        {value: 'Practice Manager', label: 'Practice Manager'},
        {value: 'other ', label: 'other '},
        // {value: 'Business Manager', label: 'Business Manager'},

    ]

    useEffect( () => {

        if(typeof props.location.state != "undefined"){
            setBackUrl(props.location.state.url);
        }
    
    }, [])

    const handleChange = (event) => {
        let i = {...input};
        i[event.target.name] = event.target.value;
        setInput(i);
    }

    const checkboxHandler = () => {
        if (agree === true) {
            setAgree(false);
        }

        if (agree === false) {
            setAgree(true);
        }

    }

    const checkboxHandler1 = () => {
        if (subcrib === true) {
            setSubcrib(false);
        }

        if (subcrib === false) {
            setSubcrib(true);
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);
            let birthDate = null;

            if(startDate){
                 birthDate = startDate.toISOString().slice(0, 10);
            }

            var data = {
                username: input.email,
                password: input.password,
                email: input.email,
                fullName: input.Fname +' '+input.Lname,
                roleDentistry: input.roleDentistry,
                dob: birthDate,
                gdcNumber: input.gdcnumber,
                practiceName: input.practice,
                practiceCity: input.pcity,
                practicePostedCode: input.pzipcode,
                practiceAddress: input.practiceAddress,
                homecity: input.city,
                homePostedCode: input.zipcode,
                homeAddress: input.homeAddress,
                phoneNumber: input.phoneNumber,
                status: true
            }

            let api = new ApiService();

            api.register(data)
                .then((response) => {
                    //setLoading(false);
                    //setemailverifyModal(true);

                    api.login({
                        identifier: input.email,
                        password: input.password,
                      }).then(response => {
                        localStorage.setItem('jwt', response.jwt);
                        setLoading(false);

                        if(backUrl){
                            window.location.href = backUrl;
                        } else {
                            goToPreviousPath();
                        }
                     }).catch(errors => {
                           setErrors({error: errors.message[0].messages[0].message});
                        }).finally(()=>{
                          setLoading(false);
                        });

                }).catch(errors => {
                    setErrors({error: errors.message[0].messages[0].message});
                }).finally(() => {
                    setLoading(false)
                });

        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;
        if (!input["Fname"]) {
            isValid = false;
            errors["Fname"] = "Please enter your First Name.";
        }

        if (!input["Lname"]) {
            isValid = false;
            errors["Lname"] = "Please enter your Last Name.";
        }

        if (!input["gdcnumber"]) {
            isValid = false;
            errors["gdcnumber"] = "Please enter your GDC Number.";
        }

        if (!input["roleDentistry"]) {
            isValid = false;
            errors["roleDentistry"] = "Please Select Role first";
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        if (!input["cemail"]) {
            isValid = false;
            errors["cemail"] = "Please enter your Confirm email Address.";
        }

        if (typeof input["cemail"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["cemail"])) {
                isValid = false;
                errors["cemail"] = "Please enter valid Confirm email address.";
            }
        }

        if (input["email"] !== input["cemail"]) {
            isValid = false;
            errors["cemail"] = "Email and confirm Email not matched";
        }

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }

        if (!input["cpassword"]) {
            isValid = false;
            errors["cpassword"] = "Please enter your Confirm password.";
        }

        if (input["cpassword"] !== input["password"]) {
            isValid = false;
            errors["cpassword"] = "Password and confirm password not matched";
        }

        if (agree === true) {
            isValid = false;
            errors["agree"] = "Please click Terms & Conditions";
        }

        setErrors(errors);
        return isValid;
    }

    return (
        <div className="register-box">
            <div className="register-left"
                 style={{backgroundImage: `url(${'.../../../images/Icons/register-back.svg'})`}}>
                <h3>Aspire <br/> Online</h3>
                <span>Registration</span>

            </div>
            <div className="register-right">
                <div className="signheading-box">
                    <Link to="/" className="registration-arrow">
                        <img src="../../../../images/Icons/left-arrow.svg"/>
                        <span>REGISTRATION</span>
                        {/* <span className="validation-error">{errors.msg}</span> */}

                    </Link>
                    <div className="alreadysign-logintext">
                        <span>If you already have an account?</span>
                        <p onClick={loginModalShow}>Login</p>
                    </div>
                </div>
                <Form onSubmit={handleSubmit}>
                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.Fname ? "form-control error" : "form-control"}
                            type="text"
                            placeholder="First Name"
                            name="Fname"
                            value={input.Fname}
                            onChange={handleChange}
                            errors={errors.Fname}
                        />
                    </div>
                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.Lname ? "form-control error" : "form-control"}
                            type="text"
                            placeholder="Last Name"
                            name="Lname"
                            value={input.Lname}
                            onChange={handleChange}
                            errors={errors.Lname}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputWithIcon
                            className="form-control"
                            type="text"
                            placeholder="GDC number"
                            icon="../../../../images/Icons/tooltip.svg"
                            onClick={() => setShowtooltip(!showTooltip)}
                            name="gdcnumber"
                            value={input.gdcnumber}
                            onChange={handleChange}
                            errors={errors.gdcnumber}
                        />
                        {/*onClick Tooltip Show   */}
                        <div className={showTooltip ? "subscribe-toolshow for-register" : "subscribe-toolhide"}>
                            <p>Required to complete PDP. Can continue without this, but cannot produce a PDP without it.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="simple-input form-group">
                            <SelectInput
                                placeholder="Role within dentistry"
                                isSearchable={false}
                                keepValue={true}
                                value={input.roleDentistry}
                                options={RoleDentistry}
                                error={errors.roleDentistry}
                                name="roleDentistry"
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.email ? "form-control error" : "form-control"}
                            type="email"
                            name="email"
                            placeholder="Enter Email"
                            value={input.email}
                            onChange={handleChange}
                            errors={errors.email}
                        />
                    </div>
                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.cemail ? "form-control error" : "form-control"}
                            type="email"
                            name="cemail"
                            placeholder="Enter Confirm Email"
                            value={input.cemail}
                            onChange={handleChange}
                            errors={errors.cemail}
                        />
                    </div>
                    {/* <div className="col-md-6">
                        <div className="simple-input form-group">
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                placeholderText="Date of birth"
                                className="form-control"
                                name="dob"
                            />
                        </div>
                    </div> */}
                    <div className="col-md-6">
                        <InputWithIcon
                            className="form-control"
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter Your password"
                            icon={passwordShown ? ".../../../../images/Icons/view-icon.png" : "../../../../images/Icons/view-icon.png"}
                            name="password"
                            onClick={togglePasswordVisiblity}
                            value={input.password}
                            onChange={handleChange}
                            errors={errors.password}
                        />
                    </div>

                    <div className="col-md-6">
                        <InputWithIcon
                            className="form-control"
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter Your Confirm password"
                            icon={passwordShown ? ".../../../../images/Icons/view-icon.png" : "../../../../images/Icons/view-icon.png"}
                            name="cpassword"
                            onClick={togglePasswordVisiblity}
                            value={input.cpassword}
                            onChange={handleChange}
                            errors={errors.cpassword}
                        />
                    </div>

                    {/* <div className="col-md-6">
                        { <SimpleInput
                            placeholder="Phone number"
                            className={errors.phoneNumber ? "form-control error" : "form-control"}
                            type="text"
                            name="phoneNumber"
                            value={input.phoneNumber}
                            onChange={handleChange}
                            errors={errors.phoneNumber}
                        /> }
                     </div>
                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.practice ? "form-control error" : "form-control"}
                            type="text"
                            name="practice"
                            placeholder="Practice Name"
                            value={input.practice}
                            onChange={handleChange}
                        />
                    </div>


                    <div className="col-md-6">
                        <div className="col-md-6 pl-0 mb-0">
                            <SimpleInput
                                placeholder="Practice City"
                                className={errors.pcity ? "form-control error" : "form-control"}
                                type="text"
                                name="pcity"
                                value={input.pcity}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-6 pr-0 mb-0">
                            <SimpleInput
                                placeholder="Practice Post Code"
                                className={errors.pzipcode ? "form-control error" : "form-control"}
                                type="text"
                                name="pzipcode"
                                value={input.pzipcode}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <SimpleInput
                            placeholder="Practice address"
                            className={errors.practiceAddress ? "form-control error" : "form-control"}
                            type="text"
                            name="practiceAddress"
                            value={input.practiceAddress}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-6 pl-0 mb-0">
                            <SimpleInput
                                placeholder="Home City"
                                className={errors.city ? "form-control error" : "form-control"}
                                type="text"
                                name="city"
                                value={input.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-6 pr-0 mb-0">
                            <SimpleInput
                                placeholder="Home Post Code"
                                className={errors.zipcode ? "form-control error" : "form-control"}
                                type="text"
                                name="zipcode"
                                value={input.zipcode}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <SimpleInput
                            placeholder="Home address"
                            className={errors.homeAddress ? "form-control error" : "form-control"}
                            type="text"
                            name="homeAddress"
                            value={input.homeAddress}
                            onChange={handleChange}
                        />
                    </div> */}


                    <div className="buttons-box ">
                        <div className="checkbox-col">
                            <label className="term-condition-check">
                                <input type="checkbox"
                                       defaultChecked={checked}
                                       onChange={() => setChecked(!checked)}
                                       id={agree}
                                       onChange={checkboxHandler}
                                       name={agree}
                                />
                                I agree to <Link className="term-color" to="/pages/2">Terms & Conditions</Link>
                                <br/>
                                <span className="validation-error">{errors.agree}</span>
                            </label>
                            <label className="term-condition-check">
                                <input type="checkbox"
                                       defaultChecked={checked}
                                       onChange={() => setChecked(!checked)}
                                       id={subcrib}
                                       name={subcrib}
                                       onChange={checkboxHandler1}
                                />
                                Never miss our latest courses! Stay in the loop by subscribing!
                            </label>
                            {/* <CheckboxInput
                                radiolabel="I agree to "
                                termtext="Terms & Conditions"
                            />
                            <CheckboxInput
                                radiolabel="Never miss our latest courses! Stay in the loop by subscribing!"
                            /> */}
                        </div>
                        { errors.error && <span className="validation-error donthave-acc text-center mt-3"><h5>{errors.error}</h5></span>}
                        <div className="button-col">
                            <SimpleButton
                                name="Register"
                                onClick={handleSubmit}
                                loading={loading}
                            />
                        </div>
                    </div>
                </Form>
                <div className="note-text">
                    <span>
                        Note: Your GDC number is presented on your CPD certificates. Please ensure that your GDC <br/>
                        number is correct because we cannot amend certificates.
                        </span>
                </div>
            </div>

            {/*  Login Modal */}
            <Modal show={loginModal} onHide={loginModalClose}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   size="md"
                   className="authModal cardModal Login-modals black-back"
            >
                <LoginModal
                    close={loginModalClose} forgot={forgotModalShow}/>
            </Modal>


            {/* Email Verification */}
            <Modal show={emailverifyModal} onHide={emailverifyModalClose}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   size="md"
                   className="authModal cardModal emailverification-modal black-back"
            >
                <EmailVerificationModal close={emailverifyModalClose} showNext={loginModalShow}/>
            </Modal>

            {/* Forgot Password */}
            <Modal show={forgotModal} onHide={forgotModalClose}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   size="md"
                   className="authModal cardModal Login-modals black-back"
            >
                <ForgotPassword close={forgotModalClose} login={loginModalShow} showNext={cardModalShow}/>
            </Modal>


            {/* Forgot Email Modal */}
            <Modal show={cardModal} onHide={cardModalClose}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   size="md"
                   className="authModal cardModal emailverification-modal  black-bac"
            >
                <ForgotPasswordEmailVerifyModal close={cardModalClose}/>
            </Modal>


        </div>
    );
}

export default Registered;
