import React, {useState , useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import ApiService from '../../../helpers/ApiService';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import CategoryBreadcrumbs from '../CourseCategories/components/CategoryBreadcrumbs';



import PageDetails from "./components/PageDetails";


const Page =(props)=> {
    

    const [page, setPage] = React.useState([]);
    
    
     let { id } = useParams();


    useEffect(async () => {

        window.scrollTo(0, 0);

        let api = new ApiService();

        api.getPages(id).then(_page => {
            setPage(_page);
            console.log(_page);

        }).catch((e) => {
     
         });

        }, [])
        
        if(!page) {
            return <></>
        }

    
    return (
        <>
           <MainHeader/>
            <CategoryBreadcrumbs catename={page.title} />
            <PageDetails PageDetail={page} />
            <Footer/>
        </>
    );

}

export default Page;