import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import QuizBreadcrumbs from './component/QuizBredcrumbs';
import {Link ,useParams} from "react-router-dom";

import { connect } from "react-redux";
import ApiService from '../../../helpers/ApiService';

const QuizCompleted = (props) => {

    
    const [coures,setCourses] = React.useState(null);

    const parameter = useParams();

    useEffect(async ()=>{

        let api = new ApiService();

        api.getCourse(parameter.id).then(_cou => {
            setCourses(_cou);
         }).catch((e) => {
     
         });

    },[])

   
    let resultFinal = 0;
    if(props.user){
        
       let objects = props.user.user_quiz;


        let result = objects.filter(obj => {
            return obj.id == parameter.type
          })

        resultFinal = result[0].percentage;
    } 

    if(!coures) {
        return <></>
    } 

    return (
        <>
            <MainHeader />
            <QuizBreadcrumbs courses={coures}/>
            <div className="completed-box">
                <div className="complete-circle">
                {resultFinal}%
                </div>
                <div className="congras">Congratulations!</div>
                <div>
                    <p>You have successfully completed the course ‘{coures.Name}’</p>
                    <p>In order to complete your PDP please fill in you reflections</p>
                </div>
                <div className="goto-my">
                    <p>You have been awarded a certification which you can find under  </p>
                    <Link className="retake-red" to={`/certificates/`}> My Certificates</Link>
                </div>

            </div>
            <Footer />
        </>
    );

}

function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(QuizCompleted); 