import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import SimpleHeading from "../../../components/Heading/SimpleHeading";
import InputWithLabel from "../../../components/Input/InputWithLabel";
import SimpleButton from "../../../components/Buttons/SimpleButton";
import InputWithIcon from "../../../components/Input/InputWithIcon";
import PaymentTable from "./PaymentTable";
import ApiService from '../../../../helpers/ApiService';

const UserProfileItem = (props) => {

    // console.log(props.user);

    let api = new ApiService();

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let i = { ...input };
        i[event.target.name] = event.target.value;
        setInput(i);
    }

    const handleChange2 = (event) => {
        let i = { ...input };
        i[event.target.name] = event.target.value;

        console.log(i);
        setInput(i);
    }

    useEffect(() => {
        setInput({ ...props.user }); 
    }, [props.user])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);

            var data = {
                fullName: input.Fname,
                dob: input.dob,
                homecity: input.homecity,
                homePostedCode: input.homePostedCode,
                homeAddress: input.homeAddress,
                email: input.email,
                phoneNumber: input.phoneNumber
            }

            api.updateUser(data)
                .then(response => {
                    //alert("updated");
                    setLoading(false);
                }).catch(errors => {
                    //console.log(errors);
                    setErrors({ error: errors.message[0].messages[0].message });
                    setLoading(false);
                })

        }
    }

    const handleSubmit1 = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);

            var data = {
                roleDentistry: input.roleDentistry,
                practiceName: input.practiceName,
                practiceCity: input.practiceCity,
                practicePostedCode: input.practicePostedCode,
                practiceAddress: input.practiceAddress,
                gdcNumber: input.gdcNumber
            }

            api.updateUser(data)
                .then(response => {
                    alert("updated");
                    setLoading(false);
                }).catch(errors => {
                    //console.log(errors);
                    setErrors({ error1: errors.message[0].messages[0].message });
                    setLoading(false);
                })

        }
    }

    const handleSubmit2 = async (event) => {
        event.preventDefault();
        if (validate2()) {

            let api = new ApiService();

            api.changePassword({
                identifier: input.email,
                password: input.cpassword,
                newPassword: input.npassword,
                confirmPassword: input.conpassword,
            }).then(response => {
                alert("Password updated");
            })
                .catch(errors => {
                    setErrors({ error2: errors.message[0].messages[0].message });
                }).finally(() => {
                    setLoading(false);
                });

        }
    }

    const validate2 = () => {
        let errors = {};
        let isValid = true;
        if (!input["cpassword"]) {
            isValid = false;
            errors["cpassword"] = "Please enter Current Password";
        }

        if (!input["npassword"]) {
            isValid = false;
            errors["npassword"] = "Please enter New Password";
        }

        if (!input["conpassword"]) {
            isValid = false;
            errors["conpassword"] = "Please enter Confirm Password";
        }

        if (input["conpassword"] !== input["npassword"]) {
            isValid = false;
            errors["conpassword"] = "Password and confirm not matched";
        }

        if (!input["email"]) {
            isValid = false;
            errors["email1"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email1"] = "Please enter valid email address.";
            }
        }

        setErrors(errors);
        return isValid;
    }



    const validate = () => {
        let errors = {};
        let isValid = true;
        if (!input["Fname"]) {
            isValid = false;
            errors["Fname"] = "Please enter your full name";
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        setErrors(errors);
        return isValid;
    }

    if (!props.user) {
        return <></>
    }

    let maxObj = 0;
    let startdate = '';
    let totalPayment = '';
    if (props.user) {
        //console.log(props.user.user_subscriptions);

        let objects = props.user.user_subscriptions;
        //console.log(objects);
        if (objects.length > 0) {
            totalPayment = objects;
            maxObj = objects.reduce((prev, current) => {
                if (+current.id > +prev.id) {
                    return current;
                } else {
                    return prev;
                }
            });

            const d = new Date(maxObj.created_at);

            startdate = d.toISOString().slice(0, 10);


        }


    }

    //console.log(maxObj);

    return (
        <>
            <div className="user-profiles">
                <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                    <Tab label="Personal Details">
                        <Form onSubmit={handleSubmit}>
                            {errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
                            <div className="Profile-contents">
                                <SimpleHeading
                                    heading="Personal Details"
                                />
                                <div className="profile-details">
                                    <div className="col-md-6">
                                        <div className="boxLeft">
                                            <InputWithLabel
                                                label="Full Name"
                                                type="text"
                                                placeholder="Full Name"
                                                name="Fname"
                                                errors={errors.Fname}
                                                value={input.Fname}
                                            />
                                            <InputWithLabel
                                                label="Date of Birth"
                                                type="text"
                                                placeholder="YYYY-MM-DD"
                                                name="dob"
                                                onChange={handleChange}
                                                value={input.dob}
                                            />
                                            <InputWithLabel
                                                label="Email"
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                value={input.email}
                                                onChange={handleChange}
                                                errors={errors.email}
                                            />
                                            <InputWithLabel
                                                label="Phone Number"
                                                type="text"
                                                name="phoneNumber"
                                                placeholder="Enter Phone Number"
                                                value={input.phoneNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="boxRight">
                                            <div className="fileupload">
                                                <div className="image-box">
                                                    <img src="../../../../images/Icons/imagebox.png" />
                                                    <span>Upload your profile <br /> picture</span>
                                                </div>
                                                <div className="upload-buttons">
                                                    <Link className="upload-buton">Upload</Link>
                                                    <Link className="cancel-buton">Cancel</Link>
                                                </div>
                                            </div>
                                            <InputWithIcon
                                                label="Address"
                                                type="text"
                                                placeholder="Enter Home Address"
                                                icon="../../../../images/Icons/editicon.png"
                                                className="form-control"
                                                name="homeAddress"
                                                value={input.homeAddress}
                                                onChange={handleChange}
                                            />
                                            <div className="col-md-12 pl-0 pr-0">
                                                <div className="col-md-6 pl-0">
                                                    <InputWithIcon
                                                        label="City"
                                                        type="text"
                                                        placeholder="Enter Home City"
                                                        icon="../../../../images/Icons/editicon.png"
                                                        className="form-control"
                                                        name="homecity"
                                                        value={input.homecity}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 pr-0">
                                                    <InputWithIcon
                                                        label="Posted Code"
                                                        type="text"
                                                        placeholder="Enter Posted Code"
                                                        icon="../../../../images/Icons/editicon.png"
                                                        className="form-control"
                                                        name="homePostedCode"
                                                        value={input.homePostedCode}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3 update-profile">
                                    <SimpleButton
                                        name="Update"
                                        onClick={handleSubmit}
                                        loading={loading}

                                    />
                                </div>
                            </div>
                        </Form>
                    </Tab>
                    <Tab label="Professional Details">
                        <Form onSubmit={handleSubmit1}>
                            {errors.error1 && <div className="donthave-acc text-center mt-3"><h4>{errors.error1}</h4></div>}
                            <div className="Profile-contents">
                                <SimpleHeading
                                    heading="Professional Details"
                                />
                                <div className="profile-details">
                                    <div className="col-md-6">
                                        <div className="boxLeft">
                                            <InputWithLabel
                                                label="Dentist Type"
                                                type="text"
                                                name="roleDentistry"
                                                placeholder="Role Dentistry"
                                                value={input.roleDentistry}

                                            />
                                            <InputWithIcon
                                                label="Practice Name"
                                                type="text"
                                                placeholder="Practice Name"
                                                icon="../../../../images/Icons/editicon.png"
                                                className="form-control"
                                                name="practiceName"
                                                value={input.practiceName}
                                                onChange={handleChange}

                                            />
                                            <div className="col-md-12 pl-0 pr-0">
                                                <div className="col-md-6 pl-0">
                                                    <InputWithIcon
                                                        label="Practice City"
                                                        type="text"
                                                        placeholder="Practice City"
                                                        icon="../../../../images/Icons/editicon.png"
                                                        className="form-control"
                                                        name="practiceCity"
                                                        value={input.practiceCity}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 pr-0">
                                                    <InputWithIcon
                                                        label="Posted Code "
                                                        type="text"
                                                        placeholder="Posted Code"
                                                        icon="../../../../images/Icons/editicon.png"
                                                        className="form-control"
                                                        name="practicePostedCode"
                                                        value={input.practicePostedCode}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="boxRight">
                                            <InputWithLabel
                                                label="GDC Number"
                                                type="text"
                                                placeholder="Enter GDC Number"
                                                name="gdcNumber"
                                                value={input.gdcNumber}
                                            />
                                            <InputWithIcon
                                                label="Practice Address"
                                                type="text"
                                                placeholder="Practice Address"
                                                icon="../../../../images/Icons/editicon.png"
                                                className="form-control"
                                                name="practiceAddress"
                                                value={input.practiceAddress}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className=" update-profile mt-3">
                                    <SimpleButton
                                        name="Update"
                                        onClick={handleSubmit1}
                                        loading={loading}

                                    />
                                </div>
                            </div>
                        </Form>
                    </Tab>
                    <Tab label="Password">
                        <Form onSubmit={handleSubmit2}>
                            {errors.error2 && <div className="donthave-acc text-center mt-3"><h4>{errors.error2}</h4></div>}
                            <div className="Profile-contents">
                                <SimpleHeading
                                    heading="Password"
                                />
                                <div className="profile-details">
                                    <div className="col-md-6">
                                        <div className="boxLeft">
                                            <InputWithLabel
                                                label="Email"
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                value={input.email}
                                                onChange={handleChange2}
                                                errors={errors.email1}
                                            />
                                            <InputWithLabel
                                                label="Current Password"
                                                type="password"
                                                placeholder="Current Password"
                                                name="cpassword"
                                                onChange={handleChange2}
                                                errors={errors.cpassword}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="boxRight">
                                            <InputWithLabel
                                                label="New Password"
                                                type="password"
                                                placeholder="New Password"
                                                name="npassword"
                                                onChange={handleChange2}
                                                errors={errors.npassword}

                                            />
                                            <InputWithLabel
                                                label="Confirm Password"
                                                type="password"
                                                placeholder="Confirm Password"
                                                name="conpassword"
                                                onChange={handleChange}
                                                errors={errors.conpassword}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="mt-5 pass-update">
                                    <SimpleButton
                                        name="Change Password"
                                        onClick={handleSubmit2}
                                        loading={loading}

                                    />
                                </div>
                            </div>
                        </Form>
                    </Tab>
                    <Tab label="Membership">
                        <div className="Profile-contents">
                            <SimpleHeading
                                heading="Membership"
                            />
                            <div className="profile-details">
                                <div className="col-md-12">

                                    {maxObj && maxObj !== 0 && maxObj.subscription !== "null"
                                        ?
                                        <div className="membership-box">
                                            <div className="membership-type">
                                                <div className="membership-detail">
                                                    <span>Membership Type</span>
                                                    <p>{maxObj.subscription == 2 ? "CPD Only" : ""} {maxObj.subscription == 3 ? "Silver" : ""} {maxObj.subscription == 4 ? "Gold" : ""}</p>
                                                </div>
                                                <div className="membership-detail">
                                                    <span>Join Date</span>
                                                    {startdate}
                                                    {/* <p>Monday, <br/>
                                                        December 22, <br/>
                                                        2020
                                                    </p> */}
                                                </div>
                                            </div>
                                            <div className="membership-type">
                                                <div className="membership-detail">
                                                    <span>Status</span>
                                                    <p>Active</p>
                                                </div>
                                                <div className="membership-detail">
                                                    <span>Expiry Date</span>
                                                    <p>{maxObj.ExpireDate}</p>
                                                    {/* <p>Wednesday <br/>
                                                        December 21, <br/>
                                                        2021
                                                    </p> */}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="membership-box">
                                            <div className="membership-type">
                                                <div className="membership-detail">
                                                    <span>Membership Type</span>
                                                    <h4>Basic Membership</h4>
                                                </div>
                                                <div className="membership-detail">
                                                    <Link className="defalut-button-with-icon" to={'/subscription'}>Upgrade</Link>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </Tab>
                    {/* <Tab label="Billing Information">
                            <div className="Profile-contents">
                                <SimpleHeading
                                    heading="Billing Information"
                                />
                                <div className="profile-details">
                                    <div className="col-md-6">
                                        <div className="boxLeft">
                                            <InputWithLabel
                                                label="Company name"
                                                type="text"
                                                placeholder="Aspire Online"
                                            />
                                            <InputWithLabel
                                                label="Full Name"
                                                type="text"
                                                placeholder="Raheel Malik"
                                            /> 
                                            <div className="mt-5 pass-update">
                                                <SimpleButton
                                                    name="Add Another Billing"
                                                /> 
                                            </div>                                                                                    
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="boxRight">
                                            <InputWithLabel
                                                label="Paypal email Id"
                                                type="text"
                                                placeholder="raheel@aspireonline.com"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab> */}
                    <Tab label="Payment History">
                        <div className="Profile-contents">
                            <SimpleHeading
                                heading="Payment History"
                            />
                            <div className="profile-details">
                                <div className="col-md-12">
                                    <div className="payment-table">
                                        {totalPayment && totalPayment !== ''
                                            ?
                                            <PaymentTable payment={totalPayment} />
                                            :
                                            <h4>No Payment</h4>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default UserProfileItem;