import React from 'react';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import ArchivedBreadcrumbs from './components/ArchivedBreadcrumbs';
import ArchivedItems from './components/ArchivedItems';
import { connect } from "react-redux";


const ArchivedPatients =(props)=> {

    let userPatients = 0;
    
    if(!props.user){
        return <></>  
      } else {
        if(props.user.data.mentor !== 'yes'){
          userPatients = props.user.patients;
        } else {
          userPatients = props.user.data.mentorpatients;
        }
        
      }



        return (
            <>
                {/* Header */}
                <MainHeader />
                <ArchivedBreadcrumbs hubname="Archived Patients" user={props.user}/>

                {/* Body */}
                <ArchivedItems patients={userPatients}/>

                {/* Footer */}
                <Footer/>
            </>
        );
}

function mapStateToProps(state) {
    return {user:state.user}
}

export default connect(mapStateToProps)(ArchivedPatients);
