import React, {Component} from 'react';
import BannerVideo from "./BannerVideo";
import ButtonWithIcon from "../../../components/Buttons/ButtonWithIcon";
import RandomSlider from '../../../components/Sliders/RandomSlider';
import RandomSlider2 from '../../../components/Sliders/RandomSlider2';


const BannerSection = (props) => {
    return (
        <>
            <div className="bannerSection">
                <div className="container">
                    <div className="bannerSection-boxes">
                        <div className="left-video">
                            <RandomSlider/>
                        </div>
                        <div className="Middle-content">
                            <h2>Welcome to <br/> Aspire Online</h2>
                            <ButtonWithIcon
                                name="Get Started"
                                icon="../../../../images/Icons/button-icon.svg"
                                link="/subscription"
                                onClick=""
                            />
                        </div>
                        <div className="right-video">
                            <RandomSlider2 />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bannersection-mobile">
                <div className="Middle-content">
                    <h2>Welcome to <br/> Aspire Online</h2>
                </div>
                <div className="right-video">
                    <RandomSlider/>
                </div>
                <ButtonWithIcon
                    name="Get Started"
                    icon="../../../../images/Icons/button-icon.svg"
                    link="/subscription"
                    onClick=""
                />
            </div>
        </>
    );
}

export default BannerSection;