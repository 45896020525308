import {createGlobalStyle} from "styled-components"

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body}!important;
    color: ${({ theme }) => theme.text}!important;
    
  }
  ul.navigation-navbar li a{
    color:${({ theme }) => theme.text}!important;
  }
  .Middle-content h2{
    color: ${({ theme }) => theme.text}!important;
  }
  .home-cat-list ul li a{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .home-cat-list ul li a:hover{
    color:${({ theme }) => theme.text}!important;
  }
  .category-verticaltabs .left-categories ul li a{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .category-verticaltabs .left-categories ul li a.active{
    color:${({ theme }) => theme.activehover}!important;
  }
  .right-categories-list ul li a{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .right-categories-list ul li a.active{
    color:${({ theme }) => theme.activehover}!important;
  }
  .defalut-heading h3{
    color:${({ theme }) => theme.heading}!important;
  }
  .go-col1 h3{
    color:${({ theme }) => theme.heading}!important;
  }
  .go-col1 span{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .go-col2{
    background:${({ theme }) => theme.catcolor}!important;
  }
  .stats-box p{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .slider-testi .designation span{
    color:${({ theme }) => theme.heading}!important;
  }
  .slider-testi .designation p{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .clientdescritpion p{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .slider-testi{
    border: 1px solid ${({ theme }) => theme.borderColor}!important;
  }
  .user-name span{
    color:${({ theme }) => theme.textColor}!important;
  }
  .testimonial-boxes .owl-theme .owl-dots .owl-dot span{
    background:${({ theme }) => theme.dotsColor};
  }
  .courseslider-boxes .owl-dots button.owl-dot span{
    background:${({ theme }) => theme.dotsColor};
  }
  .courseslider-boxes .owl-dots button.owl-dot.active span{
    background:${({ theme }) => theme.dotsColor1};
  }
  .bredcums-subs span{
    color:${({ theme }) => theme.breadcrumbsColor};
  }
  .header-links-dropdown-content ul li a {
    color:${({ theme }) => theme.headerdrop} !important;
  }
  .user-drop-box ul li a{
    color:${({ theme }) => theme.headerdrop} !important;
  }
  .breadcrums-titles a{
    color:${({ theme }) => theme.breadcrumbsColor};
  }
  .left-section h3{
    color:${({ theme }) => theme.heading}!important;
  }
  .left-section p{
    color:${({ theme }) => theme.descrption}!important;
  }
  .buttn-box a{
    background:${({ theme }) => theme.buyButton};
    color:${({ theme }) => theme.buyButtonColor};
  }
  .breadcrumbs-section{
    border-bottom:0.5px solid ${({ theme }) => theme.beadcrumbsBorder};
  }
  .course-list-box{
    border:0.5px solid ${({ theme }) => theme.beadcrumbsBorder};
  }
  .course-list-box ul li a{
    border-bottom:0.5px solid ${({ theme }) => theme.beadcrumbsBorder};
  }
  .count-with-name span{
    color:${({ theme }) => theme.courseName};
  }
  .course-list-box ul li a.active .count-with-name span{
    color:${({ theme }) => theme.courseNameActive};
  }
  .count-with-name p{
    color:${({ theme }) => theme.courseName};
  }
  .course-list-box ul li a.active .count-with-name p{
    color:${({ theme }) => theme.courseNameActive};
  }
  .detail-actions a{
    background:${({ theme }) => theme.detailButton};
  }
  .bottom-section{
    border-top:0.5px solid ${({ theme }) => theme.beadcrumbsBorder};
  }
  .course-outline{
    border-left:0.5px solid ${({ theme }) => theme.beadcrumbsBorder};
  }
  .detail-actions a.take-quiz-disable{
    background:${({ theme }) => theme.buyButton};
  }
  .detail-actions a.take-quiz-disable span{
    color:${({ theme }) => theme.buyButtonColor};
  }
  .user-profiles .tab-content{
    border: 1px solid ${({ theme }) => theme.tableBorder};
  }
  .user-profiles .tabs ul.nav.nav-tabs li a.nav-link.active{
    border: 1px solid ${({ theme }) => theme.tableBorder};
    background: ${({ theme }) => theme.tabletabsback};
  }
  .user-profiles .tabs ul.nav.nav-tabs li a.nav-link{
    background: ${({ theme }) => theme.heading};
  }
  .user-profiles .tabs ul.nav.nav-tabs li a{
    color:${({ theme }) => theme.tabscolor};
  }
  .simple-heading h3{
    color:${({ theme }) => theme.text};
  }
  .label-input label{
    color:${({ theme }) => theme.text};
  }
  .profile-details .inputwith-icon .form-control{
    border:none !important;
  }
  .inputwith-icon{
    border: 1px solid ${({ theme }) => theme.profileInputBorder};
  }
  .label-input .form-control{
    border: 1px solid ${({ theme }) => theme.profileInputBorder};
    color:${({ theme }) => theme.text};
  }
  .membership-box .membership-type .membership-detail p{
    color:${({ theme }) => theme.text};
  }
  .image-box span{
    color:${({ theme }) => theme.text};
  }
  .upload-buttons .cancel-buton{
    color:${({ theme }) => theme.text};
  }
  .responsive-payment-table .table.responsive thead th{
    color:${({ theme }) => theme.profiletablecolorheading};
  }
  .responsive-payment-table .table.responsive tbody td{
    color:${({ theme }) => theme.profiletablecolorbody};
  }
  .responsive-payment-table .table.responsive tbody td .price-currency p{
    color:${({ theme }) => theme.profiletablecolorbody};
  }
  .responsive-payment-table .table.responsive tbody tr td a{
    color:${({ theme }) => theme.text};
  }
  .responsive-payment-table .table.responsive tbody tr{
    border: 1px solid ${({ theme }) => theme.profileInputBorder};
  }
  .category-left-section h3{
    color:${({ theme }) => theme.text};
  }
  .category-left-section p{
    color:${({ theme }) => theme.descrption};
  }
  .watch-course span{
    color:${({ theme }) => theme.text};
  }
  .course-outline p{
    color:${({ theme }) => theme.descrption};
  }
  .author-box p{
    color:${({ theme }) => theme.courseName};
  }
  .right-review h4{
    color:${({ theme }) => theme.text};
  }
  .author-box p strong{
    color:${({ theme }) => theme.text};
  }
  .certificate-boxes h4{
    color:${({ theme }) => theme.text};
  }
  .certificate-tabs-new .tabs ul li a{
    background: ${({ theme }) => theme.certificatebuttonback};
  }
  .event-meta-info p{
    color:${({ theme }) => theme.text};
  }
  .news-content-box p{
    color:${({ theme }) => theme.textColor};
  }
  .boxes-metas h4{
    color:${({ theme }) => theme.text};
  }
  .boxes-metas p{
    color:${({ theme }) => theme.text};
  }
  .boxes-metas h6{
    color:${({ theme }) => theme.text};
  }
  .detail-descrption p{
    color:${({ theme }) => theme.text};
  }
  .home-cat-list .Dental a{
    color:${({ theme }) => theme.text}!important;
  }
  .get-started-mentorship p{
    color:${({ theme }) => theme.heading}!important;
  }
  .become-a-mentor{
    background:${({ theme }) => theme.heading}!important;
  }
  .become-a-mentor span{
    color: ${({ theme }) => theme.body}!important;
  }
  .pdp-table table tbody td{
    color:${({ theme }) => theme.heading}!important;
    border-right:1px solid ${({ theme }) => theme.profileInputBorder};
    border-top:1px solid ${({ theme }) => theme.profileInputBorder};
  }
  .pdp-table table{
    border:1px solid ${({ theme }) => theme.profileInputBorder};
  }
  .pdp-table table thead th{
    border-bottom:1px solid ${({ theme }) => theme.profileInputBorder};
    border-right:1px solid ${({ theme }) => theme.profileInputBorder};
  }
  .in-progress p{
    color:${({ theme }) => theme.heading}!important;
  }
  .mentor-edit{
    background:${({ theme }) => theme.mentoreditback}!important;
  }
  .upcoming-calls-head a{
    background:${({ theme }) => theme.mentoreditback}!important;
  }
  .mentordash-buttons a{
    background:${({ theme }) => theme.mentoreditback}!important;
  }
  .patient-title span{
    color:${({ theme }) => theme.heading}!important;
  }
  .detail-col p{
    color:${({ theme }) => theme.heading}!important;
  }
  .detail-col span{
    color:${({ theme }) => theme.catcolor}!important;
  }
  .chat-head span{
    color:${({ theme }) => theme.heading}!important;
  }
  .patient-description span{
    color:${({ theme }) => theme.heading}!important;
  }
  .patient-description p{
    color:${({ theme }) => theme.descrption}!important;
  }
  .patient-fileupload span{
    color:${({ theme }) => theme.heading}!important;
  }
  .chat-box{
    border:0.5px solid ${({ theme }) => theme.dotsColor1};
  }
  .input-tool input{
    border:0.5px solid ${({ theme }) => theme.dotsColor1};
    color:${({ theme }) => theme.descrption}!important;
  }
  .upcoming-calls-head span{
    color:${({ theme }) => theme.heading}!important;
  }
  
  .mentor-tabs-for-tables  .tabs ul.nav.nav-tabs li a.nav-link.active{
    border-bottom: 1px solid ${({ theme }) => theme.activehover};
    
  }
  
  .mentor-tabs-for-tables  .tabs ul.nav.nav-tabs li a{
    color:${({ theme }) => theme.heading};
  }
  .slots-boxes-colum .col-md-2{
    border: 1px solid ${({ theme }) => theme.tableBorder};
  }
`
