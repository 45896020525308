import React, { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import ApiService from '../../../helpers/ApiService';
import SimpleButton from '../Buttons/SimpleButton';
import InputWithoutLabel from '../Input/InputWithoutLabel';
import SelectInput from '../Input/SelectInput';
import SimpleInput from '../Input/SimpleInput';


const AddPatientModal = (props) => {

    let api = new ApiService();

    const [input, setInput] = useState({ mentorship: '__select__', gender: '__select__', mentor: '__select__' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [mentorUser, setUserMentor] = useState([]);

    const [Mentor, setMentor] = useState([{ value: '__select__', label: 'Select Mentor', isDisabled: true, }]);

    //Checkbox
    const [checked, setChecked] = React.useState(true);

    const Mentorship = [
        {value: '__select__', label: 'Mentor Speciality', isDisabled: true, },
        {value: 'Restorative', label: 'Restorative'},
        {value: 'Endodontics', label: 'Endodontics'},
        {value: 'Periodontics', label: 'Periodontics'},
        {value: 'Prosthodontics', label: 'Prosthodontics'},
        {value: 'Peadratric', label: 'Peadratric'},
        {value: 'Implants', label: 'Implants'},
    ]

    useEffect(async () => {
        if(input.mentorship !== '__select__'){
            
        api.getMentor(input.mentorship).then(_mentor => {
            setUserMentor(_mentor);
           if(_mentor) {
                console.log(mentorUser);
               let firstMentor = {
                "value": "__select__",
                "label": "Select Mentor",
                "isDisabled": true
              }
    
              _mentor.unshift(firstMentor);
    
             function getUniqueListBy(arr, key) {
                return [...new Map(arr.map(item => [item[key], item])).values()]
            }
    
            const arr1 = getUniqueListBy(_mentor, 'value');

            setMentor(arr1);}
         }).catch((e) => {
      
          });}

          
       
        }, [input])
        
    const Gender = [
        { value: '__select__', label: 'Gender', isDisabled: true, },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ]

   

    const handleChange = (event) => {
        let i = { ...input };
        i[event.target.name] = event.target.value;
        setInput(i);
    }


    const checkboxHandler = () => {
        if (agree === true) {
            setAgree(false);
        }

        if (agree === false) {
            setAgree(true);
        }

    }

    const handleFileChange = (event) => {
        //console.log(event.target.files);
        setFileData(event.target.files);
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);

            if(fileData.length < 1){
                var data = {
                    mentorshipType: input.mentorship,
                    age: input.age,
                    gender: input.gender,
                    patientName: input.patientName,
                    patientInfromation: input.patientInfromation,
                    question: input.question,
                    mentor: input.mentor
                }

                //console.log(data);

                api.setPatient(data)
                    .then(response => {
                        //alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        console.log(errors);
                        setErrors({ error: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

            } else {

            const formData = new FormData();

            Array.from(fileData).forEach(file => {
                formData.append('files', file);
            });
             
            api.uploadFileCertificate(formData).then(response => {

                let ids = [];
                Array.from(response).forEach(res => {
                    //console.log(res.id);

                    ids.push(res.id);

                });

                var data = {
                    mentorshipType: input.mentorship,
                    age: input.age,
                    gender: input.gender,
                    patientName: input.patientName,
                    patientInfromation: input.patientInfromation,
                    question: input.question,
                    mentor: input.mentor,
                    files: ids
                }

                //console.log(data);

                api.setPatient(data)
                    .then(response => {
                        //alert("updated");
                        setLoading(false);
                        window.location.reload();
                    }).catch(errors => {
                        console.log(errors);
                        setErrors({ error: errors.message[0].messages[0].message });
                        setLoading(false);
                    })

            }).catch(errors => {

                //console.log(errors.data.errors[0].message);
                setErrors({ error: errors.data.errors[0].message });
            });
                
            }
        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;
        if (!input["mentorship"]) {
            isValid = false;
            errors["mentorship"] = "Please Select mentorship type";
        } else if (input["mentorship"] === '__select__') {
            isValid = false;
            errors["mentorship"] = "Please Select mentorship type";
        }

        if (!input["mentor"]) {
            isValid = false;
            errors["mentor"] = "Please Select mentor";
        } else if (input["mentor"] === '__select__') {
            isValid = false;
            errors["mentor"] = "Please Select Mentor";
        }

        if (!input["age"]) {
            isValid = false;
            errors["age"] = "Please enter age.";
        }

        if (!input["gender"]) {
            isValid = false;
            errors["gender"] = "Please Select gender";
        } else if (input["gender"] === '__select__') {
            isValid = false;
            errors["gender"] = "Please Select Gender";
        }

        if (!input["patientName"]) {
            isValid = false;
            errors["patientName"] = "Please enter patient name.";
        }

        if (agree === true) {
            isValid = false;
            errors["agree"] = "Please click Terms & Conditions";
        }

        setErrors(errors);
        return isValid;
    }





    return (
        <div className="dark">
            <div className="d-flex align-items-center justify-content-between patient-header">
                <div className="pay-card">
                    <span>Add Patient</span>
                </div>
                <div className="cross-photo" onClick={props.close}>
                    <img src="../../../../images/Icons/closs-icon.svg" />
                </div>
            </div>
            <Form onSubmit={handleSubmit} className="addpatientform">
                {errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
                <div className="simple-input form-group">
                    <SelectInput
                        placeholder="Type of mentorship required"
                        isSearchable={false}
                        keepValue={true}
                        value={input.mentorship}
                        options={Mentorship}
                        name="mentorship"
                        className={errors.mentorship ? "form-control error" : "form-control"}
                        onChange={handleChange}
                        error={errors.mentorship}
                    />
                </div>
                <div className="simple-input form-group">
                    <SelectInput
                        placeholder="Mentor"
                        isSearchable={false}
                        keepValue={true}
                        value={input.mentor}
                        options={Mentor}
                        name="mentor"
                        className={errors.mentor ? "form-control error" : "form-control"}
                        onChange={handleChange}
                        error={errors.mentor}
                    />
                </div>
               
                <SimpleInput
                    className={errors.age ? "form-control error" : "form-control"}
                    type="text"
                    name="age"
                    placeholder="Enter Age"
                    value={input.age}
                    onChange={handleChange}
                    errors={errors.age}
                />
                <div className="simple-input form-group">
                    <SelectInput
                        placeholder="Gender"
                        isSearchable={false}
                        keepValue={true}
                        value={input.gender}
                        options={Gender}
                        name="gender"
                        className={errors.gender ? "form-control error" : "form-control"}
                        onChange={handleChange}
                        error={errors.gender}
                    />
                </div>
                <InputWithoutLabel
                    type='text'
                    placeholder='Patient name'
                    className='form-control'
                    icon='../../../../images/Icons/tooltip.svg'
                    tooltiptext='Please do not use patients real name, Please give
                    the patient a code e.g. 1x'
                    name="patientName"
                    value={input.patientName}
                    onChange={handleChange}
                    errors={errors.patientName}

                />
                <SimpleInput
                    type="text"
                    placeholder="Patient information, please be as thorough as possible"
                    name="patientInfromation"
                    className={errors.patientInfromation ? "form-control error" : "form-control"}
                    onChange={handleChange}
                    value={input.patientInfromation}
                />
                <InputWithoutLabel
                    type='text'
                    placeholder='Questions'
                    className='form-control'
                    icon='../../../../images/Icons/tooltip.svg'
                    tooltiptext='Please be specific and ask in bullet point format'
                    name="question"
                    value={input.question}
                    onChange={handleChange}
                    errors={errors.question}

                />

                <div className="simple-input">
                    <input type="file" className={errors.files ? "form-control error" : "form-control"} name="files" onChange={handleFileChange} placeholder="Choose file"
                        style={{ padding: 0 }} multiple />
                </div>
                <div className="checkbox-col">
                    <label className="term-condition-check">
                        <input type="checkbox"
                            defaultChecked={checked}
                            onClick={() => setChecked(!checked)}
                            id={agree}
                            onChange={checkboxHandler}
                            name={agree}
                        />
                        I agree to <Link className="term-color" to="/pages/2">Terms & Conditions</Link>
                        <br />
                    </label>
                </div>
                <div className="button-col payment">
                    <SimpleButton
                        name="Upload"
                        onClick={handleSubmit}
                        loading={loading}
                    />
                </div>
            </Form>


        </div>
    );
}

export default AddPatientModal;
