import React from 'react';
import { Link } from 'react-router-dom';


const MentorshipSteps = (props) => {
    let maxObj = 0;
    
    if(!props.userplan){
        maxObj = 0;
      } else {
          maxObj = props.userplan;
      }

      

    return (
        <div className='mentorship-steps'>
            <div className='steps-col'>
                <div className='col-md-4'>
                    <div className='step-left'>
                        <span>Step 1</span>
                        <h5>Upload Patient Information</h5>
                        <p>Select:  Case discussion or Feedback on clinical work </p>
                        <p>Upload Patient Details</p>
                        <p>Upload Pictures</p>
                        <p>Ask your questions</p>
                        <p>Choose speciality</p>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div className='steps-right'>
                        <img src='../../../../../../images/Icons/step1.svg' alt=''  />
                        <img className='step-horizontal' src='../../../../../../images/Icons/combine-arrow.svg' alt='' />
                    </div>
                </div>
            </div>
            <div className='steps-col second-step' >
                <div className='col-md-8'>
                    <div className='steps-right new' style={{flexDirection:'column'}}>
                        <img src='../../../../../../images/Icons/step2.svg' alt='' width='100%' />
                        <img  src='../../../../../../images/Icons/straight-arrow.svg' alt='' style={{height:57, marginLeft:20, marginTop:-90}} />
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='step-left' style={{paddingTop:120}}>
                        <span>Step 2</span>
                        <h5>Connect with your chosen specialist and start discussing your case</h5>                      
                    </div>
                </div>
            </div>
            <div className='steps-col' style={{paddingBottom:30, marginTop:-20}}>
                <div className='col-md-4'>
                    <div className='step-left'>
                        <span>Step 3</span>
                        <h5>Archived Your Patients</h5>
                        <p>Once you are done, you can archive your patients for viewing later </p>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div className='steps-right'>
                        <img src='../../../../../../images/Icons/step3.png' alt='' width='100%' />
                    </div>
                </div>
            </div>
            <div class="button-col payment"><div className='buton-for-mentor'>
            {maxObj && maxObj !== 0 && maxObj.subscription === 4
             ? 
                    <Link to='/mentorshipdashboard' className='defalut-button'>Dashboard</Link>
                    :
                    <Link to='/subscription' className='defalut-button'>Get Started</Link>
            }
                </div></div>
        </div>
    );
}

export default MentorshipSteps;
