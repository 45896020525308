import React from 'react';
import { Link } from 'react-router-dom';


const MentorshipGetStarted = (props) => {
    let maxObj = 0;
    
    if(!props.userplan){ 
        maxObj = 0;
      } else {
          maxObj = props.userplan;
      }

      if(props.usertype){
      console.log(props.usertype.mentor)
      }

    return (
        <div className='get-started-mentorship'>
            <div className='col-md-3'>
                <span>Stuck on a case and need help treatment planning?</span>
                <p>When was the last time someone checked your clinical work and gave you feedback to help you improve?
                </p>
            </div>
            <div className='col-md-6'>
                <div className='video-box'>
                    <img src='../../../../../../images/Icons/video-detail.png' alt='Video' />
                </div>
                <div className='buton-for-mentor'>
                    {props.usertype.mentor && props.usertype.mentor === 'yes'
                    ? 
                    <Link to='/mentordashboard' className='defalut-button'>Dashboard</Link>
                    :
                    <>
                    {maxObj && maxObj !== 0 && maxObj.subscription ===  4
                    ? 
                    <Link to='/mentorshipdashboard' className='defalut-button'>Dashboard</Link>
                    :
                    <Link to='/subscription' className='defalut-button'>Get Started</Link>
                    }
                    </>
                    }
                    
                </div>
            </div>
            <div className='col-md-3'>
                <span>Now you discuss cases with mentors and seek advice on your clinical work! </span>
                <p>Upload pictures of your cases and clinical work and ask for feedback and tips from mentors around the world!
                </p>
            </div>
        </div>
    );
}

export default MentorshipGetStarted;
