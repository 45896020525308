import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../../components/Header/MainHeader';
import Footer from "../../../../components/Footer/Footer";
import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";
import TalkDentalItems from "./components/TalkDentalItems";
import ApiService from '../../../../../helpers/ApiService';



const TalkDental =(props)=> {

    const [foundationYears, setFoundationYears] = React.useState([]);
    const [pageTitle, setPageTitle] = React.useState('');
    const [pageType, setPageType] = React.useState('');
    let { id } = useParams();

    useEffect(async () => {

        window.scrollTo(0, 0);

        let api = new ApiService();
       

        if(id == 'foundations'){

            setPageTitle('Foundations Talk Dental');

            setPageType('foundations');

            api.getFoundationYears().then(_foundations => {
                setFoundationYears(_foundations);
             }).catch((e) => {
         
             });
        } else if(id == 'students'){
            setPageTitle('Students Talk Dental');

            setPageType('students');

            api.getAspireStudents().then(_foundations => {
                setFoundationYears(_foundations);
             }).catch((e) => {
         
             });
        } else {
            return <></>
        }

        
        }, [])
        
        if(!foundationYears) {
            return <></>
        }
    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={pageTitle} />
            <TalkDentalItems foundationYears={foundationYears}  heading={pageTitle} type={pageType}/>
            <Footer/>

        </>
    );

}

export default TalkDental;