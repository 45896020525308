import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import CategoryBreadcrumbs from './components/CategoryBreadcrumbs';
import CategoryCourseList from './components/CategoryCourseList';
import CategoryChildList from './components/CategoryChildList';
import CategoryItems from './components/CategoryItems';
import CategoryPopularCourses from './components/CategoryPopularCourses';
import ApiService from '../../../helpers/ApiService';


const CourseCategory =(props)=> {
    const [categories,setCategories] = React.useState(null);

    let { id } = useParams();

    useEffect(async ()=>{

        let api = new ApiService();

        api.getCourseCategory(id).then(_cat => {
            setCategories(_cat);
         }).catch((e) => {
     
         });

    },[])
    if(!categories) {
        return <></>
    } else {
        console.log(categories);
    }

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={categories.name} />
            <CategoryItems cat={categories}/>
            {categories.name === 'Aspire Dental' ? <CategoryChildList subcats={categories}/> : <CategoryCourseList courses={categories.courses}/> }
            {categories.name === 'Aspire Dental' ? '' : <CategoryPopularCourses courses={categories.courses}/> }
            <Footer/>
        </>
    );

}

export default CourseCategory;