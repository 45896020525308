import {Link} from "react-router-dom";
import React, {useEffect} from 'react';
import SimpleButton from '../../../../components/Buttons/SimpleButton';

const RightJournalSection =(props)=> {
    if(!props.read) {
        return <></>
    }
    let userSubs = 0;
    if(props.user){
        //console.log(props.user.user_subscriptions);

        let objects = props.user.user_subscriptions;

        if(objects.length === 0){
            userSubs = 0;
        } else {
           let maxObj = objects.reduce((max, obj) => (max.subscription > obj.subscription) ? max : obj);
            userSubs = maxObj.subscription;

            if(userSubs == 'null'){
                userSubs = 0; 
            }
        }

        //console.log(userSubs);
    } 
    return (
        <div className="right-section ">
            {userSubs != '4' &&
            <div className="buy-buttons ">
                <div  className="buttn-box">
                    <Link to='/subscription' className="defalut-button">Subscribe Now</Link> 
                   
                </div>
            </div>
            }

            {/* display button when purchase the course */}
            {userSubs == '4' &&
            <div className="watch-course-button" >
                <SimpleButton
                    name="Watch this Course Now"
                />
            </div>
            }
            <div className="course-list-box">
            {userSubs != '4' &&
                <ul>
                {props.read.map((read , index) => {
                    return <li>
                        <Link className="Inactive" >
                            <div className="count-with-name"> 
                                <span>{index + 1}.</span>
                                <p  className="pl-3">{read.title}</p> 
                            </div>
                        </Link>
                    </li>
                })}
                </ul>
            }
            {userSubs == '4' &&
                <ul>
                {props.read.map((read , index) => {
                    return <li>
                        <Link className={props.activeId === read.id ? "active" : "Inactive"} onClick={()=>props.onhandles(read.video, read.id)}>
                            <div className="count-with-name"> 
                                <span>{index + 1}.</span>
                                <p  className="pl-3">{read.title}</p> 
                            </div>
                        </Link>
                    </li>
                })}
                </ul>
            }
            </div>
        </div>
    );

}

export default RightJournalSection;