import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeading from '../../../components/Heading/DefaultHeading';
import CategorySlider from './CategorySlider';

const CategoryPopularCourses = (props) => {

    return (
        <div className="cate-popular-Courses">
            <DefaultHeading heading="Popular Courses" />
            <div className="pop-cat-slider">
                <CategorySlider courses={props.courses} />
            </div>
        </div>
    );
}

export default CategoryPopularCourses;
