import React from 'react';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import ArchivedBreadcrumbs from './components/ArchivedBreadcrumbs';
import ArchivedItems from './components/ArchivedItems';
import { connect } from "react-redux";


const ArchivedCalls =(props)=> {

    let userScheduleCalls = 0;
    
    if(!props.user){
        return <></>  
      } else {
        userScheduleCalls = props.user.scheduleCalls;
      }



        return (
            <>
                {/* Header */}
                <MainHeader />
                <ArchivedBreadcrumbs hubname="Archived Calls" />

                {/* Body */}
                <ArchivedItems scheduleCalls={userScheduleCalls}/>

                {/* Footer */}
                <Footer/>
            </>
        );
}

function mapStateToProps(state) {
    return {user:state.user}
}

export default connect(mapStateToProps)(ArchivedCalls);
