import React, {useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import CourseListItems from './components/CourseListItems';

const CourseList =(props)=> {
    return (
        <>
         <MainHeader/>
         <CourseListItems/>
         <Footer/>
        </>
    );

}

export default CourseList;