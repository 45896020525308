import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Link} from "react-router-dom";
import UrlResolver from '../../../../../helpers/UrlResolver';


class EventsSlider extends Component {
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    render() {
        let {events} = this.props;
        if (!events || events.length === 0) {
            return <></>
        }
        return (
            <div className="float-left w-100 mt-4 dots-style">
                <OwlCarousel ref={this.sliderRef}
                    className="owl-theme"
                    items={1}
                    autoplay={false}
                    dots={true}
                    loop={true}
                    margin={20}
                    nav={false}
                    autoplayHoverPause={true}
                    autoFocus={true}
                    autoplaySpeed={2000}
                    animateIn="linear"
                    smartSpeed={300}
                    responsive={this.state.responsive}
                >
                {events.map(event => {
                    return <div className="item">
                    <a href={event.link} target="_blank" >
                        <div className="courses-slider new" >
                        <img src={UrlResolver.generateMediaUrl(event.banner.url)}
                                         alt={event.banner.hash}/>
                            <div className="course-overlay"  >
                                <div className="showAfterhover">
                                    <div className="slider-desc pl-0">
                                        <h4>{event.title}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>
                })}
                   
                </OwlCarousel>
            </div>
        );
    }
}

export default EventsSlider;
