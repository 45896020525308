import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; 
import rehypeRaw from 'rehype-raw';
import UrlResolver from '../../../../helpers/UrlResolver';
import {Tabs, Tab,} from 'react-bootstrap-tabs';

const DetailBottomSection = (props) => {

    return (
        <>
            <div className="detail-bottom-section">
                <div className="course-outline border-none">
                    <h3>Course Outline</h3>
                    <div className="outline-text">
                        {/* {props.courses.Outline} */}
                        <ReactMarkdown children={props.courses.Outline} rehypePlugins={[rehypeRaw]} />

                    </div>
                </div>
                <div className="course-outline">
                    <h3>Aim and Objectives</h3>
                    <div className="outline-text">
                        {/* {props.courses.AimAndObjectives} */}
                        <ReactMarkdown children={props.courses.AimAndObjectives} rehypePlugins={[rehypeRaw]} /> 
                    </div>
                </div>
                <div className="course-outline">
                    <h3>GDC Development Outcomes</h3>
                    <div className="outline-text">
                        {/* //{props.courses.GDCDevelopmentOutcomes} */}
                        <ReactMarkdown children={props.courses.GDCDevelopmentOutcomes}  rehypePlugins={[rehypeRaw]} />
                    </div>
                </div>
                <div className="course-outline">
                    <h3>Downloadable Resources</h3>
                    {props.courses.type == '1' &&
                    <div className="download-resourses">
                        {props.courses.Downloadable.map((downloadable, index) => {
                            return <Link to='#'>
                                <span>Resource {index + 1}</span>
                                <img src="../../../../images/Icons/download-icon.png"/>
                            </Link>
                        })}
                    </div>
                    }

                    {/* when purchase the course after download content enable */}
                    {props.courses.type != '1' &&
                    <div className="download-resourses-enable" style={{display : 'inline-block'}}>
                        {props.courses.Downloadable.map((downloadables, index) => {
                            return <Link to={UrlResolver.generateMediaUrl(downloadables.Resource.url)} target="_blank">
                                <span>Resource {index + 1}</span>
                                <img src="../../../../images/Icons/download-icon.png"/>
                            </Link>
                        })}
                    </div>
                    }
                </div>
            </div>
            {/* For Mobile View Only */}
            <div className="course-details-tabs-mobile">
                <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                    <Tab label="Overview">
                        <div className="course-outline border-none">
                            <h3>Course Outline</h3>
                            <div className="outline-text">
                                {props.courses.Outline}

                            </div>
                        </div>
                        <div className="course-outline">
                            <h3>Aim and Objectives</h3>
                            <div className="outline-text">
                                {props.courses.AimAndObjectives}
                            </div>
                        </div>
                        <div className="course-outline">
                            <h3>GDC Development Outcomes</h3>
                            <div className="outline-text">
                                {props.courses.GDCDevelopmentOutcomes}
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Lessons">
                        <div className="course-list-box">
                        {props.courses.type == '1' &&
                        <ul>

                            <li>
                                <Link className="active" onClick={()=>props.onhandle(props.courses.previewVideo)}>
                                    <div className="count-with-name">
                                        <span>1.</span>
                                        <p className="pl-3">Course Preview</p>
                                    </div>
                                    <img src="../../../../images/Icons/expand-icon.png"/>
                                </Link>
                            </li>

                            {props.courses.lessons.map((lesson, index) => {
                                return <li>
                                    <Link className="" src='#' >
                                        <div className="count-with-name">
                                            <span>{index + 2}.</span>
                                            <p className="pl-3">{lesson.Title}</p>
                                        </div>
                                        <img src="../../../../images/Icons/expand-icon.png"/>
                                    </Link>
                                </li>
                            })}
                            </ul>
                        }

                        {props.courses.type != '1' &&
                        <ul>

                            <li>
                                <Link className="active" onClick={()=>props.onhandle(props.courses.previewVideo)}>
                                    <div className="count-with-name">
                                        <span>1.</span>
                                        <p className="pl-3">Course Preview</p>
                                    </div>
                                    <img src="../../../../images/Icons/expand-icon.png"/>
                                </Link>
                            </li>

                            {props.courses.lessons.map((lesson, index) => {
                                return <li>
                                    <Link className="" src={lesson.Video} onClick={()=>props.onhandle(lesson.Video)}>
                                        <div className="count-with-name">
                                            <span>{index + 2}.</span>
                                            <p className="pl-3">{lesson.Title}</p>
                                        </div>
                                        <img src="../../../../images/Icons/expand-icon.png"/>
                                    </Link>
                                </li>
                            })}
                        </ul>
                        }
                            
                        </div>
                    </Tab>
                    <Tab label="Resources">
                        <div className="course-outline">
                        {props.courses.type == '1' &&
                            <div className="download-resourses">
                                {props.courses.Downloadable.map((downloadable, index) => {
                                    return <Link to="" >
                                        <span>Resource {index + 1}</span>
                                        <img src="../../../../images/Icons/download-icon.png"/>
                                    </Link>
                                })}
                            </div>
                        }



                            {/* when purchase the course after download content enable */}
                            {props.courses.type != '1' &&
                            <div className="download-resourses-enable" style={{display : 'inline-block'}}>
                                {props.courses.Downloadable.map((downloadables, index) => {
                                    return <Link to={UrlResolver.generateMediaUrl(downloadables.Resource.url)}
                                                 target="_blank">
                                        <span>Resource {index + 1}</span>
                                        <img src="../../../../images/Icons/download-icon.png"/>
                                    </Link>
                                })}
                            </div>
                            }
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    );

}

export default DetailBottomSection;