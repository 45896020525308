import React, { useEffect ,useState } from 'react';
import {useParams} from "react-router-dom";
import ApiService from '../../../../../helpers/ApiService';
import Footer from '../../../../components/Footer/Footer';
import MainHeader from '../../../../components/Header/MainHeader';
import ChatBreadcrumbs from './Components/ChatBreadcrumbs';
import ConversationItems from './Components/ConversationItems';
import { connect } from "react-redux";


const ViewConversation =(props)=> {

    const [conversation,setConversation] = React.useState(null);
    
    let api = new ApiService();
    let { id } = useParams();

    useEffect(()=>{

        window.scrollTo(0, 0);

        let api = new ApiService();

        api.getPatient(id).then(_cou => {
            setConversation(_cou);
           }).catch((e) => {
     
         });

    },[])

    if(!conversation) {
        return <></>
    } 

        return (
            <>
                {/* Header */}
                <MainHeader />
                <ChatBreadcrumbs hubname={conversation.patientName} user={props.user}/>
                {/* Body */}
                <ConversationItems  patient={conversation} user={props.user}/> 
                {/* Footer */}
                <Footer/>
            </>
        );
}


function mapStateToProps(state) {
    return {user:state.user}
}

export default connect(mapStateToProps)(ViewConversation);
