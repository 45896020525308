import React, { useEffect ,useState } from 'react';
import ApiService from '../../../../../../helpers/ApiService';
import SimpleButton from '../../../../../components/Buttons/SimpleButton';
import customConfirmDialog from '../../../../../CustomConfirmDialog';
import ConversationLeftCol from './SubComponents/ConversationLeftCol';
import ConversationRightCol from './SubComponents/ConversationRightCol';


const ConversationItems = (props) => {
    
    let api = new ApiService();

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

   const statusChanged = (patient) => {

    customConfirmDialog("Are you sure", "Are you sure you want to end mentorship?", () => {
        
        var data = {
            statusChanged:1,
            id: patient,
        }
    
           api.updatePatient(data)
                   .then(response => {
                        setLoading(false);
                        window.location.reload(); 
                        
                    }).catch(errors => {
                        //console.log(errors);
                        setErrors({error: errors.message[0].messages[0].message});
                     }).finally(()=>{
                        setLoading(false);
                    });
        
    }, "Yes", () => {

    }, "No")
};

if(!props.user){
    return <></>  
  }
    return (
        <div className="foudation-years">
            <div className="container">
                <div className='conversation-view'>
                    <div className='col-md-6'>
                        <ConversationLeftCol  patient={props.patient}/>
                    </div>
                    <div className='col-md-6'>
                        <ConversationRightCol patient={props.patient} user={props.user}/>
                    </div>
                    {props.patient.status !== 'Archived' &&
                    <div className='end-chat-session'>
                    <SimpleButton
                        name="End Mentorship session"
                        onClick={() => statusChanged(`${props.patient.id}`)}
                        loading={loading}
                    />
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ConversationItems;
