import jwt_decode from 'jwt-decode';
import React, { useState, useEffect } from 'react';
import DefaultHeading from "../../components/Heading/DefaultHeading";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CardModal from "../../components/Modals/CardModal";
import { createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ApiService from '../../../helpers/ApiService';
import { connect } from "react-redux";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

//const stripePromise = loadStripe('pk_test_nCcWEWsgJJTgFQ0DnB6h3PKj');

const stripePromise = loadStripe('pk_test_EWIX3qF7aed7wUqKV4cqOaiQ00pFQG0epr');



const Subscription = (props) => {

    const [subscriptions, setSubscriptions] = React.useState([]);
    const [selectedPlan, setSelectedPlan] = React.useState({ price: "", plan: "" , course: ""});

    useEffect( () => {

        let api = new ApiService();

        api.getPlans().then(_plans => {
            setSubscriptions(_plans);
          }).catch((e) => {

          });

    }, [])


    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: "12px",
                    color: "#FFFFFF",
                    backgroundColor: "#FF4C4C",
                    lineHeight: "136.7%",
                    fontWeight: "normal",
                }
            }
        }
    });


    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
        },
        customWidth: {
            maxWidth: 500,
        },
        noMaxWidth: {
            maxWidth: 'none',
        },
    }));

    const classes = useStyles();



    // Card Modal
    const [cardModal, setcardModal] = useState(false);
    const cardModalClose = () => setcardModal(false);

    const cardModalShow = (price, plan, course) => {

       const dataPlan = {
            price,
            plan,
            course,
       }

       //console.log(dataPlan);

        setcardModal(true);

        setSelectedPlan(dataPlan);

        //console.log(selectedPlan);
      };

    let button;
    let userSubs = 0;
    if(props.user){
        //console.log(props.user.user_subscriptions);

        let objects = props.user.user_subscriptions;

        if(objects.length === 0){
            userSubs = 0;
        } else {
           let maxObj = objects.reduce((max, obj) => (max.subscription > obj.subscription) ? max : obj);
            userSubs = maxObj.subscription;

            if(userSubs == 'null'){
                userSubs = 0; 
            }
        }

        //console.log(userSubs);
    } 
    return (
        <>
            <div className="subscription-section">
                <div className="container">
                    <div className="bredcums-subs">
                        <Link to="/" className="d-flex align-items-center">
                            <img src="../../../images/Icons/bredcumes-back.png" />
                            <span className="pl-3">SUBSCRIPTION PLANS </span>
                        </Link>
                    </div>

                    {/* Subscription Packages */}
                    <div className="subscription-package">
                        <DefaultHeading
                            heading="Subscription"
                        />
                        <div className="package-boxes">

                            {subscriptions.map(subscription => {

                                return <div className="package-col ">
                                    <div className={`${subscription.name === 'Basic' ? "package-head basic" : ""}
                                   ${subscription.name === 'CPD Only' ? "package-head cpd" : ""}
                                    ${subscription.name === 'Silver' ? "package-head silver" : ""}
                                     ${subscription.name === 'Gold' ? "package-head gold" : ""}`}>
                                        {subscription.name}
                                    </div>
                                    <div className="package-body">
                                        <div className="feature-head">
                                            <span>Features</span>
                                        </div>
                                        <div className="feature-list">
                                            <ul>
                                                {subscription.features.map(feature => {
                                                    return <li className={`${feature.featuresTooltip !== null ? "for-actions" : ""}`}>
                                                        <div>
                                                            <img src="../../../images/Icons/list-arrow.png" />
                                                            <span>{feature.featureText}</span>
                                                        </div>
                                                        {feature.featuresTooltip !== null ? <MuiThemeProvider theme={theme}><Tooltip title={feature.featuresTooltip}><Button className={classes.button}><img src="../../../images/Icons/tooltip.svg" /></Button></Tooltip></MuiThemeProvider> : ''}
                                                    </li>
                                                })}
                                            </ul>
                                            <div className="package-price">
                                                <div className="price-detail">
                                                    <span>{subscription.name !== 'Basic' ? '£' : ''}{subscription.feetext}</span>
                                                </div>

                                                {!props.user && subscription.name === 'Basic' ? <Link className="basic" to={'/register'}>Register</Link> : ''}
                                                {!props.user && subscription.name === 'CPD Only' ? <Link className="cpd" to={'/register'}>Register</Link> : ''}
                                                {!props.user && subscription.name === 'Silver' ? <Link className="silver" to={'/register'}>Register</Link> : ''}
                                                {!props.user && subscription.name === 'Gold' ? <Link className="gold" to={'/register'}>Register</Link> : ''}
                                                
                                                {props.user && subscription.name === 'Basic' ? '' : ''}
                                                {props.user && subscription.name === 'CPD Only' && subscription.id > userSubs ? <Link className="cpd" onClick={() => cardModalShow(`${subscription.fee}`,`${subscription.id}`, 'null')}  >Upgrade</Link> : ''}
                                                {props.user && subscription.name === 'Silver' && subscription.id > userSubs ? <Link className="silver" onClick={() => cardModalShow(`${subscription.fee}`,`${subscription.id}`, 'null')}  >Upgrade</Link> : ''}
                                                {props.user && subscription.name === 'Gold' && subscription.id > userSubs ? <Link className="gold" onClick={() => cardModalShow(`${subscription.fee}`,`${subscription.id}`, 'null')}  >Upgrade</Link> : ''}

                                               
                                                {props.user && subscription.name === 'CPD Only' && subscription.id == userSubs ? <Link className="cpd"  >Subscribed</Link> : ''}
                                                {props.user && subscription.name === 'Silver' && subscription.id == userSubs ? <Link className="silver" >Subscribed</Link> : ''}
                                                {props.user && subscription.name === 'Gold' && subscription.id == userSubs ? <Link className="gold"  >Subscribed</Link> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                </div>
            </div>
            <Modal show={cardModal} onHide={cardModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="authModal cardModal"
                size="md"
                className="authModal cardModal pay-modals"
            >
            
            <Elements stripe={stripePromise}>
                <CardModal planDone={selectedPlan} close={cardModalClose} />
            </Elements>
            </Modal>
        </>
    );
}



function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(Subscription);
