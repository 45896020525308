import React, { useEffect ,useState } from 'react';
import SimpleButton from '../Buttons/SimpleButton';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ApiService from '../../../helpers/ApiService';
import Moment from 'moment';


const ScheduleModal = (props) => {

    let api = new ApiService();
    let mday = [];
    let tday = [];
    let wday = [];
    let thday = [];
    let fday = [];
    let saday = [];
    let suday = [];


    function getTimeStops(start, end){
        var startTime = Moment(start, 'HH:mm');
        var endTime = Moment(end, 'HH:mm');
        
        if( endTime.isBefore(startTime) ){
          endTime.add(1, 'day');
        }
      
        var timeStops = [];
      
        while(startTime <= endTime){

            timeStops.push(`${new Moment(startTime).format('HH:mm A')} - ${new Moment(startTime.add(30, 'minutes')).format('HH:mm A')}`); 
        }
        
         return timeStops.sort();
      }

    const [value, onChange] = useState(new Date());
    const [dayslot, setDayslot] = useState(null);
    const [patient, setPatient] = useState(null);
    const [slots, setSlots] = useState(['10-11am','11-12am','12-01pm','02-03pm','04-05pm','05-06pm','06-07pm','07-08pm','08-09pm']);
    const [bookSlots,setBookSlots] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [mindate, setMindate] = useState(new Date());
    const [alreadyBooked, setAlreadyBooked] = useState(null);

    

    const [bookSlotsID,setBookSlotsID] = useState(null);

    const [activeSlots, setActiveSlots] = useState(null);

    const bookingslot = (key , timeslot) => {
          setActiveSlots(timeslot);
          setBookSlots(timeslot);
        };

      const saveSchedule = (patientid , mentorid,  bookdate , bookslot , bookslotid) => {

      if(bookslotid == 'null'){

        var data = {
            patient:patientid,
            mentor:mentorid,
            callDate:bookdate,
            callSlot:bookslot
          }

          //console.log(data);

          api.setScheduleCall(data)
               .then(response => {
                    setLoading(false);
                    window.location.href = "/mentorshipdashboard";
                    
                }).catch(errors => {
                    //console.log(errors);
                    setErrors({error: errors.message[0].messages[0].message});
                 }).finally(()=>{
                    setLoading(false);
                }); 
        
    } else {

        var data = {
            callDate:bookdate,
            callSlot:bookslot,
            id:bookslotid
          }

          //console.log(data);

          api.updateBooking(data)
          .then(response => {
              setLoading(false);
              window.location.href = "/mentorshipdashboard";

          }).catch(errors => {
              //console.log(errors);
              setErrors({ error: errors.message[0].messages[0].message });
          }).finally(() => {
              setLoading(false);
          });
        

      }

    };

    useEffect(()=>{

        window.scrollTo(0, 0);

       api.getPatient(props.patientId).then(_cou => {
             setPatient(_cou);
           }).catch((e) => {
     
         });

         api.getScheduleCallWithDate(Moment(value).format('YYYY-MM-DD')).then(_booking => {
            //console.log(_booking);
            let newArr = [];
            _booking.map((item)=> {
                newArr.push(item.callSlot)
            })
            //console.log(newArr);
           
            setAlreadyBooked(newArr);
            // let lastdate = new Date(Moment(value).format('YYYY-MM-DD'))
            // onChange(lastdate);

            }).catch((e) => {
      
          });

        if(props.slotid){
            api.getScheduleCall(props.slotid).then(_booking => {
              setDayslot(Moment(_booking.callDate).day());

               let lastdate = new Date(_booking.callDate)
                  setBookSlots(_booking.callSlot);
                   setBookSlotsID(props.slotid);
                }).catch((e) => {
          
              });
         }

        },[value])

        

        
       //console.log(value);

    if(!patient){
        return <></>;
      } else {

        console.log(value);
        
        if(patient.mentor.dactorslots[0] && patient.mentor.dactorslots[0].bookslots !== 0){
            mday = patient.mentor.dactorslots[0].bookslots;
        }

        if(patient.mentor.dactorslots[1] && patient.mentor.dactorslots[1].bookslots !== 0){
            tday = patient.mentor.dactorslots[1].bookslots;
        }

        if(patient.mentor.dactorslots[2] && patient.mentor.dactorslots[2].bookslots !== 0){
            wday = patient.mentor.dactorslots[2].bookslots;
        }

        if(patient.mentor.dactorslots[3] && patient.mentor.dactorslots[3].bookslots !== 0){
            thday = patient.mentor.dactorslots[3].bookslots;
        }

        if(patient.mentor.dactorslots[4] && patient.mentor.dactorslots[4].bookslots !== 0){
            fday = patient.mentor.dactorslots[4].bookslots;
        }

        if(patient.mentor.dactorslots[5] && patient.mentor.dactorslots[5].bookslots !== 0){
            saday = patient.mentor.dactorslots[5].bookslots;
        }

        if(patient.mentor.dactorslots[6] && patient.mentor.dactorslots[6].bookslots !== 0){
            suday = patient.mentor.dactorslots[6].bookslots;
        }
      }
  

    return (
        <div className="dark">
            <div className="d-flex align-items-center justify-content-between patient-header">
                <div className="pay-card">
                    <span style={{ color: '#ffffff' }}>Call</span>
                </div>
                <div className="cross-photo" onClick={props.close}>
                    <img src="../../../../images/Icons/closs-icon.svg" />
                </div>
            </div>
            <div className='schedule-modal'>
                <div className='col-md-6'>
                    <div className='patients-information'>
                        <div className='patient-title'>
                            <span style={{ color: '#ffffff' }}>Schedule Call</span>
                        </div>
                        <div className='patients-details' style={{ marginBottom: 40 }}>

                            {/* Loop Div */}
                            <div className='detail-col'>
                                <span style={{ color: '#ffffff' }}>Mentor -</span>
                                <p style={{ color: '#ffffff' }}>{patient.mentor.fullName}</p>
                            </div>
                            <div className='detail-col'>
                                <span style={{ color: '#ffffff' }}>Patient - </span>
                                <p style={{ color: '#ffffff' }}>{patient.patientName}</p>
                            </div>

                        </div>
                        <div className='date-head'>
                            <span>Select Date</span>
                            <p>{Moment(value).format('DD MMMM, YYYY')}</p>
                        </div>
                        <div className='date-calendar'>
                            <Calendar onChange={onChange} value={value} minDate={mindate} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='date-head'>
                        <span>Select Time Slot</span>
                        <p>{Moment(value).format('DD MMMM, YYYY')}</p>
                    </div>
                    <div className='date-slots'>
                         {Moment(value).day() == 1 &&  <div>
                            {mday.map((data) => {
                                let gettimeslotsmain = getTimeStops(data.starttimem, data.endtimem);
                                
                                    return (
                                        <div className='slot-list'>
                                                { gettimeslotsmain.map((dataslots, index) => {
                                                    if(alreadyBooked != null) {

                                                    if(alreadyBooked.includes(`${dataslots}`)){
                                                        return (
                                                             <div className="slot-col selected" >{dataslots} </div>
                                                        
                                                        )

                                                    } else {

                                                        return (
                                                             <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'} onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                        
                                                        )
                                                        
                                                    }
                                                    
                                                     } else {

                                                        return (
                                                             <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'} onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                        
                                                        )
                                                    }
                                                   
                                                })
                                                }
                                            </div>
                                        )})}
                         </div> 
                         }
                         {Moment(value).day() == 2 &&  <div>
                            {tday.map((data) => {
                                let gettimeslotsmain = getTimeStops(data.starttimet, data.endtimet);
                                //console.log(alreadyBooked);
                                    return (
                                        <div className='slot-list'>
                                                { gettimeslotsmain.map((dataslots, index) => {
                                                    if(alreadyBooked != null) {

                                                    if(alreadyBooked.includes(`${dataslots}`)){
                                                        return (
                                                             <div className="slot-col selected" >{dataslots} </div>
                                                        
                                                        )

                                                    } else {

                                                        return (
                                                             <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                        
                                                        )
                                                        
                                                    }
                                                    
                                                     } else {
                                                        
                                                        return (
                                                             <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                        
                                                        )
                                                    }
                                                   
                                                })
                                                }
                                            </div>
                                        )})}</div> }
                         {Moment(value).day() == 3 &&  <div>
                            {wday.map((data) => {
                                let gettimeslotsmain = getTimeStops(data.starttimew, data.endtimew);
                                //console.log(gettimeslotsmain);
                                    return (
                                        <div className='slot-list'>
                                                { gettimeslotsmain.map((dataslots, index) => {
                                                    if(alreadyBooked != null) {

                                                        if(alreadyBooked.includes(`${dataslots}`)){
                                                            return (
                                                                <div className="slot-col selected" >{dataslots} </div>
                                                            
                                                            )

                                                        } else {

                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                            
                                                        }

                                                        } else {
                                                            
                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                        }
                                                })
                                                }
                                            </div>
                                        )})}</div> }
                         {Moment(value).day() == 4 &&  <div>
                            {thday.map((data) => {
                                let gettimeslotsmain = getTimeStops(data.starttimeth, data.endtimeth);
                                //console.log(gettimeslotsmain);
                                    return (
                                        <div className='slot-list'>
                                                { gettimeslotsmain.map((dataslots, index) => {
                                                    if(alreadyBooked != null) {

                                                        if(alreadyBooked.includes(`${dataslots}`)){
                                                            return (
                                                                <div className="slot-col selected" >{dataslots} </div>
                                                            
                                                            )

                                                        } else {

                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                            
                                                        }

                                                        } else {
                                                            
                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                        }
                                                })
                                                }
                                            </div>
                                        )})}
                         </div> }
                         {Moment(value).day() == 5 &&  <div>
                            {fday.map((data) => {
                                let gettimeslotsmain = getTimeStops(data.starttimef, data.endtimef);
                                //console.log(gettimeslotsmain);
                                    return (
                                        <div className='slot-list'>
                                                { gettimeslotsmain.map((dataslots, index) => {
                                                    if(alreadyBooked != null) {

                                                        if(alreadyBooked.includes(`${dataslots}`)){
                                                            return (
                                                                <div className="slot-col selected" >{dataslots} </div>
                                                            
                                                            )

                                                        } else {

                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                            
                                                        }

                                                        } else {
                                                            
                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                        }
                                                })
                                                }
                                            </div>
                                        )})}
                         </div> }
                         {Moment(value).day() == 6 &&  <div>
                         {saday.map((data) => {
                                let gettimeslotsmain = getTimeStops(data.starttimesa, data.endtimesa);
                                //console.log(gettimeslotsmain);
                                    return (
                                        <div className='slot-list'>
                                                { gettimeslotsmain.map((dataslots, index) => {
                                                    if(alreadyBooked != null) {

                                                        if(alreadyBooked.includes(`${dataslots}`)){
                                                            return (
                                                                <div className="slot-col selected" >{dataslots} </div>
                                                            
                                                            )

                                                        } else {

                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                            
                                                        }

                                                        } else {
                                                            
                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                        }
                                                })
                                                }
                                            </div>
                                        )})}</div> }
                         {Moment(value).day() == 0 &&  <div>
                         {suday.map((data) => {
                                let gettimeslotsmain = getTimeStops(data.starttimesu, data.endtimesu);
                                //console.log(gettimeslotsmain);
                                    return (
                                        <div className='slot-list'>
                                                { gettimeslotsmain.map((dataslots, index) => {
                                                    if(alreadyBooked != null) {

                                                        if(alreadyBooked.includes(`${dataslots}`)){
                                                            return (
                                                                <div className="slot-col selected" >{dataslots} </div>
                                                            
                                                            )

                                                        } else {

                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                            
                                                        }

                                                        } else {
                                                            
                                                            return (
                                                                <div className={activeSlots === dataslots ? 'selected slot-col' : 'slot-col'}  onClick={() => bookingslot(`${index}`, `${dataslots}`)} key={index}>{dataslots} </div>
                                                            
                                                            )
                                                        }
                                                })
                                                }
                                            </div>
                                        )})}</div> }


                        {/* {slots.map((slot, index) => {
                            return <div className={`${activeSlots && activeSlots == index ? "slot-col selected" : "slot-col"}`} onClick={() => bookingslot(`${index}`, `${slot}`)} key={index}>
                                {slot}
                            </div>
                        })} */}
                        {/* {patient.mentor.dactorslots.map((data) => {
                        if(data.day == 1){
                           let gettimeslotsmain = getTimeStops(data.starttimem, data.endtimem);
                                //console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            } else if(props.tday == 2){
                                let gettimeslotsmain = getTimeStops(data.starttimet, data.endtimet);
                                console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            }
                        
                    })
                } */}
                      
                    </div>
                </div>
                <div className="button-col payment">
                     <SimpleButton
                        name="Save"
                        onClick={() => saveSchedule(`${patient.id}`,`${patient.mentor.id}`,`${Moment(value).format('YYYY-MM-DD')}`, `${bookSlots}`, `${bookSlotsID}`)}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

export default ScheduleModal;
