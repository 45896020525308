import React, {useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import AddPatientModal from '../../../../../components/Modals/AddPatientModal';
import CardModal from '../../../../../components/Modals/CardModal';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import EditPatientModal from '../../../../../components/Modals/EditPatientModal';

const stripePromise = loadStripe('pk_test_nCcWEWsgJJTgFQ0DnB6h3PKj');

const MentorTable = (props) => {

    // add patient Modal
    const [addPatientModal, setAddPatientModal] = useState(false);
    const addPatientModalClose = () => setAddPatientModal(false);
    const addPatientModalShow = () => setAddPatientModal(true);

    const [selectedPatient, setSelectedPatient] = React.useState({ patient: "" });

    // edit patient Modal
    const [editPatientModal, setEditPatientModal] = useState(false);
    const editPatientModalClose = () => setEditPatientModal(false);
    const editPatientModalShow = (patient) => {

       setEditPatientModal(true);

       setSelectedPatient(patient);
      };

    const [selectedPlan, setSelectedPlan] = React.useState({ price: "", plan: "" , course: ""});

   

    // Card Modal
    const [cardModal, setcardModal] = useState(false);
    const cardModalClose = () => setcardModal(false);

    const cardModalShow = (price, plan, course) => {

        const dataPlan = {
            price,
            plan,
            course,
       }
        setcardModal(true);

        setSelectedPlan(dataPlan);
      };


    const [showStatus, setShowStatus] = useState(false);
    const [showPaymentStatus, setShowPaymentStatus] = useState(false);

    let userpatients = [];
    if(props.patients){
         
        let objects = props.patients;

        //console.log(objects);

        if(objects.length === 0){
            userpatients = [];
        } else {
            userpatients = objects;

            //console.log(userpatients);
        }
      }

    return (
        <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Patients</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Payment</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                {userpatients.map(userpatient => {
                    let d = new Date(userpatient.created_at);
                    let sdated = d.toISOString().slice(0, 10); 
                    if(userpatient.status === 'Submitted' || userpatient.status === 'In process'){
                      return <tr>
                        <td className="text-left  pl-4" >
                            <div className='patient-detail'>
                                <img src='../../../../../../images/Icons/mentor-pic.svg' alt='ProfilePic' />
                                <span>{userpatient.patientName}</span>
                            </div>
                        </td>
                        <td>{sdated}</td>
                        <td>
                            {userpatient.status === 'In process' &&
                                <div className="in-progress">
                                    <p>In Progress</p>
                                </div>
                            }
                            {userpatient.status === 'Submitted' &&
                                <div className="status-change">
                                    <span>Submitted</span>
                                </div>
                            }

                        </td>
                        <td>{userpatient.mentorshipType}</td>
                        <td>
                            {userpatient.paymentStatus === 'pending' &&
                                <div className='payment-status'  onClick={() => cardModalShow('200',`${userpatient.id}`, 'patients')} >
                                    <Link>Pay Now</Link>
                                </div>
                            }
                            {userpatient.paymentStatus === 'Done' &&
                                <div className='payment-done'>
                                    <span>Payment Done</span>
                                </div>
                            }
                        </td>
                        <td>
                            <div className='mentor-actions'>
                                <div className='mentor-edit' onClick={() => editPatientModalShow(`${userpatient.id}`)} >
                                    <span>Edit</span>
                                </div>
                                {userpatient.paymentStatus === 'Done' &&
                                <div className='view-conversation'>
                                    <Link to={`/viewconversation/${userpatient.id}`}>View Conversation</Link>
                                </div>
                                }
                                
                            </div>
                            
                            </td>
                    </tr>
                    }
                    })}

                </tbody>
            </table>

            {/*  Add patient Modal */}
            <Modal show={addPatientModal} onHide={addPatientModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals add-patient"
            >
                
                <AddPatientModal 
                    close={addPatientModalClose}
                />
            </Modal>

            {/*  Edit patient Modal */}
            <Modal show={editPatientModal} onHide={editPatientModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals add-patient edit-patient"
            >
                
                <EditPatientModal patientId={selectedPatient} close={editPatientModalClose} />
            </Modal>

            <Modal show={cardModal} onHide={cardModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered 
                size="md"
                className="authModal cardModal pay-modals"
            >
            <Elements stripe={stripePromise}>
               <CardModal planDone={selectedPlan} close={cardModalClose} />
            </Elements>
            </Modal>

        </div>
    );
}

export default MentorTable;
