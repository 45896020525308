import React, { useEffect ,useState } from 'react';
import {Link, useParams} from "react-router-dom";
import CourseDetailLeftBox from './CourseDetailLeftBox';
import CourseDetailRightBox from './CourseDetailRightBox';
import DetailBottomSection from './DetailBottomSection';
import ApiService from '../../../../helpers/ApiService';
import { connect } from "react-redux";


const CourseDetailItems = (props) => {
    
    const [coures,setCourses] = React.useState(null);

    const [activeId, setActiveId] = useState('preview');

    const [courseVideoId, setCourseVideoId] = useState('');
    
    const handleCourseClick =  (value, actid) => {
        setCourseVideoId(value);
        setActiveId(actid);
    }
    
    let { id } = useParams();

    useEffect(()=>{

        window.scrollTo(0, 0);

        let api = new ApiService();

        api.getCourse(id).then(_cou => {
            setCourses(_cou);

            //console.log(_cou);
            
            setCourseVideoId(_cou.previewVideo);
            
         }).catch((e) => {
     
         });

    },[])

    if(!coures) {
        return <></>
    } 
    return (
        <>
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="breadcums-info">
                    <div className="breadcrumbs-arrow">
                        <Link to="">
                            <img src="../../../../images/Icons/bredcumes-back.png" />
                        </Link>
                    </div>
                    <div className="breadcrums-titles">
                        <Link to="/">Home </Link>
                        <Link to={`/coursecategory/${coures.course_category.id}`}> / {coures.course_category.name}</Link>
                        <Link> / {coures.Name}</Link>
                    </div>
                </div>
            </div>
        </div>
         <div className="course-detail-page">
           <div className="container">
                <div className="course-detail-info"> 
                    <CourseDetailLeftBox  courses={coures} video={courseVideoId} user={props.user }/> 
                    <CourseDetailRightBox  courses={coures} onhandle={handleCourseClick}  user={props.user } activeId={activeId} /> 
                </div>
            </div>
            <div className="bottom-section">
                <div className="container">
                    <DetailBottomSection  courses={coures} />
                </div>
            </div>
        </div>
        </>
    );

}

function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(CourseDetailItems);