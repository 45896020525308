import React, { useState } from 'react';
import SimpleInput from "../Input/SimpleInput";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import SimpleButton from "../Buttons/SimpleButton";
import ApiService from '../../../helpers/ApiService';

const ChangePasswordModal = (props) => {

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let i = {...input};
        i[event.target.name] = event.target.value;
        setInput(i);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);

            let url_string = window.location.href;
            let url = new URL(url_string);
            let code = url.searchParams.get("code");
            
            let api = new ApiService();

            api.resetPassword({
                code:code,
                password: input.password,
                passwordConfirmation: input.confirm_password,
            }).then(response => {
                props.showNext();
            })
              .catch(errors => {
                setErrors({error: errors.message[0].messages[0].message});
              }).finally(()=>{
                setLoading(false);
              });
              
        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }

        if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
        }

        if (input["password"] !== input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "password and confirm password  not matched";
        }


        setErrors(errors);
        return isValid;
    }
    return (
        <div className="dark">
            <div className="modal-head">
                <h2>CHANGE PASSWORD</h2>
                <div onClick={props.close} className="close-icon">
                    <img src="../../../../images/Icons/close-icon.png" />
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <SimpleInput
                    className={errors.password ? "form-control error" : "form-control"}
                    type="password"
                    name="password"
                    value={input.password}
                    onChange={handleChange}
                    placeholder="Enter new password"
                    errors={errors.password}
                />
                <SimpleInput
                    className={errors.confirm_password ? "form-control error" : "form-control"}
                    type="password"
                    name="confirm_password"
                    value={input.confirm_password}
                    onChange={handleChange}
                    placeholder="Confirm password"
                    errors={errors.confirm_password}
                />
                <SimpleButton onClick={handleSubmit} loading={loading} name="Save" />
                <Button className="defalut-buton-text" onClick={props.login}>Login</Button>
                { errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
            </Form>
        </div>
    );
}

export default ChangePasswordModal;