import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';


const PreviousMobileTable = (props) => {

    const [showTabContent, setShowTabContent] = useState(false);

    return (
        <div className="pdp-mobile-view">
            <Link className="tabs-mobile-head" onClick={() => setShowTabContent(!showTabContent)}>
                <span>Disinfection and Decontamination</span>
                {showTabContent
                ?
                <img src='../../../../../images/Icons/tab-up.svg' />
                :
                <img src='../../../../../images/Icons/tab-down.svg' />
                }
            </Link>
            {showTabContent &&
                <div className="tabs-mobile-content">
                    <div className="mobile-content-col">
                        <span>Duration</span>
                        <p>2:00 Hours</p>
                    </div>
                    <div className="mobile-content-col">
                        <span>When will I complete this course</span>
                        <p>11/12/2020</p>
                    </div>
                    <div className="mobile-content-col">
                        <span>GDC development outcomes</span>
                        <p>A</p>
                    </div>
                    <div className="mobile-content-col bordernone">
                        <span>Complete reflections now</span>
                        <Link to="/coursereflection">Reflection</Link>
                    </div>
                </div>
            }
        </div>
    );
}

export default PreviousMobileTable;
