import React from 'react';
import DefaultHeading from "../../../../../components/Heading/DefaultHeading";
import CompetitionsList from "./CompetitionsList";





const CompetitionsItems = (props) => {
    return (
        <>
            <div className="events-items">
                <div className="container">
                    <DefaultHeading heading={props.heading} />
                    <CompetitionsList Items={props.CompetitionsItem}/>
                </div>
            </div>
        </>
    );

}

export default CompetitionsItems;