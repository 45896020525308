import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ReflectionInfo = (props) => {

    return (
        <div className="reflection-info">
            <div className="reflection-left">
                <div className="reflection-col">
                    <span>Course Title -</span>
                    <p>Complaints Handling CPD</p>
                </div>
                <div className="reflection-col">
                    <span>Durarion -</span>
                    <p>I Hour</p>
                </div>
                <div className="reflection-col">
                    <span>Pass Score -</span>
                    <p>90%</p>
                </div>
                <div className="reflection-col">
                    <span>Reflective Learning -</span>
                    <p>Yes</p>
                </div>
            </div>
            <div className="reflection-right">
                <div className="reflection-col">
                    <span>Start Date -</span>
                    <p>11/12/2020</p>
                </div>
                <div className="reflection-col">
                    <span>Completion Date -</span>
                    <p>15/12/2020</p>
                </div>
                <div className="reflection-col">
                    <span>GDC Outcomes -</span>
                    <p>B</p>
                </div>
            </div>
        </div>
    );
}

export default ReflectionInfo;
