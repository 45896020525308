import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../components/Header/MainHeader';
import Footer from '../../../components/Footer/Footer';
import PdpHubBreadCrumbs from './components/PdpHubBreadCrumbs';
import PdpHubItems from './components/PdpHubItems';



const PdpHub =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <PdpHubBreadCrumbs hubname="PDP Hub"/>
            <PdpHubItems/>
            <Footer/>
        </>
    );

}

export default PdpHub;