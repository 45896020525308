import jwt_decode from 'jwt-decode';
import React from 'react'
import {Route} from 'react-router-dom';
import Home from "../main/home/Home";
import UserProfiles from "../main/UserProfiles/UserProfiles";
import Subscription from "../main/Subscription/Subscription";
import CourseList from '../main/Courses/CourseList';
import CourseDetail from '../main/Courses/CourseDetail';
import TutorDetail from "../main/home/TutorDetail";
import Quiz from '../main/Quiz/Quiz';
import FeedBack from '../main/Feedback/FeedBack';
import QuizCompleted from '../main/Quiz/QuizCompleted';
import QuizFailed from '../main/Quiz/QuizFailed.';
import MyCertificates from '../main/Quiz/Certificates/MyCertificates';
import CourseCategory from '../main/CourseCategories/CourseCategory';
import FoudationYearsCategory from '../main/CourseCategories/FoudationYears/FoudationYearsCategory';
import LearningResources from '../main/CourseCategories/FoudationYears/LearningResources/LearningResources';
import LearningResourcesDetail from "../main/CourseCategories/FoudationYears/LearningResources/LearningResourcesDetail";
import JournalClubCategory from '../main/CourseCategories/JournalClubCategory/JournalClubCategory';
import AspireNews from '../main/CourseCategories/AspireNews/AspireNews';
import { connect } from "react-redux";
import { updateUser } from '../../redux/actions/user';
import ApiService from '../../helpers/ApiService';
import TalkDental from "../main/CourseCategories/FoudationYears/TalkDental/TalkDental";
import TalkDentalDetail from "../main/CourseCategories/FoudationYears/TalkDental/TalkDentalDetail";
import AspireNewsDetails from '../main/CourseCategories/AspireNews/AspireNewsDetail';
import Career from "../main/CourseCategories/FoudationYears/Career/Career";
import Events from "../main/CourseCategories/FoudationYears/Events/Events";
import Competitions from "../main/CourseCategories/FoudationYears/Competitions/Competitions";
import Dashboard from '../main/Dashboard/Dashboard';
import PdpHub from '../main/Dashboard/PdpHub/PdpHub';
import PersonalPlaning from '../main/Dashboard/PdpHub/PersonalPlaning.js/PersonalPlaning';
import FieldOfPractice from '../main/Dashboard/PdpHub/PersonalPlaning.js/FieldOfPractice';
import ChoosePdpCourses from '../main/Dashboard/ChoosePdp/ChoosePdpCourses';
import Reflection from '../main/Dashboard/Reflection/Reflection';
import GdcAnnualStatement from '../main/Dashboard/GdcAnnualStatement/GdcAnnualStatement';
import Gdc5Year from '../main/Dashboard/Gdc5YearCycle/Gdc5Year';
import PreviousLearningCycle from '../main/Dashboard/PreviousLearningCycle/PreviousLearningCycle';
import ExternalPdpCourse from '../main/Dashboard/ExternalPdpCourses/ExternalPdpCourse';
import Page from '../main/page/Page';
import Mentorship from '../main/Mentorship /Mentorship';
import MentorshipDashboard from '../main/Mentorship /MentorshipDashboard/MentorshipDashboard';
import ArchivedPatients from '../main/Mentorship /ArchivedPatients/ArchivedPatients';
import ViewConversation from '../main/Mentorship /ArchivedPatients/ViewConversation/ViewConversation';
import MentorRegister from '../main/Mentorship /MentorshipDashboard/MentorRegister/MentorRegister';
import ArchivedCalls from '../main/Mentorship /ArchivedCalls/ArchivedCalls';
import MentorDashboard from '../main/Mentorship /MentorDashboard/MentorDashboard';

const MainRoute = ({component: Component, updateUser}) => {

    React.useEffect(()=>{


        let api = new ApiService();
        let jwt = localStorage.getItem('jwt');

        if (jwt) {
            let data = jwt_decode(jwt);
            if (data) {
                api.getMeUser().then(user => {
                    updateUser(user)  
                  }).catch((e) => {
                     console.log(e);
                  });

                    
            }
        }
    },[])

    return (
        <Route
            render={props => (
                <div className="content-container">
                    
                    {/* Body Content */}

                    <div>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/subscription" component={Subscription}/>
                        <Route exact path="/profile" component={UserProfiles}/>
                        <Route exact path="/courselist" component={CourseList}/>
                        <Route exact path="/coursedetail/:id" component={CourseDetail}/>
                        <Route exact path="/pages/:id" component={Page}/>
                        <Route exact path="/quiz/:id" component={Quiz}/>
                        <Route exact path="/feedback/:id/:type" component={FeedBack}/>
                        <Route exact path="/quizcomplete/:id/:type" component={QuizCompleted}/>
                        <Route exact path="/quizfailed/:id/:type" component={QuizFailed}/>
                        <Route exact path="/certificates" component={MyCertificates}/>
                        <Route exact path="/coursecategory/:id" render={(props)=>{
                            return <CourseCategory key={props.match.params.id} {...props} />
                        }}/>
                        <Route exact path="/foudationyears/:id" component={FoudationYearsCategory}/>
                        <Route exact path="/tutors/:id" component={TutorDetail}/>
                        <Route exact path="/students/:id" component={FoudationYearsCategory}/>
                        <Route exact path="/learningresources/:id" component={LearningResources}/>
                        <Route exact path="/learningresourcesdetail/:type/:id" component={LearningResourcesDetail}/>
                        <Route exact path="/talkdental/:id" component={TalkDental}/>
                        <Route exact path="/talkdentaldetail/:type/:id" component={TalkDentalDetail}/>
                        <Route exact path="/career/:id" component={Career}/>
                        <Route exact path="/events/:id" component={Events}/>
                        <Route exact path="/competitions/:id" component={Competitions}/>
                        <Route exact path="/journalcategory" component={JournalClubCategory}/>
                        <Route exact path="/aspirenews" component={AspireNews}/>
                        <Route exact path="/aspirenews/:id" component={AspireNewsDetails}/>
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route exact path="/pdphub" component={PdpHub}/>
                        <Route exact path="/planing" component={PersonalPlaning}/>
                        <Route exact path="/fieldpractice" component={FieldOfPractice}/>
                        <Route exact path="/choosepdp" component={ChoosePdpCourses}/>
                        <Route exact path="/coursereflection" component={Reflection}/>
                        <Route exact path="/gdcannual" component={GdcAnnualStatement}/>
                        <Route exact path="/gdc5year" component={Gdc5Year}/>
                        <Route exact path="/previouslearning" component={PreviousLearningCycle}/>
                        <Route exact path="/externalpdp" component={ExternalPdpCourse}/>

                        {/* Mentorship */}
                        <Route exact path="/becomementor" component={MentorRegister}/>
                        <Route exact path="/mentorship" component={Mentorship}/>
                        <Route exact path="/mentorshipdashboard" component={MentorshipDashboard}/>
                        <Route exact path="/archivedpatients" component={ArchivedPatients}/>
                        <Route exact path="/archivedcalls" component={ArchivedCalls}/>
                        <Route exact path="/viewconversation/:id" component={ViewConversation}/>
                        <Route exact path="/mentordashboard" component={MentorDashboard}/>
                    </div>
                </div>
            )}
        />
    )
};

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return {
        updateUser: user => dispatch(updateUser(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRoute);