import React, { useEffect } from 'react';
import { Col, Container, Row, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FooterAccordion from "./components/FooterAccordion";
import ApiService from '../../../helpers/ApiService';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { connect } from "react-redux";

const Footer = (props) => {

    const [categories, setCategories] = React.useState([]);

    const url = "https://aspiredentalacademy.us16.list-manage.com/subscribe/post?u=d7e60a27efae2f353f1808c79&amp;id=22884b5461";

    useEffect(async () => {
        let api = new ApiService();

        api.getCategories().then(_cats => {
            let sortCat = _cats.sort((a, b) => a.verticalPosition - b.verticalPosition);
            setCategories(sortCat.filter(cat => {
                return cat.parent === null && cat.verticalPosition != 0;
            }));
        }).catch((e) => {

        });

    }, [])

    if(props.user){
       // console.log(props.user.data);
    }
    

    return (
        <footer className="footer float-left w-100">
            <Container>
                <div className="hide-for-mobile">
                    <Row>
                        <Col md={3} xs={6}>
                            <span className="col-head">Explore Categories</span>
                            <ul>
                                {categories.map(category => {
                                    return <li className="nav-item">
                                        {category.name == 'Aspire Journal Club' ? <Link to="/journalcategory" className="">{category.name}</Link>
                                            : category.name == 'Aspire Students' ? <Link to="/students/2" className="">{category.name}</Link>
                                                : category.name == 'Aspire Foundation' ? <Link to="foudationyears/1" className="">{category.name}</Link>
                                                    : category.name == 'Aspire Live' ? <Link to="#" className="">{category.name}</Link>
                                                        : category.name == 'Aspire News' ? <Link to="/aspirenews" className="">{category.name}</Link>
                                                            : category.name == 'Aspire Mentorship' ? <Link to="/mentorship" className="">{category.name}</Link>
                                                                : <Link to={`/coursecategory/${category.id}`} className="">{category.name}</Link>
                                        }
                                    </li>
                                })}
                            </ul>
                        </Col>
                        <Col md={3} xs={6}>
                            <span className="col-head">About</span>
                            <ul>
                            {!props.user && 
                                <li><Link to="/becomementor">Become a Mentor</Link></li> 
                            }
                            {props.user  && props.user.data.mentor !== 'yes' &&
                               <li><Link to="/becomementor">Become a Mentor</Link></li> 
                            }
                                <li><Link to="/pages/1">Privacy Policy</Link></li>
                                <li><Link to="/pages/2">Terms & Conditions</Link></li>
                                <li><Link to="/pages/3">Equality & Diversity Policy</Link></li>
                                <li><Link to="/">Contact Us</Link></li>
                            </ul>
                        </Col>
                        <Col md={3} xs={6}>
                            <span className="col-head">Social Media</span>
                            <ul className="phone-mt-3">
                                <li><Link to="/"><img src="../../../../images/Icons/facebook.svg" alt={"Facebook"} /> Facebook</Link>
                                </li>
                                <li><Link to="/"><img src="../../../../images/Icons/insta.svg" alt={"Instagram"} /> Instagram</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3} xs={12}>
                            <span className="col-head">Contact Details</span>
                            <ul className="phone-mt-3">
                                <li><Link to="/"><img src="../../../../images/Icons/email.svg" alt={"Email"} /> example12@gmail.com</Link>
                                </li>
                                <li><Link to="/"><img
                                    src="../../../../images/Icons/email.svg" alt={"Email"} /> example123@gmail.com</Link></li>
                            </ul>
                            <div className="subscribe-box">
                                <span className="col-head">Newsletter</span>
                                <div className="subscribe-form">
                                    <MailchimpSubscribe url={url} />
                                    {/* <input type="email" name="subscribe" placeholder="Email address"/>
                                    <Button className="subscribe-buton">Subscribe </Button> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="hide-for-desktop">
                    <FooterAccordion />
                    <div className="mobile-newsletter">
                        <div className="subscribe-form">
                            <MailchimpSubscribe url={url} />
                        </div>
                    </div>
                    <div className="mobile-social-media">
                        <ul className="phone-mt-3">
                            <li><Link to="/"><img
                                src="../../../../images/Icons/fb-new.png" alt={"Facebook"} /> </Link></li>
                            <li><Link to="/"><img src="../../../../images/Icons/google.png" alt={"Google"} /> </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="copy-riht-text">
                        <p>© 2021 Aspire online. All rights<br />
                            reserved</p>
                    </div>
                </div>
            </Container>
        </footer>
    );
}

function mapStateToProps(state) {
    return {user:state.user}
}



export default connect(mapStateToProps)(Footer);

