import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../../../../helpers/ApiService';
import { Modal } from "react-bootstrap";
import ScheduleModal from '../../../../../components/Modals/ScheduleModal';
import customConfirmDialog from '../../../../../CustomConfirmDialog';


const UpcomingCallsTable = (props) => {
    
    let api = new ApiService();

    const d = new Date();

        let startdate = d.toISOString().slice(0, 10);

    const [showStatus, setShowStatus] = useState(false);
    const [showPaymentStatus, setShowPaymentStatus] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [selectedPatient, setSelectedPatient] = React.useState({ patient: "" });
    const [selectedslot, setSelectedSlot] = React.useState({ slot: "" });



    // ReSchedule Modal
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const rescheduleModalClose = () => setRescheduleModal(false);
    const rescheduleModalShow = (bookslotid , patientid) => {

        customConfirmDialog("Are you sure", "Are you sure you want to Reschedule?", () => {
        
            setRescheduleModal(true);

            setSelectedPatient(patientid);

            setSelectedSlot(bookslotid);
            
        }, "Yes", () => {

        }, "No")
    };

    let userscheduleCalls = [];
    if (props.scheduleCalls) {

        

        let objects = props.scheduleCalls;

        //console.log(objects);

        if (objects.length === 0) {
            userscheduleCalls = [];
        } else {
            userscheduleCalls = objects;

            //console.log(userpatients);
        }
    }

    const statusChangedBooking = (bookslot) => {
        customConfirmDialog("Are you sure", "Are you sure you want to cancel?", () => {
        
            var data = {
                statusChanged: 1,
                id: bookslot,
            }
    
            api.updateBooking(data)
                .then(response => {
                    setLoading(false);
                    window.location.reload();
    
                }).catch(errors => {
                    //console.log(errors);
                    setErrors({ error: errors.message[0].messages[0].message });
                }).finally(() => {
                    setLoading(false);
                });
            
        }, "Yes", () => {

        }, "No")

    };

    

    return (
        <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Mentor</th>
                        <th>Patients</th>
                        <th>Date & Time</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {userscheduleCalls.map(userscheduleCall => {
                        if (userscheduleCall.status === 'Scheduled' && userscheduleCall.callDate >= startdate) {
                            return <tr>
                                <td>{userscheduleCall.mentor.fullName}</td>
                                <td >
                                    <div className='patient-detail'>
                                        <span>{userscheduleCall.patient.patientName}</span>
                                    </div>
                                </td>
                                <td>{userscheduleCall.callDate}, {userscheduleCall.callSlot}</td>
                                <td>{userscheduleCall.status}</td>
                                <td>
                                    <div className='mentor-actions'>
                                        <div className='view-conversation' >
                                            {/* <Link to={`/viewconversation/${userscheduleCall.patient.id}`} >Reshedule</Link> */}
                                             <Link onClick={() => rescheduleModalShow(`${userscheduleCall.id}`, `${userscheduleCall.patient.id}`) } >Reshedule</Link> 
                                        </div>
                                        <div className='mentor-edit'>
                                            <span onClick={() => statusChangedBooking(`${userscheduleCall.id}`)}>Cancel</span>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        }
                    })}

                </tbody>
            </table>

            {/*  ReSchedule Modal */}
            <Modal show={rescheduleModal} onHide={rescheduleModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="authModal cardModal Login-modals schedule"
            >
                <ScheduleModal  patientId={selectedPatient} slotid={selectedslot} close={rescheduleModalClose} /> 
            </Modal>
        </div>
    );
}

export default UpcomingCallsTable;
