import React, { useEffect } from 'react';


const PaperReview = (props) => {
    if(!props.papers) {
        return <></>
    } 
    return (
        <div className="paper-review">
           {props.papers.map((paper , index) => {
            if ( index%2 === 0) {
            return <div className="boxes-col" key={index}>
                <div className="video-box">
                    <div className="embed-responsive embed-responsive-16by9">
                       <iframe className="embed-responsive-item"
                            src={`https://player.vimeo.com/video/${paper.video}`} frameborder="0"
                            allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="right-review">
                    <h4>{paper.name}:</h4>
                    <div className="author-box">
                        <p><strong>Author</strong>{paper.author}</p>
                    </div>
                    <div className="author-box">
                        <p><strong>Journal</strong>{paper.description}</p>
                    </div>
                    <div className="author-box">
                        <p><strong>Reference</strong>{paper.reference}</p>
                    </div>
                </div>
            </div>
            } else {
                return  <div className="boxes-col" key={index}>
                <div className="right-review pl-0 pr-4">
                    <h4>{paper.name}:</h4>
                    <div className="author-box">
                        <p><strong>Author</strong>{paper.author}</p>
                    </div>
                    <div className="author-box">
                        <p><strong>Journal</strong>{paper.description}</p>
                    </div>
                    <div className="author-box">
                        <p><strong>Reference</strong>{paper.reference}</p>
                    </div>
                </div>
                <div className="video-box ">
                <div className="embed-responsive embed-responsive-16by9">
                       <iframe className="embed-responsive-item"
                            src={`https://player.vimeo.com/video/${paper.video}`} frameborder="0"
                            allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            }
        })}
        </div>
    );
}

export default PaperReview;