import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';


const TimeSlotsTable = (props) => {
//console.log(props.slotsTime);
    function getTimeStops(start, end){
        var startTime = Moment(start, 'HH:mm');
        var endTime = Moment(end, 'HH:mm');
        
        if( endTime.isBefore(startTime) ){
          endTime.add(1, 'day');
        }
      
        var timeStops = [];
      
        while(startTime <= endTime){

            timeStops.push(`${new Moment(startTime).format('HH:mm A')} - ${new Moment(startTime.add(30, 'minutes')).format('HH:mm A')}`); 
        //   timeStops.push(new Moment(startTime).format('HH:mm A'));
        //   startTime.add(30, 'minutes');


        }
        
         return timeStops.sort();
      }
      
    return (
        <div className="pdp-table">
            <div className="row">
                <div className='slots-boxes-colum'>
                {props.slotsTime.map((data) => {
                        if(props.tday == 1){
                           let gettimeslotsmain = getTimeStops(data.starttimem, data.endtimem);
                                //console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            } else if(props.tday == 2){
                                let gettimeslotsmain = getTimeStops(data.starttimet, data.endtimet);
                                console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            }  else if(props.wday == 3){
                                let gettimeslotsmain = getTimeStops(data.starttimew, data.endtimew);
                                //console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            }  else if(props.thday == 4){
                                let gettimeslotsmain = getTimeStops(data.starttimeth, data.endtimeth);
                                //console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            }  else if(props.fday == 5){
                                let gettimeslotsmain = getTimeStops(data.starttimef, data.endtimef);
                                //console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            }  else if(props.saday == 6){
                                let gettimeslotsmain = getTimeStops(data.starttimesa, data.endtimesa);
                                //console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            }  else if(props.suday == 7){
                                let gettimeslotsmain = getTimeStops(data.starttimesu, data.endtimesu);
                                //console.log(gettimeslotsmain);
                                    return (
                                            <>
                                                { gettimeslotsmain.map((dataslots) => {
                                                    return (
                                                        <div className='col-md-3'>
                                                            <div className='slots-timing-box'>
                                                                <span>{dataslots}</span>
                                                            </div>
                                                        </div>
                                                )
                                                })
                                                }
                                            </>
                                        )
                            }
                        
                    })
                }
                </div>
                </div>

        </div>
    );
}

export default TimeSlotsTable;
