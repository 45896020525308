import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import DashboardBredcrumbs from './components/DashboardBredcrumbs';
import DashBoardItems from './components/DashBoardItems';



const Dashboard =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <DashboardBredcrumbs
             dashname="My Personal Training dashboard"
             tooltip="../../../images/Icons/tooltip.svg"
            />
            <DashBoardItems/>
            <Footer/>
        </>
    );

}

export default Dashboard;