import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';



const FieldPracticeList = (props) => {

    return (
        <div className="field-practice-list">
            <div className="practice-items">
                <div className="practice-meta">
                    <h3>My work settings</h3>
                    <p>What is your place(s) of work and its environment? .e.g. Mixed (NHS/Private) general practice, Specialist practice, Hospital etc.
                    </p>
                </div>
                <div className="practice-form">
                    <textarea placeholder="Write here.........................."
                    />
                </div>
            </div>
            <div className="practice-items">
                <div className="practice-meta">
                    <h3>Any additional roles, qualifications or  professional interests:</h3>
                    <p>Do you have additional roles, qualifications, specialties or 
                        areas of focus/interest?  Do any of these need continual learning 
                        or maintenance? e.g. Sedation diploma, organise practice meetings, reception duties 
                    </p>
                </div>
                <div className="practice-form">
                    <textarea placeholder="Write here.........................."
                    />
                </div>
            </div>
            <div className="practice-items">
                <div className="practice-meta">
                    <h3>My patient Population:</h3>
                    <p>What are the oral health and management needs of patients in your care? Does this change across your work settings? e.g. 
                        Large elderly population, General population, Mainly children etc
                    </p>
                </div>
                <div className="practice-form">
                    <textarea placeholder="Write here.........................."
                    />
                </div>
            </div>
        </div>
    );
}

export default FieldPracticeList;
