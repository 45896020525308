import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SimpleHeading from '../../../../../components/Heading/SimpleHeading';



const LearningCycle = (props) => {

    return (
        <div className="learning-box-cycle">
            <SimpleHeading heading="Learning Cycle" />
            <div className="cycle-descrption">
                <span>To check the start and end dates of learning cycles,  </span>
                <Link>Click here</Link>
            </div>
        </div>
    );
}

export default LearningCycle;
