import React from 'react';
import DefaultHeading from '../../../../components/Heading/DefaultHeading';

import AspireNewBoxes from "./AspireNewBoxes";
import AspireNewNews from "./AspireNewNews";

const AspireNewItems = (props) => {
    if(!props.items) {
        return <></>
    }
    return (
        <>
            <div className="talkdental-items">
                <div className="container">
                    <DefaultHeading heading="Aspire News"/>
                    <AspireNewBoxes talkDentals={props.items} />
                    {/* <AspireNewNews talkDentals={props.items} /> */}
                </div>
            </div>
        </>
    );

}

export default AspireNewItems;