import { Link, Redirect } from 'react-router-dom';
import React from 'react';
import DefaultHeading from '../../../components/Heading/DefaultHeading';
import ConnectDirectlywith from './SubComponents/ConnectDirectlywith';
import MentorshipGetStarted from './SubComponents/MentorshipGetStarted';
import MentorshipSteps from './SubComponents/MentorshipSteps';


const MentorshipItems = (props) => {
    
    let maxObj = 0;
    if(props.user){
        //console.log(props.user.user_subscriptions);

        let objects = props.user.user_subscriptions;
          //console.log(objects);
         if(objects.length > 0){
            
         maxObj = objects.reduce((prev, current) => {
            if (+current.id > +prev.id) {
                return current;
            } else {
                return prev;
            }
        });
    }
   } 

   let jwt = localStorage.getItem('jwt');

   if (!jwt) {
       return <Redirect to={{
           pathname: "/register",
           state: { url: "/mentorship" },
         }}
          />
   }

   if(!props.user){
       return <></>;
   } else {
    console.log(props.user);
   }

    return (
        <div className="foudation-years" style={{paddingBottom:0}}>
            <div className="container">
                <DefaultHeading heading="Mentorship " />
                <MentorshipGetStarted  userplan={ maxObj } usertype={ props.user.data }/>
                <ConnectDirectlywith />
                <MentorshipSteps  userplan={ maxObj } usertype={ props.user.data }/>
            </div>
            {/* {!props.user && 
            <div className='become-a-mentor'>
                <span>Do you want to become a mentor ?</span>
                <Link className='become-mentor-buton' to="/register">
                   REGISTRATION FIRST
                </Link>
            </div>
            } */} 
            {props.user && props.user.data.mentorSpeciality === '' && props.user.data.mentor !== 'yes' &&
            <div className='become-a-mentor'>
                <span>Do you want to become a mentor ?</span>
                <Link className='become-mentor-buton' to="/becomementor">
                    APPLY NOW
                </Link>
            </div>
            }

            {props.user && props.user.data.mentorSpeciality !== '' && props.user.data.mentor !== 'yes' &&
            <div className='become-a-mentor'>
                <span>Request Sent For Become A Mentor.</span>
            </div>
            }
        </div>
    );
}

export default MentorshipItems;
