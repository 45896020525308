import React from 'react';
import {Link} from "react-router-dom";

const EmailVerificationModal = (props)=> {
        return (
            <>
                <div className="modal-head for-email-verify">                   
                    <div onClick={props.close} className="close-icon">
                        <img src="../../../../images/Icons/close-icon.png"/>
                    </div>
                </div>
                <div className="email-verification">
                    <img src="../../../../images/Icons/emailverify.svg"/>
                    <h4>Please Check Your Email</h4>
                    <span>Dear user, you have succssfully registered.<br/>
                        We’ve sent you an email with a link to verify your account.
                    </span>
                    <span>If you do not see the email, <br/>
                        Please make sure you check your spam folder
                    </span>
                </div>
            </>
        );
}

export default EmailVerificationModal;