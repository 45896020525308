import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import DashboardBredcrumbs from '../components/DashboardBredcrumbs';
import ChoosePdpItems from './components/ChoosePdpItems';



const ChoosePdpCourses =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <DashboardBredcrumbs dashname="Choose PDP Courses"/>
            <ChoosePdpItems/>
            <Footer/>
        </>
    );

}

export default ChoosePdpCourses;