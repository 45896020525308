import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../../components/Header/MainHeader';
import Footer from '../../../../components/Footer/Footer';
import DashboardBredcrumbs from '../../components/DashboardBredcrumbs';
import FieldPracticeItems from './components/FieldPracticeItems';




const FieldOfPractice =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <DashboardBredcrumbs dashname="Personal Development Planing"/>
            <FieldPracticeItems/>
            <Footer/>
        </>
    );

}

export default FieldOfPractice;