import React, { useState } from 'react';
import UrlResolver from '../../../../../../helpers/UrlResolver';
import DefaultHeading from '../../../../../components/Heading/DefaultHeading';
import { Modal } from "react-bootstrap";




const LearningResourcesItems = (props) => {

    // Learning Resources
    const [showModal, setShowModal] = useState(false);
    const [videoId, setVideoId] = useState('');
    const showModalClose = () => setShowModal(false);


    const handleClick = (value) => {
        setVideoId(value);
        // console.log(value);
        setShowModal(true)

    }

    if (!props.foundationYears) {
        return <></>
    }


    return (
        <>
            <div className="Learning-resources-section">
                <div className="container">
                    <DefaultHeading heading={props.heading} />
                    <div className="resources-list">
                        <div className="learning-heading">
                            <div className="simple-heading width-auto">
                                <h3>Webinars</h3>
                                <span></span>
                            </div>
                        </div>
                        <div className='spacing-between mt-5'>
                            {props.cat1.map(learnResorce => {
                                return <div className="col-md-3">
                                    <a href={`/learningresourcesdetail/${props.type}/${learnResorce.id}`} >
                                        <div className="courses-slider new-heights2">
                                            <img src={UrlResolver.generateMediaUrl(learnResorce.videobanner.url)}
                                                alt={learnResorce.videobanner.hash} />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>{learnResorce.name}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>{`${learnResorce.description.substring(0, 100)}...`}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="resources-list">
                        <div className="learning-heading">
                            <div className="simple-heading width-auto">
                                <h3>Other Section 1</h3>
                                <span></span>
                            </div>
                        </div>
                        <div className='spacing-between mt-5'>
                            {props.cat2.map(learnResorce => {
                                return <div className="col-md-3">
                                    <a href={`/learningresourcesdetail/${props.type}/${learnResorce.id}`} >
                                        <div className="courses-slider new-heights2" >
                                            <img src={UrlResolver.generateMediaUrl(learnResorce.videobanner.url)}
                                                alt={learnResorce.videobanner.hash} />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>{learnResorce.name}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>{`${learnResorce.description.substring(0, 100)}...`}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="resources-list">
                        <div className="learning-heading">
                            <div className="simple-heading width-auto">
                                <h3>Other Section 2</h3>
                                <span></span>
                            </div>
                        </div>
                        <div className='spacing-between mt-5'>
                            {props.cat3.map(learnResorce => {
                                return <div className="col-md-3">
                                    <a href={`/learningresourcesdetail/${props.type}/${learnResorce.id}`} >
                                        <div className="courses-slider new-heights2" onClick={() => handleClick(learnResorce.video)}>
                                            <img src={UrlResolver.generateMediaUrl(learnResorce.videobanner.url)}
                                                alt={learnResorce.videobanner.hash} />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>{learnResorce.name}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>{`${learnResorce.description.substring(0, 100)}...`}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            })}
                        </div>
                    </div>

                </div>
            </div>

            {/*  Video Popup Modal  */}
            <Modal show={showModal} onHide={showModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="authModal cardModal slider-popup "
            >
                <div className="slider-modal-video">
                    <iframe
                        style={{


                        }}
                        src={`https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&autopause=0&autoplay=1`}
                        frameBorder="0"
                    />
                </div>
            </Modal>
        </>
    );

}

export default LearningResourcesItems;
