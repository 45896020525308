import React, { useEffect } from 'react';
import { Link } from "react-router-dom";



const CategoryRightSection = (props) => {
    return (

        <div className="category-right-section">
            <div className="video-box">
                <div className="embed-responsive embed-responsive-16by9">
                   <iframe className="embed-responsive-item" src={`https://player.vimeo.com/video/${props.Video}`} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    );

}

export default CategoryRightSection;