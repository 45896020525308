import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Link} from "react-router-dom";
import UrlResolver from '../../../../../helpers/UrlResolver';


class TalkDentalSlider extends Component {
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    render() {
        let {talkDentals} = this.props;

        let talkType = "";

        if (!talkDentals || talkDentals.length === 0) {
            return <></>
        } else {
            talkType = this.props.type;
        }
        return (
            <div className="float-left w-100 mt-4 dots-style">
                <OwlCarousel ref={this.sliderRef}
                    className="owl-theme"
                    items={1}
                    autoplay={false}
                    dots={true}
                    loop={true}
                    margin={30}
                    nav={false}
                    autoplayHoverPause={true}
                    autoFocus={true}
                    autoplaySpeed={2000}
                    animateIn="linear"
                    smartSpeed={300}
                    responsive={this.state.responsive}
                >
                {talkDentals.map(talkDental => {
                    return <div className="item">
                        <div className="courses-slider new-heights1" >
                        
                        <img src={UrlResolver.generateMediaUrl(talkDental.banner.url)}
                                         alt={talkDental.banner.hash}/>
                            <div className="course-cat-name">
                                <span>{talkDental.title}</span> 
                                <p>{`${talkDental.description.substring(0, 140)}...`}</p>
                                <Link to={`/talkdentaldetail/${talkType}/${talkDental.id}`}>View more</Link>
                            </div>
                        </div>
                    </div>
                })}
                
                </OwlCarousel>
            </div>
        );
    }
}

export default TalkDentalSlider;