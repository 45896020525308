import React, {useState , useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../components/Header/MainHeader';


import AspireNewDetails from "./components/AspireNewDetails";

import Footer from '../../../components/Footer/Footer';
import CategoryBreadcrumbs from '../components/CategoryBreadcrumbs';
import ApiService from '../../../../helpers/ApiService';





const AspireNewDetail =(props)=> {
    

   const [item, setItem] = React.useState([]); 
    
   let api = new ApiService();

   

    let parameter = useParams();


    useEffect(async () => {

        window.scrollTo(0, 0);

        api.getAspireNews().then(_news => {

            let __FOUND = _news.aspireNews.find((post, index) => {
                if(post.id == parameter.id)
                    return true;
            });
            setItem(__FOUND);
        }).catch((e) => {
     
         });
        }, [])
        
        if(!item) {
            return <></>
        }

    
    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={item.title} /> 
            <AspireNewDetails TalkDental={item}/>
            <Footer/>
            

        </>
    );

}

export default AspireNewDetail;