import * as Actions from '../actions/theme';

const initialState = {
    theme: 'dark'
};
export const themeReducer = (state = initialState, action) => {
    //console.log(action);
    switch (action.type) {
        case Actions.APPLY_THEME:
            return Object.assign({}, { theme: action.payload } );
        default:
            return state;
    }
};
