import React, {useState} from 'react';
import DefaultHeading from "../../../../../components/Heading/DefaultHeading";
import SliderCareers from "./SliderCareers";
import { Modal } from "react-bootstrap";




const CareerItems = (props) => {

    
    const [videoCareerId, setVideoCareerId] = useState('274648225');
    

    const handleCareerClick =  (value) => {
        setVideoCareerId(value);
        

    }


    if(!props.foundationYears) {
        return <></>
    }
    if(!props.foundationYears.Careers) {
        return <></>
    }
    return (
        <>
            <div className="careers-items">
                <div className="container">
                    <DefaultHeading heading={props.heading} />
                    <div className="video" style={{
                        position: "relative",
                        paddingBottom: "56.25%" /* 16:9 */,
                        paddingTop: 25,
                        height: 0
                        }}>
                            <iframe
                                style={{
                                    position: "absolute",
                                     top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%"
                                        }}
                                src={`https://player.vimeo.com/video/${videoCareerId}?autoplay=1&loop=1&autopause=0&autoplay=1`}
                                frameBorder="0"
                            />
                    </div>
                    <div className="career-border"></div>
                    <div className="slider-career">
                        <SliderCareers careers={props.foundationYears.Careers} oncareerhandle={handleCareerClick}/>
                    </div>
                </div>
            </div>

        </>
    );

}

export default CareerItems;