import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import UrlResolver from '../../../../../helpers/UrlResolver';


class LearningSlider extends Component {
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    render() {
        let {learnResorces} = this.props;
        let talkType = "";
        if (!learnResorces || learnResorces.length === 0) {
            return <></>
        } else {
            talkType = this.props.type;
        }
        return (
            <div className="float-left w-100 mt-4 dots-style">
                <OwlCarousel ref={this.sliderRef}
                             className="owl-theme"
                             items={1}
                             autoplay={false}
                             dots={true}
                             loop={true}
                             margin={30}
                             nav={false}
                             autoplayHoverPause={true}
                             autoFocus={true}
                             autoplaySpeed={2000}
                             animateIn="linear"
                             smartSpeed={300}
                             responsive={this.state.responsive}
                >
                    {learnResorces.map(learnResorce => {
                        return <div className="item">
                        <a href={`/learningresourcesdetail/${talkType}/${learnResorce.id}`} >
                            <div className="courses-slider new-heights" >
                                <img src={UrlResolver.generateMediaUrl(learnResorce.videobanner.url)}
                                     alt={learnResorce.videobanner.hash}/>
                                <div className="course-overlay">
                                    <div className="showAfterhover">
                                        <div className="coursevideo-back">
                                            <img src="../../../../images/Icons/coursevideo.png"/>
                                        </div>
                                        <div className="slider-desc">
                                            <h4>{learnResorce.name}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="course-cat-name">
                                    <p>{`${learnResorce.description.substring(0, 100)}...`}</p>
                                </div>
                            </div>
                            </a>
                        </div>
                    })}
                </OwlCarousel>
            </div>
        );
    }
}

export default LearningSlider;
