import React, {useState , useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../../components/Header/MainHeader';
import Footer from "../../../../components/Footer/Footer";
import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";
import DentalDetailItems from "./components/DentalDetailItems";
import ApiService from '../../../../../helpers/ApiService';




const TalkDentalDetail =(props)=> {

    const [foundationYears, setFoundationYears] = React.useState([]);
    
   let api = new ApiService();

    let parameter = useParams();

    
    useEffect(async () => {

        window.scrollTo(0, 0);

        let api = new ApiService();
       

        if(parameter.type == 'foundations'){

          api.getFoundationYears().then(_foundations => {
                
                 let __FOUND = _foundations.TalkDental.find((post, index) => {
                    if(post.id == parameter.id)
                        return true;
                });
                //console.log(__FOUND);
                setFoundationYears(__FOUND);
             }).catch((e) => {
         
             });
        } else if(parameter.type == 'students'){

            api.getAspireStudents().then(_foundations => {
                let __FOUND = _foundations.TalkDental.find((post, index) => {
                    if(post.id == parameter.id)
                        return true;
                });
                //console.log(__FOUND);
                setFoundationYears(__FOUND);
             }).catch((e) => {
         
             });
        } else {
            return <></>
        }

        
        }, [])
        
        if(!foundationYears) {
            return <></>
        }

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={foundationYears.title} /> 
            <DentalDetailItems TalkDental={foundationYears}/>
            <Footer/>

        </>
    );

}

export default TalkDentalDetail;