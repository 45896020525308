import React from 'react'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; 
import rehypeRaw from 'rehype-raw';
import UrlResolver from '../../../../../../helpers/UrlResolver';


const LearningDetailItems = (props) => {
    return (
        <>
            <div className="talk-detail-items">
                <div className="detail-section"
                     style={{backgroundImage: `url(${'.../../../../images/Icons/courses2.png'})`}}>
                    <div className="titlebox-meta">
                        <div className="container">
                            <div className="meta-detail">
                                <h2>{props.TalkDental.name}</h2>
                                {/* <span>{props.TalkDental.date}</span>  */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-descrption">
                <div className="container">
                <div className="slider-modal-video">
                    <iframe style={{}} src={`https://player.vimeo.com/video/${props.TalkDental.video}?autoplay=1&loop=1&autopause=0&autoplay=1`}
                        frameBorder="0"
                    />
                </div> 
                </div>
                    <div className="container">
                      <ReactMarkdown children= {props.TalkDental.description} rehypePlugins={[rehypeRaw]} ></ReactMarkdown> 
                    </div>

                </div>
            </div>
        </>
    );

}

export default LearningDetailItems;