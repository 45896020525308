import React, {useEffect} from 'react';
import BannerSection from "./components/BannerSection";
import CategoryList from "./components/CategoryList";
import VerticalTabs from "./components/VerticalTabs";
import OurTutorItems from "./components/OurTutorItems";
import OurStats from "./components/OurStats";
import TestimonialSlider from "./components/TestimonialSlider";
import MainHeader from "../../components/Header/MainHeader";
import Footer from "../../components/Footer/Footer";
import FullVideoSection from "./components/FullVideoSection";
import PopularCourseSection from "./components/PopularCourseSection";
import ReadyGoSection from "./components/ReadyGoSection";
import MobileTestimonial from './components/MobileTestimonial';
import ApiService from '../../../helpers/ApiService';


const Home =(props)=> {
    const [popularCourses,setPopularCourses] = React.useState([]);

    useEffect(()=>{
        let api = new ApiService();

        api.getCourses().then(_courses => {
            setPopularCourses(_courses);
          }).catch((e) => {

          });
      },[])

        return (
            <>
                {/* Header */}
                <MainHeader />

                {/* Body */}
                <BannerSection/>
                <CategoryList/>
                <FullVideoSection/>
                <VerticalTabs/>
                <OurTutorItems/>
                <PopularCourseSection courses={popularCourses}/>
                <ReadyGoSection/>
                
                {/* <OurStats/> */}
                <TestimonialSlider/>
                <MobileTestimonial/>

                {/* Footer */}
                <Footer/>
            </>
        );
}

export default Home;
