import React from 'react';
import { Link } from "react-router-dom";
import SimpleButton from '../Buttons/SimpleButton';

const QuizSubmitModal = (props) => {
    return (
        <>
            <div className="modal-head for-email-verify">
                <div onClick={props.close} className="close-icon">
                    <img src="../../../../images/Icons/close-icon.png" />
                </div>
            </div>
            <div className="email-verification">
                <img src="../../../../images/Icons/submit-icon.png" />
                <h4>Submit Quiz</h4>
                <span>Are you sure you want to submit this?<br />
                        Once you click on yes, you won’t be able to change your <br />
                        answers.
                </span>
                <div className="quiz-buttons">
                    <SimpleButton onClick={props.yes} name="Yes" />
                    <span onClick={props.no}>No</span>
                </div>
            </div>
        </>
    );
}

export default QuizSubmitModal;