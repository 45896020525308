import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Gdc5YearContentList = (props) => {

    return (
        <div className="annual-info">
            <div className="annual-box">
                <div className="annual-left">
                    <span>Name -</span>
                    <p>Raheel Malik</p>
                </div>
                <div className="annual-right">
                    <span>CPD is revelent to my field of prctice</span>
                    <img src="../../../../../images/Icons/check-click.svg" />
                </div>
            </div>
            <div className="annual-box">
                <div className="annual-left">
                    <span>GDC Number -</span>
                    <p>123456789</p>
                </div>
                <div className="annual-right">
                    <span>Information submitted is full and accurate</span>
                    <img src="../../../../../images/Icons/check-click.svg" />
                </div>
            </div>
            <div className="annual-box">
                <div className="annual-left">
                    <span>CPD Cycle Date -</span>
                    <p>17/08/2020</p>
                </div>
                <div className="annual-right">
                    <span>I understand the CPD requirements</span>
                    <img src="../../../../../images/Icons/check-click.svg" />
                </div>
            </div>
            <div className="annual-box">
                <div className="annual-left">
                    <span>CPD Total -</span>
                    <p>280 Hours</p>
                </div>
                <div className="annual-right">
                    <span>I have kept a record of my CPD and PDP</span>
                    <img src="../../../../../images/Icons/check-click.svg" />
                </div>
            </div>
        </div>
    );
}

export default Gdc5YearContentList;
