import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../components/Header/MainHeader';
import Footer from '../../../components/Footer/Footer';
import GdcAnnualBreadcrumbs from './components/GdcAnnualBreadcrumbs';
import GdcAnnualItems from './components/GdcAnnualItems';



const GdcAnnualStatement =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <GdcAnnualBreadcrumbs/>
            <GdcAnnualItems/>
            <Footer/>
        </>
    );

}

export default GdcAnnualStatement;