import React from 'react';
import {Form} from "react-bootstrap";

const InputWithLabel =(props)=> {

        return (
             <Form.Group controlId="formBasicEmail" className="label-input">
                <Form.Label>{props.label}</Form.Label>
                <Form.Control
                    type={props.type}
                    className={props.className}
                    placeholder={props.placeholder}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    id={props.id} 

                    /> 
                <span className="validation-error">{props.errors}</span>
            </Form.Group>
        );
}

export default InputWithLabel;