import React from 'react';
import {Link} from "react-router-dom";
import DefaultHeading from '../../../components/Heading/DefaultHeading';
import UrlResolver from "../../../../helpers/UrlResolver";

const CategoryChildList = (props) => {

    if(!props.subcats){
        return <></>
    } else {
        console.log(props.subcats);
    }
    return (
        <div className="cate-course-list">
            <div className="container">
                <DefaultHeading heading="Aspire Dental Subcatgories"/>
                <div className="course-list cat-course">
                   {props.subcats.child.map(catChild => {
                        return <div className="col-md-3">
                            <div className="courses-slider">
                                <Link to={`/coursecategory/${catChild.id}`}>
                                    <img src={UrlResolver.generateMediaUrl(catChild.thumbnail.url)}
                                         alt={catChild.thumbnail.hash}/>
                                    <div className="course-overlay">
                                        <div className="showAfterhover">
                                            <div className="slider-desc">
                                                <h4>{catChild.name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </div>
    );

}

export default CategoryChildList;