import React from 'react';

const DefaultHeading =(props)=> {

        return (
            <div className="defalut-heading">
                <h3>{props.heading}</h3>
                <span ></span>
            </div>
        );
}

export default DefaultHeading;