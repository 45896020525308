import React from 'react';
import DefaultHeading from "../../../../../components/Heading/DefaultHeading";
import EventsList from "./EventsList";





const EventsItems = (props) => {
    return (
        <>
            <div className="events-items">
                <div className="container">
                    <DefaultHeading heading={props.heading} />
                    <EventsList Items={props.EventsItem}/>
                </div>
            </div>
        </>
    );

}

export default EventsItems;