import React from 'react';
import SimpleInput from "../Input/SimpleInput";
import { Form} from "react-bootstrap";
import SelectInput from "../Input/SelectInput";
import SimpleButton from "../Buttons/SimpleButton";
import CheckboxInput from "../Input/CheckboxInput";

const RegisterModal = (props)=> {

        return (
            <div className="register-box">
                <div className="register-left" style={{backgroundImage: `url(${'.../../../../images/Icons/register-back.svg'})`}}>
                    <h3>Aspire Online</h3>
                    <span>Registration</span>
                </div>
                <div className="register-right">
                    <div className="signheading-box">
                        <div className="registration-arrow">
                            <img src="../../../../../images/Icons/left-arrow.svg"/>
                            <span>REGISTRATION</span>
                        </div>
                        <div className="alreadysign-logintext">
                            <span>If you already have an account?</span>
                            <p onClick={props.showNext}>Login</p>
                        </div>
                    </div>
                    <Form>
                        <div className="col-md-6">
                            <SimpleInput  type="text" placeholder="Full Name"/> 
                        </div>
                        <div className="col-md-6">
                            <SelectInput/>
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="date" placeholder="Date of birth"/> 
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="text" placeholder="GDC number"/> 
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="email" placeholder="Email address"/> 
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="text" placeholder="Practice name"/> 
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="text" placeholder="Phone number"/> 
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="text" placeholder="Practice address"/> 
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="text" placeholder="Home address"/> 
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-6 pl-0 mb-0">
                                <SimpleInput  type="text" placeholder="City"/> 
                            </div>
                            <div className="col-md-6 pr-0 mb-0">
                                <SimpleInput  type="text" placeholder="Post Code"/> 
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-6 pl-0 mb-0">
                                <SimpleInput  type="text" placeholder="City"/> 
                            </div>
                            <div className="col-md-6 pr-0 mb-0">
                                <SimpleInput  type="text" placeholder="Post Code"/> 
                            </div>
                        </div>
                        <div className="col-md-6">
                            <SimpleInput  type="password" placeholder="Enter your password"/> 
                        </div>
                        <div className="buttons-box ">
                            <div className="checkbox-col">
                                <CheckboxInput
                                    radiolabel="I agree to Terms & Conditions"
                                />
                                 <CheckboxInput
                                    radiolabel="Never miss our latest courses! Stay in the loop by subscribing!"
                                />
                            </div>
                            <div className="button-col">
                                <SimpleButton
                                    name="Register"
                                    onClick={props.checkemail}
                                />
                            </div>
                        </div>
                    </Form>
                    <div className="note-text">
                        <span>
                        Note: Your GDC number is presented on your CPD certificates. Please ensure that your GDC <br/>
                        number is correct because we cannot amend certificates.
                        </span>
                    </div>
                </div>
            </div>
        );
}

export default RegisterModal;