import React from 'react';
import {Link} from "react-router-dom";

const ForgotPasswordEmailVerifyModal = (props)=> {

        return (
            <>
                <div className="modal-head  for-email-verify">
                    <div onClick={props.close} className="close-icon">
                        <img src="../../../../images/Icons/close-icon.png"/>
                    </div>
                </div>
                <div className="email-verification">
                    <img src="../../../../images/Icons/forgotverify.svg"/>
                    <h4>Forgot Password</h4>
                    <span>Dear User, <br/> 
                            We’ve sent you an email<br/> 
                            with a link to change your account password. <br/>
                            If you do not see the email, <br/>
                            Please make sure you check your spam folder <br/>
                    </span>
                    {/* <span>To resend the email, click here  <Link onClick={props.changePassword}>Resend</Link></span> */}
                </div>
            </>
        );
}

export default ForgotPasswordEmailVerifyModal;