import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeading from '../../../../components/Heading/DefaultHeading';
import PdpBoxesList from './PdpBoxesList';



const PdpHubItems = (props) => {

    return (
        <div className="foudation-years">
            <div className="container">
                <DefaultHeading heading="PDP Hub" />
                <PdpBoxesList/>
            </div>
        </div>
    );
}

export default PdpHubItems;
