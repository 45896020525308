import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ReflectionForm = (props) => {

    return (
        <div className="reflection-info-new">
            <div className="reflection-left-form">
                <div className="reflection-area">
                    <label>How does this course relate to my field of practice?</label>
                    <textarea placeholder="Write here.........................." />
                </div>
            </div>
            <div className="reflection-right-form">
                <div className="reflection-area">
                    <label>What benefit will this have to my work?</label>
                    <textarea placeholder="Write here.........................." />
                </div>
            </div>
            <div className="reflection-bottom-form">
                <div className="reflection-area">
                    <label>What benefit will this have to my work?</label>
                    <textarea placeholder="Write here.........................." />
                </div>
            </div>
        </div>
    );
}

export default ReflectionForm;
