import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CategoryLeftSection from './CategoryLeftSection';
import CategoryRightSection from './CategoryRightSection';

const CategoryItems = (props) => {
        
    return (
        <div className="course-categories">
            <div className="container">
                <div className="category-Sections">
                    <CategoryLeftSection Cats={props.cat} />
                    <CategoryRightSection Video={props.cat.videoLink}/>
                </div>
            </div>
        </div>
    );
}

export default CategoryItems;
