import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../components/Header/MainHeader';
import Footer from '../../../components/Footer/Footer';
import Gdc5YearBreadcrumbs from './components/Gdc5YearBreadcrumbs';
import Gdc5YearItems from './components/Gdc5YearItems';



const Gdc5Year =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <Gdc5YearBreadcrumbs/>
            <Gdc5YearItems/>
            <Footer/>
        </>
    );

}

export default Gdc5Year;