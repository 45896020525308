import React, { useState }from 'react';
import SimpleInput from "../Input/SimpleInput";
import {Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiService from '../../../helpers/ApiService';
import SimpleButton from '../Buttons/SimpleButton';

const HelpModal = (props)=> {

        return (
            <div className="help-modal">
                <div className="d-flex align-items-center justify-content-between help-modal-header">
                    <div className="pay-card">
                        <span>Help Popup</span>
                    </div>
                    <div className="cross-photo">
                        <img src="../../../../images/Icons/closs-icon.svg"/>
                    </div>
                </div>               
                <div className="help-modal-descrption">
                    <p>Lorem Ipsum is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the industry's </p>
                    <p>Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's </p>
                    <p className="">Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's </p>
                    <div className="help-link-action">
                        <span>Click on the link for more information</span>
                        <Link>Click Here</Link>
                    </div>
                    <div className="help-modal-button">
                        <SimpleButton onClick={props.onClick}  name="Okay" />
                    </div>
                </div>
            </div>
        );
}

export default HelpModal;