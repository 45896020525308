import React, { useState } from 'react';
import SimpleButton from '../../../components/Buttons/SimpleButton';
import { Form } from "react-bootstrap";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { connect } from "react-redux";
import ApiService from '../../../../helpers/ApiService';


const FeedBackItems = (props) => {

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); 

    let api =  new ApiService();

   const handleChange = (event) => {
        let i = { ...input };
        i[event.target.name] = event.target.value;
        setInput(i);
        console.log(i);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);
            console.log(input);

            var data = {
                feedbacks: input,
                course: props.courses.id,
              }

            api.setfeedback(data)
            .then(response => {
   
               console.log(response);
   
               setLoading(false);
   
                window.location.href = "/quizcomplete/"+props.courses.id+"/"+props.result;
   
              }).catch(errors => {
                 setErrors({error: errors.message[0].messages[0].message});
              }).finally(()=>{
                 setLoading(false);
             });
            //setquizModal(true);
           
        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;
        props.Feedbacks.map((quiz, index)=>{
            console.log(input[`answer${index+1}`])
           if(input[`answer${index+1}`] === undefined){
               isValid = false;
               errors['msg'] = "Please answer all questions";
           }
        });

        setErrors(errors);
        return isValid;
    }

    return (
        <div className="quiz-question-section">
            <div className="container">
            <Form onSubmit={handleSubmit}>
                <div className="feedback-questions">
                    <div className="defalut-heading">
                        <h3 className="text-center">Feedback</h3>
                        <span ></span>
                    </div>
                    <p>{props.courses.Name}</p>
                    {props.Feedbacks.map((ques , index) => {
            return <div className="feedback-box">
                <div className="feedback-question-text">
                    <span>Q.{index + 1}</span>
                    <p>{ques.Question}</p>
                </div>
                <div className="feedback-question-options">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label={`answer${index + 1}`} name={`answer${index + 1}`}  value={input[`answer${index + 1}`]} onChange={handleChange}>
                            <FormControlLabel value="yes" control={<Radio checked={input[`answer${index + 1}`] == 'yes'}/>} label="Yes" />
                            <FormControlLabel value="no" control={<Radio checked={input[`answer${index + 1}`] == 'no'} />} label="No" />
                         </RadioGroup>
                    </FormControl>
                    </div>
            </div>
        })}
                    
                </div>
                <div className="feedback-submit">
                    <div className="form-group">
                        <label>Please provide us with further feedback to help us improve our services</label>
                        <textarea
                            placeholder="Enter your feedback here..."
                            id="exampleFormControlTextarea1"
                            rows="5"
                            onChange={handleChange}
                            name="msg"
                        />
                    </div>
                    <span className="validation-error">{errors.msg}</span>

                        <SimpleButton
                                name="Submit"
                                onClick={handleSubmit}
                                loading={loading}
                            />
                </div>
                </Form>
            </div>
        </div>
    );

}

function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(FeedBackItems);
