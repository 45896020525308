import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import PreviousLearningBreadcrumbs from './components/PreviousLearningBreadcrumbs';
import PreviousLearningItems from './components/PreviousLearningItems';



const PreviousLearningCycle =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <PreviousLearningBreadcrumbs/>
            <PreviousLearningItems/>
            <Footer/>
        </>
    );

}

export default PreviousLearningCycle;