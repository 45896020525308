import React from 'react';
import {Form} from "react-bootstrap";

const SimpleInput =(props)=> {

        return (
            <Form.Group controlId="formBasicEmail" className="simple-input">
                <input 
                    className={props.className}
                    type={props.type}
                    placeholder={props.placeholder}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange} 
                    id={props.id}
                    disabled={props.disabled}
                />
                <span className="validation-error">{props.errors}</span>
            </Form.Group>
        )
}

export default SimpleInput;