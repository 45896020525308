import { Link } from 'react-router-dom';
import React, { useEffect ,useState } from 'react';




const ArchivedItems = (props) => {

    const d = new Date();

    let startdate = d.toISOString().slice(0, 10);

    let userscheduleCalls = [];
    if(props.scheduleCalls){
         
        let objects = props.scheduleCalls;

        //console.log(objects);

        if(objects.length === 0){
            userscheduleCalls = [];
        } else {
            userscheduleCalls = objects;

            //console.log(userpatients);
        }
      }

      useEffect(()=>{

        window.scrollTo(0, 0);
        },[])

    return (
        <div className="foudation-years" >
            <div className="container">
              <div className='upcoming-calls-head'>
                    <span>Archived Calls</span>
                </div>  
                <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Mentor</th>
                        <th>Patients</th>
                        <th>Date & Time</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {userscheduleCalls.map(userscheduleCall => {
                    if(userscheduleCall.status === 'Archived' || userscheduleCall.callDate < startdate){
                   return <tr>
                        <td>{userscheduleCall.mentor.fullName}</td>
                        <td className="text-left  pl-4" >
                            <div className='patient-detail'>
                                <span>{userscheduleCall.patient.patientName}</span>
                            </div>
                        </td>
                        <td>{userscheduleCall.callDate}, {userscheduleCall.callSlot}</td>
                        <td>Archived</td>
                    </tr>
                    }
                })}
                   
                </tbody>
            </table>

        </div>
            </div>
            
        </div>
    );
    
}

export default ArchivedItems;
