import React, { useEffect } from 'react';
import ApiService from '../../../helpers/ApiService';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import QuizBreadcrumbs from './component/QuizBredcrumbs';
import {Link ,useParams} from "react-router-dom";

import { connect } from "react-redux";

const QuizFailed = (props) => {

    const [coures,setCourses] = React.useState(null);

    const parameter = useParams();

    useEffect(async ()=>{

        let api = new ApiService();

        api.getCourse(parameter.id).then(_cou => {
            setCourses(_cou);
         }).catch((e) => {
     
         });

    },[])

   
    let resultFinal = 0;
    if(props.user){
        
       console.log(parameter.type);
        let objects = props.user.user_quiz;


        let result = objects.filter(obj => {
            return obj.id == parameter.type
          })

        resultFinal = result[0].percentage;

    } 

    if(!coures) {
        return <></>
    } 

    return (
        <>
            <MainHeader />
            <QuizBreadcrumbs courses={coures}/>
            <div className="completed-box">
                <div className="complete-circle-red">
                    {resultFinal}%
                </div>
                <div className="congras-red">Better Luck Next Time!</div>
                <div>
                    <p>You have failed in the course ‘{coures.Name}’</p>
                </div>
                <div className="goto-my">
                    <p>You have been awarded a certification which you can find under  </p> 
                    <Link className="retake-red" to={`/quiz/${coures.id}`}>Retake Test!</Link>
                </div>

            </div>
            <Footer />
        </>
    );

}

function mapStateToProps(state) {
    return {user: state.user}
}



export default connect(mapStateToProps)(QuizFailed);