import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const ChoosePdpTable = (props) => {

    const [showdateDrop, setShowdateDrop] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [showdatepicker, setShowdatepicker] = useState(false);

    return (
        <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Courses</th>
                        <th>Duration</th>
                        <th>When will I complete <br /> this course</th>
                        <th>GDC development <br /> outcomes</th>
                        <th>
                            <div className="reflection-heading">
                                <span>Complete <br /> reflections now</span>
                                <p>(Can also be completed after the <br /> course)</p>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-left  pl-4" >Disinfection and Decontamination</td>
                        <td>2:00 Hours</td>
                        <td>
                            {!showdatepicker &&
                                <div onClick={() => setShowdateDrop(!showdateDrop)} className="reflec-body">
                                    <p>Select start date</p>
                                    {showdateDrop
                                        ?
                                        <img src='../../../../images/Icons/red-pdp.svg' />
                                        :
                                        <img src='../../../../images/Icons/pdp-icon.svg' />
                                    }
                                </div>
                            }
                            {showdateDrop &&
                                <div>
                                    {!showdatepicker &&
                                        <div className="startdate-drop">
                                            <ul>
                                                <li>Annually</li>
                                                <li>Throughout Cycle</li>
                                                <li>Quarterly</li>
                                                <li onClick={() => setShowdatepicker(!showdatepicker)}>Specific Date</li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            }
                            {showdatepicker &&
                                <div className="pdptable-datepicker">
                                    <div>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            placeholderText="Date of birth"
                                            className="form-control"
                                            name="dob"
                                        />
                                    </div>
                                    <img src="../../../../images/Icons/pdp-edit.svg" />
                                </div>
                            }
                        </td>
                        <td>A</td>
                        <td><Link to="/coursereflection">Reflection</Link></td>
                    </tr>
                    <tr>
                        <td className="text-left  pl-4">Radiation Protection</td>
                        <td>2:00 Hours</td>
                        <td>
                            <div className="reflec-body">
                                <p>Select start date</p>
                                <img src='../../../../images/Icons/pdp-icon.svg' />
                            </div>
                        </td>
                        <td>B</td>
                        <td><Link to="/coursereflection">Reflection</Link></td>
                    </tr>
                    <tr>
                        <td className="text-left pl-4">Medical Emergency</td>
                        <td>2:00 Hours</td>
                        <td>
                            <div className="reflec-body">
                                <p>Select start date</p>
                                <img src='../../../../images/Icons/pdp-icon.svg' />
                            </div>
                        </td>
                        <td>C</td>
                        <td><Link to="/coursereflection">Reflection</Link></td>
                    </tr>
                </tbody>
            </table>

        </div>
    );
}

export default ChoosePdpTable;
