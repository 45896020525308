import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import DefaultHeading from '../../../../components/Heading/DefaultHeading';
import { Modal } from "react-bootstrap";
import AddPatientModal from '../../../../components/Modals/AddPatientModal';
import MentorTable from './SubComponents/MentorTable';
import UpcomingCallsTable from './SubComponents/UpcomingCallsTable';
import ApiService from '../../../../../helpers/ApiService';


const MentorDashboardItems = (props) => {
    let api = new ApiService();
    
    // Add patient Modal
    const [addPatientModal, setAddPatientModal] = useState(false);
    const addPatientModalClose = () => setAddPatientModal(false);
    const addPatientModalShow = () => setAddPatientModal(true);

    let userPatients = 0;
    let userScheduleCalls = 0;
    
    if(!props.user){
        return <></>  
      } else {
        userScheduleCalls = props.user.scheduleCalls;
        userPatients = props.user.patients;
        //console.log(props.user.mentor);
      }


    return (
        <div className="foudation-years">
            <div className="container">
                <DefaultHeading heading="Mentorship Dashboard " />
                <div className='mentordash-buttons'>
                    {props.user.mentor === 'yes' &&
                        <Link to='/mentordashboard' className='mentorButton'>Mentor Dashboard</Link>
                    }
                <button onClick={addPatientModalShow}>Add New Patient</button>
                    <Link to='/archivedpatients'>View Archived Patient</Link>
                </div>
                <MentorTable  patients={userPatients} />

                {/* Upcoming Calls */}
                <div className='upcoming-calls-head'>
                    <span>Upcoming Calls</span>
                    <Link to='/archivedcalls'>View Call Archived</Link>
                </div>

                {/* Upcoming Table */}
                <UpcomingCallsTable scheduleCalls={userScheduleCalls}/>
            </div>



            {/*  Add patient Modal */}
            <Modal show={addPatientModal} onHide={addPatientModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals add-patient"
            >
                <AddPatientModal 
                    close={addPatientModalClose}
                />
            </Modal>

        </div>
    );
}

export default MentorDashboardItems;
