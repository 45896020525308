import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import ApiService from '../../../helpers/ApiService';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import CategoryBreadcrumbs from '../CourseCategories/components/CategoryBreadcrumbs';
import CategoryCourseList from '../CourseCategories/components/CategoryCourseList';


const TutorDetail =(props)=> {
    const [tutors, setTutors] = React.useState([]);

    let parameter = useParams();

    useEffect(async () => {

        window.scrollTo(0, 0);
       
        let api = new ApiService();

        api.getTutors().then(_tuts => {
            let __FOUND = _tuts.find((post, index) => {
                if(post.id == parameter.id)
                    return true;
            });
         
            console.log(__FOUND);
            setTutors(__FOUND);
         }).catch((e) => {
     
         });
        }, [])

        if(!tutors) {
            return <></>
        } 

    return (
        <>
           <MainHeader/>
             <CategoryBreadcrumbs catename={tutors.Name} />
             <CategoryCourseList courses={tutors.courses} />
           <Footer/>
        </>
    );

}

export default TutorDetail;