import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from "../../../../components/Header/MainHeader";
import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";
import Footer from "../../../../components/Footer/Footer";
import CompetitionsItems from "./components/CompetitionsItem";
import ApiService from '../../../../../helpers/ApiService';




const Competitions =(props)=> {
    const [foundationYears, setFoundationYears] = React.useState([]);
    const [pageTitle, setPageTitle] = React.useState('');
    let { id } = useParams();

    useEffect(async () => {
       
        let api = new ApiService();
       

        if(id == 'foundations'){

            setPageTitle('Foundations Competitions');

            api.getFoundationYears().then(_foundations => {
                setFoundationYears(_foundations.Competitions);
             }).catch((e) => {
         
             });
        } else if(id == 'students'){
            setPageTitle('Students Competitions');

            api.getAspireStudents().then(_foundations => {
                setFoundationYears(_foundations.Competitions);
             }).catch((e) => {
         
             });
        } else {
            return <></>
        }

        
        }, [])
        
        if(!foundationYears) {
            return <></>
        }

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={pageTitle} /> 
            <CompetitionsItems CompetitionsItem={foundationYears}  heading={pageTitle}/>
            <Footer/>

        </>
    );

}

export default Competitions;