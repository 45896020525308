import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LearningCycle from './LearningCycle';
import PersonalInformation from './PersonalInformation';



const PersonalPlaningItems = (props) => {

    return (
        <div className="foudation-years">
            <div className="container">
                <div className="personal-development-planing">
                    <PersonalInformation/>
                    <LearningCycle/>
                </div>
                <div className="personal-button-group">
                    <Link className="skip">Skip </Link>
                    <Link className="next-plan" to="/fieldpractice">Next</Link>
                </div>
            </div>
        </div>
    );
}

export default PersonalPlaningItems;
