import * as React from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import Select from 'react-select'

// COMPONENTS
const DropdownContainer = styled.div`
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`
const StyledLabel = styled.label`
  font-weight: 500;
  margin: 4px;
`
// custom select styles
const customStyles = {
  control: (styles, props) => {
    return {
      ...styles,
      padding: '0 .75rem',
      borderRadius: '6px',
      height: '50px',
      backgroundColor: 'transparent',
      border: props.isFocused ? '1px solid rgba(255, 255, 255, 0.5)' : '1px solid rgba(255, 255, 255, 0.5)',
      boxShadow: 'none',
      cursor: 'pointer',
      ':hover': {
        border: props.isFocused ? '1px solid rgba(255, 255, 255, 0.5)' : '1px solid rgba(255, 255, 255, 0.5)',
      },
    }
  },
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,

      transition: 'all 0.2s',
      backgroundColor: isSelected ? 'transparent' : 'inherit',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      color: '#ffffff',
      padding: '13px 15px',
      borderTop: '0.5px solid rgba(255, 255, 255, 0.21)',
      ':hover': {
        backgroundColor: '#292929',
        color: '#ffffff'
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'transparent' : 'transparent'),
      },
      borderRadius: '0px',
    }
  },
  menu: styles => {
    return {
      ...styles,
      borderRadius: '0px',
      backgroundColor: '#181818',
      margin: 0,
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#9D9D9D',
        outline: '1px solid #9D9D9D',
        borderRadius: '70px',
      },
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#ffffff',
  }),
}

const SelectInput = ({
  placeholder,
  onSelect,
  options,
  keepValue,
  label,
  onChange,
  id,
  name,
  error,
  containerCss, value,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('')
  const staticValue = keepValue ? {} : { value: [] }

  let v = {};
  //console.log(value)
  options.map(o=>{
    if(o.value === value){
      v = o;
    }
  });
  //console.log({v})
  return (
    <>
      <DropdownContainer css={containerCss}>
        {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
        <Select
          name={name}
          options={options}
          value={v}
          isSearchable={false}
          styles={customStyles}
          placeholder={placeholder}
          inputValue={inputValue}
          onInputChange={inputValue => setInputValue(inputValue)}
          onSelectResetsInput={!keepValue}
          onChange={value => {
            onChange({target:{name:name,value:value.value}})
          }}
          {...{ rest, ...staticValue }}
        />
      </DropdownContainer>
      <span className="validation-error">{error}</span> 
    </>
  )
}

export default SelectInput
