import React, { useState } from 'react';
import { Form } from "react-bootstrap";

const InputWithoutLabel = (props) => {

    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <>
            <Form.Group controlId="formBasicEmail" className="label-input">
                <div className={props.errors ? "inputwith-icon error" : "inputwith-icon"}>
                    <input className={props.className} onChange={props.onChange} type={props.type} name={props.name} value={props.value} placeholder={props.placeholder} />
                    <img className="cursr" onClick={() => setShowTooltip(!showTooltip)} src={props.icon} />
                   
                    {/* Show Tooltip */}
                    {showTooltip &&
                        <div className='mentor-tool-tip'>
                            <span>{props.tooltiptext}</span>
                        </div>
                    }
                </div>
                <span className="validation-error">{props.errors}</span>
            </Form.Group>
        </>
    );
}

export default InputWithoutLabel;