import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MentorsTable = (props) => {

    let userpatients = [];
    if(props.patients){
         
        let objects = props.patients;

        //console.log(objects);

        if(objects.length === 0){
            userpatients = [];
        } else {
            userpatients = objects;

            //console.log(userpatients);
        }
      }

    return (
        <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Patients</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Actions</th> 
                    </tr>
                </thead>
                <tbody>
                {userpatients.map(userpatient => {
                    if(userpatient.paymentStatus === 'Done' && userpatient.status !== 'Archived'){
                        let d = new Date(userpatient.created_at);
                    let sdated = d.toISOString().slice(0, 10); 
                    return <tr>
                        <td className="text-left  pl-4" >
                            <div className='patient-detail'>
                                <img src='../../../../../../images/Icons/mentor-pic.svg' alt='ProfilePic' />
                                <span>{userpatient.patientName}</span>
                            </div>
                        </td>
                        <td>{sdated}</td>
                        <td>
                            In Progress
                        </td>
                        <td>Case Discussion</td>
                        <td>
                            <div className='mentor-actions'>
                                <div className='view-conversation' >
                                <Link to={`/viewconversation/${userpatient.id}`}>View Conversation</Link>
                                </div>
                            </div>
                        </td>
                        {/* <td>
                            <div className='mentor-actions'>
                                <div class="mentor-edit">
                                    <span>View</span>
                                </div>
                                <div class="mentor-edit">
                                    <span>Accept</span>
                                </div>
                                <div class="mentor-edit">
                                    <span>Reject</span>
                                </div>
                            </div>

                        </td> */}
                    </tr>
                    }
                })}
                 </tbody>
            </table>
        </div>
    );
}

export default MentorsTable;
