import React, {useState, useEffect} from 'react';
import ApiService from '../../../../helpers/ApiService';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import CategoryBreadcrumbs from '../components/CategoryBreadcrumbs';
import AspireNewItems from "./components/AspireNewItems";

const AspireNews =(props)=> {
     
    const [news, setNews] = React.useState([]);

    useEffect(async () => {

        window.scrollTo(0, 0);

        let api = new ApiService();

        api.getAspireNews().then(_news => {
            //console.log(_news.aspireNews);
            setNews(_news.aspireNews);
        }).catch((e) => {
     
         });
        }, [])
        
        if(!news) {
            return <></>
        }

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename="Aspire News" />
            <AspireNewItems items={news}  />
            <Footer/>
        </>
    );
}

export default AspireNews;