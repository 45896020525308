import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from '../../../components/Header/MainHeader';
import Footer from '../../../components/Footer/Footer';
import ReflectionBreadcrumbs from './components/ReflectionBreadcrumbs';
import ReflectionItems from './components/ReflectionItems';



const Reflection =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <ReflectionBreadcrumbs/>
            <ReflectionItems/>
            <Footer/>
        </>
    );

}

export default Reflection;