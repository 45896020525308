import React, {useEffect ,useState} from 'react';
import BannerVideo from "./BannerVideo";
import ButtonWithIcon from "../../../components/Buttons/ButtonWithIcon";


const FullVideoSection =(props)=> {

    var VIDEOS = '../../../../images/video/homenewvideo.mp4';
     
    const [homeVideoLink, setHomeVideoLink] = useState('');

    useEffect(()=>{

        setHomeVideoLink(VIDEOS);

    },[])

    return (
                <div className="full-video-section">
                    <div className="video"
                        style={{
                            overflow: "hidden",
                            height:"670px",
                            width:'100%',
                        }}
                        >
                            <video height="auto" width="100%" controls autoPlay muted loop>
                               <source src={homeVideoLink} type="video/mp4"/>
                            </video>
                        </div>
                </div>
        );
}

export default FullVideoSection;