import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReflectionForm from './ReflectionForm';
import ReflectionInfo from './ReflectionInfo';

const ReflectionItems = (props) => {

    return (
        <div className="foudation-years">
            <div className="container">
                <div className="reflection-heading">
                    <h3>Course Reflection</h3>
                </div>
                <div className="reflection-content-box">
                    <ReflectionInfo/>
                    <ReflectionForm/>
                </div>
                <div className="personal-button-group">
                    <Link className="next-plan" to="/choosepdp">Done</Link>
                </div>
            </div>
        </div>
    );
}

export default ReflectionItems;
