import React from 'react';
import ButtonWithIcon from "../../../components/Buttons/ButtonWithIcon";

const ReadyGoSection =(props)=> {
        return (
            <>
                <div className="readytogo-section">
                    <div className="container">
                        <div className="readygo-box">
                            <div className="go-col1">
                                <h3>Ready to go?</h3>
                                <span>Sign up today and gain access to the best online dental courses brought to you by some of the world’s leading dental experts. From just £25 a year, start your journey today into a bettering yourself as a dentist
                                </span>
                                <ButtonWithIcon
                                    name="Get Started"
                                    icon="../../../images/Icons/button-icon.svg"
                                    link="/subscription"
                                    onClick=""
                                />
                            </div>
                            <div className="go-col2">
                            </div>
                            <div className="go-col3">
                                <img src="../../../images/Icons/ready-togo.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
}

export default ReadyGoSection;