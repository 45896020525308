import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import DefaultHeading from '../../../../components/Heading/DefaultHeading';
import MentorsTable from './Subcomponents/MentorsTable';
import MentorUpcomingCalls from './Subcomponents/MentorUpcomingCalls';
import TimeSlotsTable from './Subcomponents/TimeSlotsTable';
import { Modal } from "react-bootstrap";
import AddSlotsModal from '../../../../components/Modals/AddSlotsModal';
import { Tabs, Tab } from 'react-bootstrap-tabs';


const MentorItems = (props) => {

    // Add patient Modal
    const [addSlot, setAddSlotModal] = useState(false);
    const addSlotClose = () => setAddSlotModal(false);
    const addSlotShow = () => setAddSlotModal(true);

    const [selectedMentor, setSelectedMentor] = React.useState([]);

    const editDactorModalShow = (mentor) => {

        setAddSlotModal(true);
        //console.log(mentor);
        setSelectedMentor(mentor);

    };

    let mentorPatients = 0;
    let mentorScheduleCalls = 0;
    let dBooking = 0;
    let mday = [];
    let tday = [];
    let wday = [];
    let thday = [];
    let fday = [];
    let saday = [];
    let suday = [];
    if (!props.user) {
        return <></>
    } else {
        console.log(props.user);
        

        if(props.user.data.dactorslots[0] && props.user.data.dactorslots[0].bookslots !== 0){
            mday = props.user.data.dactorslots[0].bookslots;
        }

        if(props.user.data.dactorslots[1] && props.user.data.dactorslots[1].bookslots !== 0){
            tday = props.user.data.dactorslots[1].bookslots;
        }

        if(props.user.data.dactorslots[2] && props.user.data.dactorslots[2].bookslots !== 0){
            wday = props.user.data.dactorslots[2].bookslots;
            //console.log(wday);
        }

        if(props.user.data.dactorslots[3] && props.user.data.dactorslots[3].bookslots !== 0){
            thday = props.user.data.dactorslots[3].bookslots;
        }

        if(props.user.data.dactorslots[4] && props.user.data.dactorslots[4].bookslots !== 0){
            fday = props.user.data.dactorslots[4].bookslots;
        }

        if(props.user.data.dactorslots[5] && props.user.data.dactorslots[5].bookslots !== 0){
            saday = props.user.data.dactorslots[5].bookslots;
        }

        if(props.user.data.dactorslots[6] && props.user.data.dactorslots[6].bookslots !== 0){
            suday = props.user.data.dactorslots[6].bookslots;
        }
        
       console.log(suday);
       
        let dactorslots = props.user.data.dactorslots;

        //console.log(props.user.data.dactorslots);

        if (dactorslots.length === 0) {
            dBooking = 0;
        } else {
            dBooking = dactorslots;
        }
        mentorPatients = props.user.data.mentorpatients;
        mentorScheduleCalls = props.user.data.schedulecalls;

        //console.log(dBooking);
    }

    return (
        <div className="foudation-years">
            <div className="container">
                <DefaultHeading heading="Mentor Dashboard " />
                <div className='mentordash-buttons'>
                    <Link to='/mentorshipdashboard' className='mentorButton'>Mentorship Dashboard</Link>
                    <Link to='/archivedpatients'>View Archived Patient</Link>
                </div>
                <MentorsTable patients={mentorPatients} />

                {/* Upcoming Calls */}
                <div className='upcoming-calls-head'>
                    <span>Upcoming Calls</span>
                    <Link to='/archivedcalls'>View Call Archived</Link>
                </div>
                <MentorUpcomingCalls scheduleCalls={mentorScheduleCalls} />

                {/* Time Slots */}
                <div className='upcoming-calls-head'>
                    <span>Time Slots</span>
                    <Link onClick={addSlotShow}>Edit/Add</Link>

                </div>

                <div className='mentor-tabs-for-tables'>
                    <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                        <Tab label="Monday">
                            <TimeSlotsTable  slotsTime={mday} tday={'1'}/>
                        </Tab>
                        <Tab label="Tuesday">
                            <TimeSlotsTable slotsTime={tday} tday={'2'}/>
                        </Tab>
                        <Tab label="Wednesday">
                            <TimeSlotsTable slotsTime={wday} wday={'3'} />
                        </Tab>
                        <Tab label="Thursday">
                            <TimeSlotsTable slotsTime={thday} thday={'4'} />
                        </Tab>
                        <Tab label="Friday">
                            <TimeSlotsTable slotsTime={fday} fday={'5'} />
                        </Tab>
                        <Tab label="Saturday">
                            <TimeSlotsTable slotsTime={saday} saday={'6'}/>
                        </Tab>
                        <Tab label="Sunday" >
                            <TimeSlotsTable slotsTime={suday} suday={'7'}/>
                        </Tab>
                    </Tabs>
                </div>
            </div>

            {/*  Add/Edit Slots Modal */}
            <Modal show={addSlot} onHide={addSlotClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals add-patient slots"
            >
                <AddSlotsModal close={addSlotClose} mentorId={dBooking} />

            </Modal>
        </div>
    );
}

export default MentorItems;
