import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import { Modal } from "react-bootstrap";
import CardModal from '../../../components/Modals/CardModal';
import SimpleButton from "../../../components/Buttons/SimpleButton";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_test_nCcWEWsgJJTgFQ0DnB6h3PKj');


const CourseDetailLeftBox = (props) => {

    const [selectedPlan, setSelectedPlan] = React.useState({ price: "", plan: "" , course: ""});
    
    // Card Modal
    const [cardModal, setcardModal] = useState(false);
    const [cardType, setcardType] = useState();
    const cardModalClose = () => setcardModal(false);

    const cardModalShow = (price, plan, course) => {

       const dataPlan = {
            price,
            plan,
            course,
       }

        setcardModal(true);

        setcardType('CourseDeatils');

        setSelectedPlan(dataPlan);
    };

    return (
        <>
        <div className="left-section">
            <div className="title-info-desktop">
                <h3>{props.courses.Name}</h3>
                <p>{props.courses.Description}</p> 
            </div>
            <div className="video-box">
                <div className="embed-responsive embed-responsive-16by9">  
                    <iframe className="embed-responsive-item"
                            src={`https://player.vimeo.com/video/${props.video}`} frameborder="0"
                            allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
                {/* <img src='../../../../images/Icons/video-detail.png' alt=''/>
                <div className="video-overlay-back">
                    <img src='../../../../images/Icons/coursevideo.png' alt=''/>
                </div> */}
            </div>
            <div className="title-info-mobile">
                <h3>{props.courses.Name}</h3>
                <p>{props.courses.Description}</p>
            </div>

            {/*For Mobile View */}
            <div className="buy-buttons-mobile">
            {props.courses.type == '1' &&
                <div className="buy-buttons ">
                    <div className="buttn-box">
                        <span>$ 500 per year</span>
                        <Link to='/subscription' className="defalut-button">Subscribe Now</Link> 
                    </div>
                    <div className="buttn-box disabled">
                        <span>${props.courses.coursefee} for this course</span>
                        {!props.user ? <Link to='/register' >Buy This Course</Link> : <Link onClick={() => cardModalShow(`${props.courses.coursefee}`,'null', `${props.courses.id}`)}>Buy This Course</Link> } 
                    </div>
                </div>
            }

                {/* display button when purchase the course */}
                {props.courses.type != '1' &&
                <div className="watch-course-button" style={{display : 'inline-block'}}>
                    <SimpleButton
                        name="Watch this Course Now"
                    />
                </div>
                }
            </div>
            <div className="detail-actions">
                <Link to="/">
                    <span>Add to PDP</span>
                    <img src="../../../../images/Icons/pdp.png"/>
                </Link>
                <Link to="/">
                    <span>Bookmark</span>
                    <img src="../../../../images/Icons/bookmark.png"/>
                </Link>
                {props.courses.type == '1' &&
                <Link to='#' className="take-quiz-disable">
                    <span>Take quiz</span>
                    <img src="../../../../images/Icons/quiz.png"/>
                </Link>
                }
                {props.courses.type != '1' &&
                <Link to={`/quiz/${props.courses.id}`} className="take-quiz-enable" style={{display : 'flex'}}>
                    <span>Take quiz</span>
                    <img src="../../../../images/Icons/quiz-enable.png"/>
                </Link>
                }
            </div>
        </div>
        
        
        <Modal show={cardModal} onHide={cardModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="authModal cardModal"
                size="md"
                className="authModal cardModal pay-modals"
            ><Elements stripe={stripePromise}>
                <CardModal planDone={selectedPlan} nextUrl={cardType}/>
            </Elements>
            </Modal>
            </>
    );

}

export default CourseDetailLeftBox;