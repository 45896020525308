import React, {Component} from 'react';
import {Col, Container, Row,} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const FooterAccordion = (props) => {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Explore Categories</Typography>
                    <img src='../../../../../images/Icons/white-arrow.png'/>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="mobile-footer-list">
                        <ul>
                            <li><Link to="/">Aspire eCPD</Link></li>
                            <li><Link to="/">Aspire Think</Link></li>
                            <li><Link to="/mentorship">Aspire Mentorship</Link></li>
                            <li><Link to="/">Aspire Live</Link></li>
                            <li><Link to="/">Aspire Shield</Link></li>
                            <li><Link to="/">Aspire Journal Club</Link></li>
                            <li><Link to="/">Aspire Finance</Link></li>
                            <li><Link to="/">Aspire Students/Foundation</Link></li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>About</Typography>
                    <img src='../../../../../images/Icons/white-arrow.png'/>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="mobile-footer-list"> 
                        <ul>
                        {!props.user && 
                                <li><Link to="/becomementor">Become a Mentor</Link></li> 
                            }
                            {props.user && props.user.data.mentor !== 'yes' &&
                               <li><Link to="/becomementor">Become a Mentor</Link></li> 
                            }
                                <li><Link to="/pages/1">Privacy Policy</Link></li>
                                <li><Link to="/pages/2">Terms & Conditions</Link></li>
                                <li><Link to="/pages/3">Equality & Diversity Policy</Link></li>
                                <li><Link to="/">Contact Us</Link></li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Contact Details</Typography>
                    <img src='../../../../../images/Icons/white-arrow.png'/>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="mobile-footer-list">
                        <ul className="phone-mt-3">
                            <li><Link to="/"><img src="../../../../images/Icons/email.svg"/> example12@gmail.com</Link>
                            </li>
                            <li><Link to="/"><img src="../../../../images/Icons/email.svg"/> example123@gmail.com</Link>
                            </li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default FooterAccordion;