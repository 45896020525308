import React, { useState } from 'react';
import SimpleInput from "../Input/SimpleInput";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleButton from "../Buttons/SimpleButton";
import ApiService from '../../../helpers/ApiService';


const LoginModal = (props) => {

  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    let i = { ...input };
    i[event.target.name] = event.target.value;
    setInput(i);
  }

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    if (validate()) {
      setLoading(true);

      let api = new ApiService();
      api.login({
        identifier: input.email,
        password: input.password,
      }).then(response => {
        localStorage.setItem('jwt', response.jwt);
        setLoading(false);
        window.location.reload();
      }).catch(errors => {
           setErrors({error: errors.message[0].messages[0].message});
        }).finally(()=>{
          setLoading(false);
        });

    }
  }


  const validate = () => {
    let errors = {};
    let isValid = true;
    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your username.";
    }

    if (typeof input["email"] !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    setErrors(errors);
    return isValid;
  }
  return (
    <div className="dark">
      <div className="modal-head">
        <h2>LOGIN</h2>
        <div onClick={props.close} className="close-icon">
          <img src="../../../../images/Icons/close-icon.png" />
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <SimpleInput
          className={errors.email ? "form-control error" : "form-control"}
          type="email"
          name="email"
          placeholder="Enter your email"
          value={input.email}
          onChange={handleChange}
          errors={errors.email}
        />
        <SimpleInput
          className={errors.password ? "form-control error" : "form-control"}
          type="password"
          name="password"
          value={input.password}
          onChange={handleChange}
          placeholder="Enter your password"
          errors={errors.password}
        />
        
        <div className="for-term-text mb-2" >
          <Link className="forgotPassword " to="/pages/2">Terms & Conditions</Link>
          <span onClick={props.forgot} className="forgotPassword ">Forgot Password?</span>
        </div>
        <SimpleButton onClick={handleSubmit} loading={loading} name="Log In" />
        { errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error === "Identifier or password invalid." ? 'Username or password invalid.' : `${errors.error}`}</h4></div>}
        <div className="donthave-acc text-center mt-3">
          <span className="">
            Don’t have an account? <Link to="/register" ><span className="signin-color" >Register Now</span></Link>
          </span>
        </div>
      </Form>
    </div>
  );

};

export default LoginModal;
