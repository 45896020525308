import React from 'react';

const QuizHeading =(props)=> {

        return (
            <div className="defalut-heading">
                <h3 className="text-center">Assessing Mental Capacity<br/> CPD Questions (10)</h3>
                <span ></span>
            </div>
        );
}

export default QuizHeading;