import jwt_decode from 'jwt-decode';
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ApiService from '../../../helpers/ApiService';
import { applyTheme } from "../../../redux/actions/theme";
import { updateUser } from '../../../redux/actions/user';
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import EmailVerificationModal from "../Modals/EmailVerificationModal";
import ForgotPassword from "../Modals/ForgotPassword";
import ForgotPasswordEmailVerifyModal from "../Modals/ForgotPasswordEmailVerifyModal";
import LoginModal from "../Modals/LoginModal";
import RegisterModal from "../Modals/RegisterModal";
import BrowseDropDown from "./Headeritems/BroweDropDown";
import UserDrop from "./Headeritems/UserDrop";

// Hook
function useOnClickOutside(ref, buttonRef, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target) || (buttonRef && buttonRef.current.contains(event.target))) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

const MainHeader = (props) => {

    const [showMenu, setshowMenu] = useState(false);
    // const [open, setOpen] = useState(false);
    const [showDropDown, setShowDropDowm] = useState(false);
    const [showBrowse, setshowBrowse] = useState(false);
    const [showBrowseloged, setshowBrowseloged] = useState(false);
    const [showBrowsemobile, setshowBrowsemobile] = useState(false);

    const [isLoggedIn, setIsLogedIn] = useState(false);


    // Login Modal
    const [loginModal, setloginModal] = useState(false);
    const loginModalClose = () => setloginModal(false);
    const loginModalShow = () => setloginModal(true) + setregisterModal(false) + setforgotModal(false) + setemailverifyModal(false) + setNewPasswordModal(false);

    // Register Modal
    const [registerModal, setregisterModal] = useState(false);
    const registerModalClose = () => setregisterModal(false);
    const registerModalShow = () => setregisterModal(true) + setloginModal(false);

    // Email Verify Modal
    const [emailverifyModal, setemailverifyModal] = useState(false);
    const emailverifyModalClose = () => setemailverifyModal(false);
    const emailverifyModalShow = () => setemailverifyModal(true) + setloginModal(false) + setregisterModal(false);

    // Forgot Password Modal
    const [forgotModal, setforgotModal] = useState(false);
    const forgotModalClose = () => setforgotModal(false);
    const forgotModalShow = () => setforgotModal(true) + setloginModal(false);

    // Forgot Email Modal
    const [cardModal, setcardModal] = useState(false);
    const cardModalClose = () => setcardModal(false);
    const cardModalShow = () => setcardModal(true) + setforgotModal(false);

    // Change Password Modal
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const newPasswordModalClose = () => setNewPasswordModal(false);
    const newPasswordModalShow = () => setNewPasswordModal(true) + setcardModal(false);

    const ref = useRef();
    const buttonRef = useRef();
    const ref1 = useRef();
    const buttonRef1 = useRef();
    const ref2 = useRef();
    const buttonRef2 = useRef();
    const logoutRef = useRef();
    const logoutButtonRef = useRef();
    const menuRef = useRef();
    const menuButtonRef = useRef();

    useOnClickOutside(ref, buttonRef, () => setshowBrowse(false));
    useOnClickOutside(ref1, buttonRef1, () => setshowBrowseloged(false));
    useOnClickOutside(ref2, buttonRef2, () => setshowBrowsemobile(false));
    useOnClickOutside(logoutRef, logoutButtonRef, () => setShowDropDowm(false));
    useOnClickOutside(menuRef, menuButtonRef, () => setshowMenu(false));

    useEffect(() => {

        if (window.location.href.includes('?confirmation=')) {
            let url_string = window.location.href;
            let url = new URL(url_string);
            let confirm = url.searchParams.get("confirmation");

            let api = new ApiService();

            api.userConfirmation(confirm).then(_confirm => {
                loginModalShow();
            }).catch((e) => {

            });

        }

        let jwt = localStorage.getItem('jwt');

        if (jwt) {
            let data = jwt_decode(jwt);
            if (data) {
                setIsLogedIn(true);
            }
        }

        if (window.location.href.includes('?code=')) {

            if (!jwt) {
                newPasswordModalShow();
            }
        }
    }, []);

    let planUser = 0;
    if (props.user) {
        //console.log(props.user.user_subscriptions);

        let objects = props.user.user_subscriptions;
        //console.log(objects);
        if (objects.length > 0) {

            planUser = objects.reduce((prev, current) => {
                if (+current.id > +prev.id) {
                    return current;
                } else {
                    return prev;
                }
            });
        }
    }

    const toggleTheme = () => {
        console.log("Applying theme")
        props.applyTheme(props.theme === "light" ? "dark" : "light");
    }
    const handleLogout = (event) => {

        localStorage.removeItem("jwt");
        localStorage.clear();
        window.location.assign("/")
    }
    return (
        <>
            <header className="Header-main">
                <div className="container">
                    <Navbar bg="transparent" variant="dark" className="justify-content-between px-0">
                        <div className="main-header-box d-flex align-items-center justify-content-between">
                            <div className="darklight-mode text-dark px-0 ">
                                <span className="nav-link  toggleButton" onClick={toggleTheme}>
                                    {props.theme === 'light' ?
                                        <>
                                            <span className="theme light change light-theme">
                                                <img src="../../../../images/Icons/light.svg" />
                                            </span>
                                            <span className="theme dark  dark-theme">
                                                <img src="../../../../images/Icons/dark.svg" />
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span className="theme light light-theme">
                                                <img src="../../../../images/Icons/light.svg" />
                                            </span>
                                            <span className="theme dark change  dark-theme">
                                                <img src="../../../../images/Icons/dark.svg" />
                                            </span>
                                        </>
                                    }
                                </span>
                            </div>
                            <Nav.Link href="/" className="header-logo text-dark px-0 ">
                                <img src="../../../../images/header-logo.svg" />
                            </Nav.Link>
                            {!props.user &&
                                <ul className="navigation-navbar">
                                    <li>
                                        <Link ref={buttonRef} onClick={() => setshowBrowse(!showBrowse)}>Browse</Link>
                                        {showBrowse &&
                                            <div ref={ref}>
                                                <BrowseDropDown />
                                            </div>
                                        }
                                    </li>
                                    <li>
                                        <Link to="/" onClick={loginModalShow}>Login</Link>
                                    </li>
                                </ul>
                            }

                            {/* Navigation when Logged In */}

                            {props.user && 
                            
                            <ul className="navigation-navbar">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link ref={buttonRef1} onClick={() => setshowBrowseloged(!showBrowseloged)}>Browse</Link>
                                    {showBrowseloged &&
                                        <div ref={ref1}>
                                            <BrowseDropDown />
                                        </div>
                                    }
                                </li>
                                <li>
                                    <div ref={logoutButtonRef} onClick={() => setShowDropDowm(!showDropDown)} className="user-drop">
                                        <div className="user-image">
                                            <img src="../../../images/Icons/user.svg" />
                                        </div>
                                        <div className="user-name">
                                            <div className='d-flex flex-column'>
                                                <span>Welcome</span>
                                                <span>{props.user && props.user.Fname}</span>
                                            </div>
                                            <div className="names">

                                                <img src="../../../images/Icons/drop-arrow.svg" />
                                            </div>
                                        </div>
                                    </div>
                                   
                                    {showDropDown &&
                                        <div ref={logoutRef} >
                                            <UserDrop userplan={planUser} />
                                        </div>
                                    }
                                </li>
                            </ul>
                            }
                        </div>
                    </Navbar>
                </div>
            </header>

            {/*Mobile Header*/}

            <div className="mobileheader">
                <div className="mobile-logo">
                    <Link to="/">
                        <img src='../../../images/Icons/mobile-logo.png' />
                    </Link>
                </div>
                <div className="mobile-right-section">
                    <div className="mobile-browse">
                        <span ref={buttonRef2} onClick={() => setshowBrowsemobile(!showBrowsemobile)}>Browse <img
                            src='../../../images/Icons/white-arrow.png' /></span>
                        {/* User Profile Drop */}
                        {showBrowsemobile &&
                            <div ref={ref2}>
                                <BrowseDropDown />
                            </div>
                        }
                    </div>
                    <div className="mobile-menu">
                        <img ref={menuButtonRef} onClick={() => setshowMenu(!showMenu)} src='../../../images/Icons/menu-icon.png' />
                        {showMenu &&
                            <>
                                {!props.user &&
                                    <div ref={menuRef} className="login-for-mobile">
                                        <span onClick={loginModalShow}>Login</span>
                                    </div>
                                }
                                {props.user &&
                                    <div ref={menuRef} className="mobile-nav-show">
                                        <div className="navigation-info">
                                            <div className="userinfo-show">
                                                <img src="../../../images/Icons/userdummy.png" />
                                                <span>{props.user && props.user.fullName}</span>
                                            </div>
                                            <ul className="mobile-list-nav">
                                                <li>
                                                    <Link>Home</Link>
                                                </li>
                                                <li>
                                                    <Link onClick={handleLogout}>Logout</Link>
                                                </li>
                                            </ul>
                                            <ul className="mobile-list-nav">
                                                <li className="border-none">
                                                    <Link>Notifications</Link>
                                                </li>
                                                <li>
                                                    <Link>Messages</Link>
                                                </li>
                                                <li>
                                                    <Link>Personal Training Dashboard </Link>
                                                </li>
                                                <li>
                                                    <Link>PDP Hub</Link>
                                                </li>
                                                {planUser && planUser !== 0 && planUser.subscription === 4 &&
                                                    <li>
                                                        <Link to='/mentorshipdashboard'>Mentorship Dashboard </Link>
                                                    </li>
                                                }
                                                <li>
                                                    <Link to="/certificates">My Certificates </Link>
                                                </li>
                                                <li>
                                                    <Link to="/profile">My Profile </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            {/*  Login Modal */}
            <Modal show={loginModal} onHide={loginModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals"
            >
                <LoginModal
                    onLogin={() => {
                        setIsLogedIn(true);
                        setloginModal(false)
                    }}
                    showNext={registerModalShow}
                    close={loginModalClose}
                    forgot={forgotModalShow} />
            </Modal>


            {/* Register Modal */}
            <Modal show={registerModal} onHide={registerModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="authModal cardModal register-modals"
            >
                <RegisterModal checkemail={emailverifyModalShow} showNext={loginModalShow} />
            </Modal>


            {/* Email Verification */}
            <Modal show={emailverifyModal} onHide={emailverifyModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal emailverification-modal"
            >
                <EmailVerificationModal close={emailverifyModalClose} showNext={loginModalShow} />
            </Modal>


            {/* Forgot Password */}
            <Modal show={forgotModal} onHide={forgotModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals"
            >
                <ForgotPassword close={forgotModalClose} login={loginModalShow} showNext={cardModalShow} />
            </Modal>


            {/* Forgot Email Modal */}
            <Modal show={cardModal} onHide={cardModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal emailverification-modal"
            >
                <ForgotPasswordEmailVerifyModal close={cardModalClose} changePassword={newPasswordModalShow} />
            </Modal>


            {/* Change Password Modal */}
            <Modal show={newPasswordModal} onHide={newPasswordModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal Login-modals"
            >
                <ChangePasswordModal login={loginModalShow} showNext={loginModalShow} close={newPasswordModalClose} />
            </Modal>
        </>
    );
}

function mapStateToProps(state) {
    return { theme: state.theme.theme, user: state.user }
}

function mapDispatchToProps(dispatch) {
    return {
        applyTheme: theme => dispatch(applyTheme(theme)),
        updateUser: user => dispatch(updateUser(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
