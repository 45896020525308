import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from "../../../../components/Header/MainHeader";
import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";
import CareerItems from "./components/CareerItems";
import Footer from "../../../../components/Footer/Footer";
import ApiService from '../../../../../helpers/ApiService';




const Career =(props)=> { 
    
    const [foundationYears, setFoundationYears] = React.useState([]);
    const [pageTitle, setPageTitle] = React.useState('');
    let { id } = useParams();

    useEffect(async () => {

        let api = new ApiService();
       

        if(id == 'foundations'){

            setPageTitle('Foundations Careers');

            api.getFoundationYears().then(_foundations => {
                setFoundationYears(_foundations);
             }).catch((e) => {
         
             });
        } else if(id == 'students'){
            setPageTitle('Students Careers');

            api.getAspireStudents().then(_foundations => {
                setFoundationYears(_foundations);
             }).catch((e) => {
         
             });
        } else {
            return <></>
        }

        
        }, [])
        
        if(!foundationYears) {
            return <></> 
        }

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={pageTitle} />
            <CareerItems foundationYears={foundationYears}  heading={pageTitle}/>
            <Footer/>

        </>
    );

}

export default Career;