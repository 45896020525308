import React, { useState } from 'react';
import SimpleInput from "../Input/SimpleInput";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import SimpleButton from "../Buttons/SimpleButton";
import ApiService from '../../../helpers/ApiService';

const ForgotPassword = (props) => {

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let i = {...input};
        i[event.target.name] = event.target.value;
        setInput(i);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);

            let api = new ApiService();

            api.forgetPassword({
                email: input.email,
            }).then(response => {
                props.showNext();
            })
              .catch(errors => {
                //setErrors({error: errors});
                 setErrors({error: errors.message[0].messages[0].message});
              }).finally(()=>{
                setLoading(false);
              });
            
        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        setErrors(errors);
        return isValid;
    }
    return (
        <div className="dark">
            <div className="modal-head">
                <h2>FORGOT PASSWORD</h2>
                <div onClick={props.close} className="close-icon">
                    <img src="../../../../images/Icons/close-icon.png" />
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <SimpleInput
                    className={errors.email ? "form-control error" : "form-control"}
                    type="email"
                    name="email"
                    placeholder="Enter your registered email"
                    value={input.email}
                    onChange={handleChange}
                    errors={errors.email}
                />
                <SimpleButton onClick={handleSubmit} loading={loading} name="Send" />
                <Button className="  defalut-buton-text " onClick={props.login}>Login</Button>
                { errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
            </Form>
        </div>
    );
}

export default ForgotPassword;