import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import ApiService from '../../../../helpers/ApiService';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import CategoryBreadcrumbs from '../components/CategoryBreadcrumbs';
import FoudationCategoryItems from './components/FoudationCategoryItems';



const FoudationYearsCategory =(props)=> {

    const [foundationYears, setFoundationYears] = React.useState([]);
    const [pageTitle, setPageTitle] = React.useState('');
    const [pageType, setPageType] = React.useState('');
    let { id } = useParams();

    useEffect(async () => {
        window.scrollTo(0, 0);

        let api = new ApiService();
       

        if(id == '1'){

            setPageTitle('Aspire Foundation Years');
            setPageType('foundations');

            api.getFoundationYears().then(_foundations => {
                setFoundationYears(_foundations);

               // console.log(_foundations);
             }).catch((e) => {
         
             });
        } else if(id == '2'){
            setPageTitle('Aspire Students');
            setPageType('students');

            api.getAspireStudents().then(_foundations => {
                setFoundationYears(_foundations);
                //console.log(_foundations);
             }).catch((e) => {
         
             });
        } else {
            return <></>
        }

        
        }, [])
        
        if(!foundationYears) {
            return <></>
        }

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={pageTitle} />
            <FoudationCategoryItems foundationYears={foundationYears}  heading={pageTitle}  type={pageType}/>
            <Footer/>
            
        </>
    );

}

export default FoudationYearsCategory;