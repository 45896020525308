import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import UrlResolver from '../../../../../helpers/UrlResolver';


class CareerSlider extends Component {
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    render() {
        let {careers} = this.props;
        if (!careers || careers.length === 0) { 
            return <></>
        }
        return (
            <div className="float-left w-100 mt-4 dots-style">
                <OwlCarousel ref={this.sliderRef}
                    className="owl-theme"
                    items={1}
                    autoplay={false}
                    dots={true}
                    loop={true}
                    margin={30}
                    nav={false}
                    autoplayHoverPause={true}
                    autoFocus={true}
                    autoplaySpeed={2000}
                    animateIn="linear"
                    smartSpeed={300}
                    responsive={this.state.responsive}
                >
                {careers.map(career => {
                    return <div className="item">
                        <div className="courses-slider new-heights" onClick={()=>this.props.oncareerhandle(career.video)}>
                         <img src={UrlResolver.generateMediaUrl(career.banner.url)}
                                         alt={career.banner.hash}/>
                            <div className="course-overlay" >
                                <div className="showAfterhover">
                                    <div className="coursevideo-back">
                                        <img src="../../../../images/Icons/coursevideo.png" />
                                    </div>
                                    <div className="slider-desc">
                                        <h4>{career.title}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="course-cat-name">
                            <p>{`${career.description.substring(0, 100)}..`}</p>
                            </div>
                        </div>
                    </div>
                })}
                </OwlCarousel>
            </div>
        );
    }
}

export default CareerSlider;
