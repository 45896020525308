import {Link} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import { Modal } from "react-bootstrap";
import CardModal from '../../../components/Modals/CardModal';
import SimpleButton from '../../../components/Buttons/SimpleButton';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_test_nCcWEWsgJJTgFQ0DnB6h3PKj');




const CourseDetailRightBox =(props)=> {
    const [selectedPlan, setSelectedPlan] = React.useState({ price: "", plan: "" , course: ""});
    
    // Card Modal
    const [cardModal, setcardModal] = useState(false);
    const [cardType, setcardType] = useState();
    const cardModalClose = () => setcardModal(false);

    const cardModalShow = (price, plan, course) => {

       const dataPlan = {
            price,
            plan,
            course,
       }

        setcardModal(true);

        setcardType('CourseDeatils');

        setSelectedPlan(dataPlan);
    };


    return (
        <>
        <div className="right-section">
            {props.courses.type == '1' &&
            <div className="buy-buttons ">
                <div  className="buttn-box">
                    <Link to='/subscription' className="defalut-button">Subscribe Now</Link> 
                   
                </div>
                <div  className="buttn-box disabled">
                    {/* <span>£{props.courses.coursefee} for this course</span> */}
                   {!props.user ? <Link to='/register' >Buy This Course</Link> : <Link onClick={() => cardModalShow(`${props.courses.coursefee}`,'null', `${props.courses.id}`)}>Buy This Course</Link> } 
                </div>
            </div>
            }

            {/* display button when purchase the course */}
            {props.courses.type != '1' &&
            <div className="watch-course-button" >
                <SimpleButton
                    name="Watch this Course Now"
                />
            </div>
            }
            {/*For Mobile View*/}
            <div className="detail-actions for-mobile-only">
                <Link to="/">
                    <span>Add to PDP</span>
                    <img src="../../../../images/Icons/pdp.png"/>
                </Link>
                <Link to="/">
                    <span>Bookmark</span>
                    <img src="../../../../images/Icons/bookmark.png"/>
                </Link>
                {props.courses.type == '1' &&
                <Link to='#' className="take-quiz-disable">
                    <span>Take quiz</span>
                    <img src="../../../../images/Icons/quiz.png"/>
                </Link>
                }
                {props.courses.type != '1' &&
                <Link to={`/quiz`} className="take-quiz-enable" style={{display : 'flex'}}>
                    <span>Take quiz</span>
                    <img src="../../../../images/Icons/quiz-enable.png"/>
                </Link>
                }
            </div>

            <div className="course-list-box">

                {props.courses.type == '1' &&

                    <ul>
                        
                        <li>
                            <Link className="active" onClick={()=>props.onhandle(props.courses.previewVideo)}>
                                <div className="count-with-name">
                                    <span>1.</span>
                                    <p  className="pl-3">Course Preview</p>
                                </div>
                                <img src="../../../../images/Icons/expand-icon.png"/>
                            </Link>
                        </li>

                    {props.courses.lessons.map((lesson , index) => {
                        return <li>
                        <Link className="" src="" >
                            <div className="count-with-name">
                                    <span>{index + 2}.</span>
                                    <p  className="pl-3">{lesson.Title}</p>
                                </div>
                                <img src="../../../../images/Icons/expand-icon.png"/>
                        </Link>
                        </li>
                    })}
                    </ul>
                
                }

                {props.courses.type != '1' &&

                    <ul>
                        
                        <li>
                            <Link className={props.activeId === 'preview' ? "active" : "Inactive"} onClick={()=>props.onhandle(props.courses.previewVideo , 'preview')}>
                                <div className="count-with-name">
                                    <span>1.</span>
                                    <p  className="pl-3">Course Preview</p>
                                </div>
                                <img src="../../../../images/Icons/expand-icon.png"/>
                            </Link>
                        </li>

                    {props.courses.lessons.map((lesson , index) => {
                        return <li>
                        <Link className={props.activeId === lesson.id ? "active" : "Inactive"} src={lesson.Video} onClick={()=>props.onhandle(lesson.Video, lesson.id)}>
                            <div className="count-with-name">
                                    <span>{index + 2}.</span>
                                    <p  className="pl-3">{lesson.Title}</p>
                                </div>
                                <img src="../../../../images/Icons/expand-icon.png"/> 
                        </Link>
                        </li>
                    })}
                    </ul>
                
                }
                
            </div>

        </div>
        

        <Modal show={cardModal} onHide={cardModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="authModal cardModal"
                size="md"
                className="authModal cardModal pay-modals"
            ><Elements stripe={stripePromise}>
                <CardModal planDone={selectedPlan} nextUrl={cardType} close={cardModalClose}/>
            </Elements>
            </Modal>
            </>
    );

}

export default CourseDetailRightBox;