import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import DefaultHeading from "../../../components/Heading/DefaultHeading";
import ApiService from '../../../../helpers/ApiService';
import UrlResolver from '../../../../helpers/UrlResolver';


const OurTutorItems = (props) => {

    const [tutors, setTutors] = React.useState([]);

    useEffect(async () => {

        let api = new ApiService();

        api.getTutors().then(_tuts => {
            //console.log(_tuts);
            setTutors(_tuts);
         }).catch((e) => {
     
         });
        }, [])

    return (
        <>
            <div className="our-tutor-section">
                <DefaultHeading
                    heading="Our Tutors"
                />
                <div className="container">
                    <div className="our-tutors-box">
                        <div className="tutor-list">
                            <ul>
                                {tutors.map(tutor => {
                                    return <li><Link to={`/tutors/${tutor.id}`} >
                                        <div className="tutor-photo">
                                            <img
                                                    src={UrlResolver.generateMediaUrl(tutor.profilepicture.url)}/>
                                                <div className="tutor-info">
                                                    <span>{tutor.title}</span>
                                                    <p>{tutor.Name}</p>
                                                    <div className="showAfterHover float-left w-100 d-flex  align-items-center"><span>View Courses</span></div>
                                                </div>
                                        </div></Link>
                                    </li>
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default OurTutorItems;