import React from 'react';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import MentorshipBreadcrumbs from '../Components/MentorshipBreadcrumbs';
import MentorDashboardItems from './Components/MentorDashboardItems';
import { connect } from "react-redux";


const MentorshipDashboard =(props)=> {
  
   return (
            <>
                {/* Header */}
                <MainHeader/>
                <MentorshipBreadcrumbs hubname="Mentorship Dashboard" />
                {/* Body */}
                <MentorDashboardItems user={ props.user }/>

                {/* Footer */}
                <Footer/>
            </>
        );
}

function mapStateToProps(state) {
    return {user:state.user}
}

export default connect(mapStateToProps)(MentorshipDashboard);
