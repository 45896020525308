import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ApiService from '../../../../helpers/ApiService';
import DefaultHeading from '../../../components/Heading/DefaultHeading';


//const CATEGORIES = [{id: 1, name: "Test Category 1"}, {id: 2, name: "Test Category 2"}, {id: 3, name: "Test Category 3"}, {id: 4, name: "Test Category 4"}];

const CategoryList = (props) => {

    const [categories,setCategories] = React.useState([]);

    useEffect(async ()=>{
        let api = new ApiService();

        api.getCategories().then(_cats => {
            let sortCat =   _cats.sort((a, b) => a.verticalPosition - b.verticalPosition);
             setCategories(sortCat.filter(cat=>{
                 return cat.parent === null && cat.verticalPosition != 0;
             }));
          }).catch((e) => {

          });

    },[])


    return (
        <>
            <div className="Horizontal-tab-section">
                <div className="container">
                    <div className="all-cat-mobile">
                        <DefaultHeading heading="Aspire Categories" />
                    </div>
                    <div className="video-tabs">
                        <div className="home-cat-list">
                            <ul role="tablist" className="">
                                {categories.map(category => {
                                    return <li className={`nav-item ${category.name}`}>
                                    {category.name == 'Aspire Journal Club' ? <Link to="/journalcategory" className="">{category.name}</Link> 
                                              : category.name  == 'Aspire Students' ? <Link to="/students/2" className="">{category.name}</Link>
                                               : category.name == 'Aspire Foundation' ? <Link to="foudationyears/1" className="">{category.name}</Link>
                                               : category.name == 'Aspire Live' ? <Link to="#" className="">{category.name}</Link>
                                               : category.name == 'Aspire News' ? <Link to="/aspirenews" className="">{category.name}</Link>
                                               : category.name == 'Aspire Mentorship' ? <Link to="/mentorship" className="">{category.name}</Link>
                                               : <Link to={`/coursecategory/${category.id}`} className="">{category.name}</Link> 
                                               }
                                    </li>
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryList;
