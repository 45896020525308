import { Link } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import CertificateTable from './CertificateTable';
import UploadModal from '../../../components/Modals/UploadModal';
import {Tabs, Tab,} from 'react-bootstrap-tabs';
import ExternalTable from './ExternalTable';
import { connect } from "react-redux";

const CertificateItems = (props) => {

    // Card Modal
    const [uploadModal, setuploadModal] = useState(false);
    const uploadModalClose = () => setuploadModal(false);
    const uploadModalShow = () => setuploadModal(true);

    let objects = 0;
    if(props.user){
        
        objects = props.user.user_quiz;
        
        if(objects.length === 0){
            objects = 0;
        } else {

            objects = objects.filter(cat => {
                return cat.QuizResult == "passed";
            });
            
           if(objects == 'null'){
              objects = 0; 
            }
        }

        // console.log(objects)

     }

    return (
        <div className="certificate-items">
            <div className="container">
                <div className="certificate-boxes">
                    <div className="info-header">
                        <h4>My Certificates </h4>
                    </div>
                </div>
                <div className="certificate-tabs-new">
                    <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                        <Tab label="Aspire Certificates">
                            <div className="certificate-table">
                                <div className="download-all">
                                    <Link >
                                    Download All
                                    </Link>
                                </div>
                                <CertificateTable  certs={props.certificate.aspire}  quizs={objects}/>
                                <div className="goto-dasboard">
                                    <Link>Go to Personal Training Dashboard<img src='../../../../images/Icons/double-right-arrow.png' alt={"Arrow"}/></Link>
                                </div>
                            </div>
                        </Tab>
                        <Tab label="External Certificates">
                            <div className="certificate-table">
                                <div className="upload-document">
                                    <Link onClick={uploadModalShow}>
                                        Upload External Certificates
                                        <img src="../../../../images/Icons/arrow-up.png" />
                                    </Link>
                                </div>
                                <ExternalTable certs={props.certificate.external}/>
                                <div className="goto-dasboard">
                                    <Link>Go to Personal Training Dashboard <img src='../../../../images/Icons/double-right-arrow.png' alt={"Arrow"}/></Link>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>

            </div>

            {/* Upload Certificate Modal */}
            <Modal show={uploadModal} onHide={uploadModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="authModal cardModal"
                size="md"
                className="authModal cardModal pay-modals"
            >
                <UploadModal onUploaded={props.onUploaded} close={uploadModalClose} />
            </Modal>
        </div>
    );

}

function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(CertificateItems); 
