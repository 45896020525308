import React, { useState } from 'react';
import { Link } from "react-router-dom";
import DefaultHeading from '../../../../components/Heading/DefaultHeading';
import CareerSlider from './CareerSlider';
import CompetitionsSlider from './CompetitionsSlider';
import EventsSlider from './EventsSlider';
import LearningSlider from './LearningSlider';
import TalkDentalSlider from './TalkDentalSlider';
import { Tabs, Tab, } from 'react-bootstrap-tabs';
import { Modal } from "react-bootstrap";
import RegisterModal from "../../../../components/Modals/RegisterModal";

const FoudationCategoryItems = (props) => {

    // Learning Resources
    const [showModal, setShowModal] = useState(false);
    const [videoId, setVideoId] = useState('');
    const showModalClose = () => setShowModal(false);


    const handleClick = (value) => {
        setVideoId(value);
        // console.log(value);
        setShowModal(true)

    }

    // Career Slider
    const [careerModal, setCareerModal] = useState(false);
    const [videoCareerId, setVideoCareerId] = useState('');
    const careerModalClose = () => setCareerModal(false);
    const careerModalShow = () => setCareerModal(true);

    const handleCareerClick = (value) => {
        setVideoCareerId(value);
        //console.log(value);
        setCareerModal(true)

    }

    if (!props.foundationYears) {
        return <></>
    }
    return (
        <>
            <div className="foudation-years">
                <div className="container">
                    <DefaultHeading heading={props.heading} />
                    <div className="foudation-desktop">
                        <div className="learning-resources">
                            <div className="learning-heading">
                                <div className="simple-heading width-auto">
                                    <Link to={`/learningresources/${props.type}`}>
                                        <h3>Learning Resources</h3>
                                        <span></span>
                                    </Link>
                                </div>
                                <Link to={`/learningresources/${props.type}`}>View All</Link>
                            </div>
                            <div className="learning-slider">
                                <LearningSlider learnResorces={props.foundationYears.LearningResources} type={props.type} />
                            </div>
                        </div>
                        <div className="learning-resources">
                            <div className="learning-heading">
                                <div className="simple-heading width-auto">
                                    <Link to={`/talkdental/${props.type}`}>
                                        <h3>Talk Dental</h3>
                                        <span></span>
                                    </Link>
                                </div>
                                <Link to={`/talkdental/${props.type}`}>View All</Link>
                            </div>
                            <div className="learning-slider">
                                <TalkDentalSlider talkDentals={props.foundationYears.TalkDental} type={props.type} />
                            </div>
                        </div>
                        <div className="learning-resources">
                            <div className="learning-heading">
                                <div className="simple-heading width-auto">
                                    <Link to={`/career/${props.type}`}>
                                        <h3>Careers</h3>
                                        <span></span>
                                    </Link>
                                </div>
                                <Link to={`/career/${props.type}`}>View All</Link>
                            </div>
                            <div className="learning-slider">
                                <CareerSlider careers={props.foundationYears.Careers} oncareerhandle={handleCareerClick} />
                            </div>
                        </div>
                        <div className="learning-resources">
                            <div className="learning-heading">
                                <div className="simple-heading width-auto">
                                    <Link to={`/events/${props.type}`}>
                                        <h3>Events</h3>
                                        <span></span>
                                    </Link>
                                </div>
                                <Link to={`/events/${props.type}`}>View All</Link>
                            </div>
                            <div className="learning-slider">
                                <EventsSlider events={props.foundationYears.Events} type={props.type} />
                            </div>
                        </div>
                        <div className="learning-resources">
                            <div className="learning-heading">
                                <div className="simple-heading width-auto">
                                    <Link to={`/competitions/${props.type}`}>
                                        <h3>Competitions</h3>
                                        <span></span>
                                    </Link>
                                </div>
                                <Link to={`/competitions/${props.type}`}>View All</Link>
                            </div>
                            <div className="learning-slider">
                                <CompetitionsSlider competitions={props.foundationYears.Competitions} type={props.type} />
                            </div>
                        </div>
                    </div>

                    {/*For Mobile Only */}
                    <div className="foudation-years-mobile">
                        <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                            <Tab label="Learning Resources">
                                <div className="learning-resources">
                                    <div className="learning-slider">
                                        <LearningSlider learnResorces={props.foundationYears.LearningResources} onhandle={handleClick} />
                                    </div>
                                    <div className="learning-heading">
                                        <Link to="/learningresources">View All</Link>
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Talk Dental">
                                <div className="learning-resources">
                                    <div className="learning-slider">
                                        <TalkDentalSlider talkDentals={props.foundationYears.TalkDental} />
                                    </div>
                                    <div className="learning-heading">
                                        <Link to="/learningresources">View All</Link>
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Careers">
                                <div className="learning-resources">
                                    <div className="learning-slider">
                                        <CareerSlider careers={props.foundationYears.Careers} oncareerhandle={handleCareerClick} />
                                    </div>
                                    <div className="learning-heading">
                                        <Link to="/learningresources">View All</Link>
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Events">
                                <div className="learning-resources">
                                    <div className="learning-slider">
                                        <EventsSlider events={props.foundationYears.Events} />
                                    </div>
                                    <div className="learning-heading">
                                        <Link to="/learningresources">View All</Link>
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Competitions">
                                <div className="learning-resources">
                                    <div className="learning-slider">
                                        <CompetitionsSlider competitions={props.foundationYears.Competitions} />
                                    </div>
                                    <div className="learning-heading">
                                        <Link to="/learningresources">View All</Link>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>

            {/*  Video Popup Modal  */}
            <Modal show={showModal} onHide={showModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="authModal cardModal slider-popup "
            >
                <div className="slider-modal-video">
                    <iframe
                        style={{


                        }}
                        src={`https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&autopause=0&autoplay=1`}
                        frameBorder="0"
                    />
                </div>
            </Modal>


            { /*  Video Popup Modal  */}

            <Modal show={careerModal} onHide={careerModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="authModal cardModal slider-popup "
            >
                <div className="slider-modal-video">
                    <iframe
                        style={{


                        }}
                        src={`https://player.vimeo.com/video/${videoCareerId}?autoplay=1&loop=1&autopause=0&autoplay=1`}
                        frameBorder="0"
                    />
                </div>
            </Modal>
        </>
    );

}

export default FoudationCategoryItems;