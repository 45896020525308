import * as axios from 'axios';

export default class ApiService {
  constructor() {
    this.api_token = null;
    this.client = null;
     this.api_url = 'https://backend.aspire-online.itmatic.io';
     //this.api_url = 'http://localhost:1337';
  }

  init(authorize = true, multipart = false) {
   this.api_token = localStorage.getItem('jwt');

    const headers = {
      Accept: 'application/json',
    };
    if(authorize && this.api_token){
        headers['Authorization'] = `Bearer ${this.api_token}`;
    }

    if(multipart){
       headers['content-type']  = 'multipart/form-data';
    }


    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers,
    });

    return this.client;
  };

  post(url, data, authorize = true, multipart = false) {
    return new Promise(async (resolve, reject) => {
      this.init(authorize, multipart)
        .post(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          console.log(e.response);
          reject(e.response ? e.response.data : {});
        });
    });
  }

  put(url, data, authorize = true) {
    return new Promise(async (resolve, reject) => {
      this.init(authorize)
        .put(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          console.log(e.response.data);
          reject(e.response ? e.response.data : {});
        });
    });
  }

  get(url, params, authorize = true) {
    return new Promise(async (resolve, reject) => {
      this.init(authorize)
        .get(url, { params })
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e.response ? e.response.data : {});
        });
    });
  }

  delete(url) {
    return new Promise(async (resolve, reject) => {
      this.init()
        .delete(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          reject(e.response ? e.response.data : {});
        });
    });
  }

  login(params) {
    return this.post('/auth/local', params, false);
  };

  resetPassword(params) {
    return this.post('/auth/reset-password', params);
  };

  register(params) {
    return this.post('/auth/local/register', params, false);
  };

  forgetPassword(params) {
    return this.post('/auth/forgot-password', params);
  };

  changePassword(params) {
    return this.post('/password', params);
  };

  getCourses(params) {
    return this.get('/courses', params,false);
  }

  getCategories(params) {
    return this.get('/course-categories', params,false);
  }

  getTutors(params) {
    return this.get('/tutors', params,false);
  }

  getSlots(params) {
    return this.get('/slots', params,false);
  }

  getTestimonials(params) {
    return this.get('/testmonials', params,false);
  }

  getPlans(params) {
    return this.get('/subscriptions', params);
  }

  getMyCertificates(params) {
    return this.get('/certificates/me', params);
  }

  getFeedbackQuestions(params) {
    return this.get('/Feedbacks', params);
  }

  uploadFileCertificate(params) {
    return this.post('/upload/', params, true, true);
  };

  uploadCertificate(params) {
    return this.post('/certificates', params);
  };

  getCourseCategory(id) {
    return this.get(`/course-categories/${id}`);
  };

  getCourse(id) {
    return this.get(`/courses/${id}`);
  };

  getQuizs(id) {
    return this.get(`/courses/${id}`);
  };

  getPages(id) {
    return this.get(`/pages/${id}`);
  };

  userConfirmation(id) {
    return this.get(`/auth/email-confirmation?confirmation=${id}`);
  };

  subscription(params) {
    return this.post('/user-subscriptions', params);
  };

  setquiz(params) {
    return this.post('/user-quizs', params);
  };

  setfeedback(params) {
    return this.post('/user-feedbacks', params);
  };

  getMeUser(params) {
    return this.get('/users/me', params);
  }

  getMentor(params) {
    return this.get('/users/mentor', params);
  }


  updateUser(params) {
    return this.put('/users/updateMe', params);
  };

  setDactorSlots(params) {
    return this.post('/dactorslots', params);
  };

  updateDactorSlots(params) {
    return this.put('/dactorslots/updateSlot', params);
  };
  
  mentorRequest(params) {
    return this.put('/users/requestMentor', params);
  };

  getPatient(id) {
    return this.get(`/patients/${id}`);
  }; 

  getDactorSlots(id) {
    return this.get(`/users/${id}`);
  };

  setPatient(params) {
    return this.post('/patients', params);
  };

  updatePatient(params) {
    return this.put(`/patients/${params.id}`, params);
  };

  editPatient(params) {
    return this.put('/patients/updatePatient', params);
  };

  getScheduleCall(id) {
    return this.get(`/schedule-calls/${id}`);
  };

  getScheduleCallWithDate(params) {
    return this.get(`/schedule-calls?callDate=${params}`);
  }

  setScheduleCall(params) {
    return this.post('/schedule-calls', params);
  };

  updateBooking(params) {
    return this.put(`/schedule-calls/${params.id}`, params);
  };

  getJournalClub(params) {
    return this.get('/journal-club', params);
  }

  getAspireNews(params) {
    return this.get('/aspire-news', params);
  }

  getFoundationYears(params) {
    return this.get('/aspire-foundation-years', params);
  }

  getAspireStudents(params) {
    return this.get('/aspire-students', params);
  }

  deletePatientfile(id){
    return this.delete(`/upload/files/${id}`);
  }

}
