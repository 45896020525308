import * as axios from 'axios';

export default class Api {

    //static BASE_API_URL = 'http://localhost:1337';
    static BASE_API_URL = 'https://backend.aspire-online.itmatic.io';


    static get(url) {
        return Api.call(url);
    }

    static post(url, data) {
        return Api.call(url, "POST", data);
    }

    static call(url, method = "GET", data, multipart = false, authorized = true, shouldRedirectOnAccessDenied = true) {

        let headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Accept": 'application/json',
        };
        if (multipart) {
            //delete headers['Content-Type'];
        }

        // console.log("Calling API: " + method + " " + Api.BASE_API_URL + url);

        // console.log(data);

        // console.log(headers);

        if (!multipart) {
            data = JSON.stringify(data);
        }
        return new Promise((resolve, reject) => {
            fetch(Api.BASE_API_URL + url, {
                method: method || "GET",
                body: data,
                headers: headers
            }).then(async response => {
                // response only can be ok in range of 2XX
                if (response.ok) {
                    // you can call response.json() here too if you want to return json
                    resolve(response.json());
                } else {
                    //handle errors in the way you want to


                    switch (response.status) {

                        case 404:
                            console.log('Object not found');
                            break;
                        case 500:
                            console.log('Internal server error');
                            break;
                        case 403:
                            if (shouldRedirectOnAccessDenied) window.location.href = "/login";
                            break;
                        default:
                            console.log('Some error occured');
                            break;
                    }
                    console.log("URL: " + method + " " + url);

                    let body = await response.text();
                    console.log(body);
                    let json = JSON.parse(body);
                    console.log("Error: " + json.error);
                    return reject(json.error);
                }
            })
                .catch(error => {
                    //it will be invoked mostly for network errors
                    //do what ever you want to do with error here
                    console.log(error);
                    reject("Connection error.");
                });
        });
    }
}
