import React from 'react';
import {Link} from "react-router-dom";
import DefaultHeading from '../../../components/Heading/DefaultHeading';
import UrlResolver from "../../../../helpers/UrlResolver";

const CategoryCourseList = (props) => {
    if(!props.courses){
        return <></>
    }
    return (
        <div className="cate-course-list">
            <div className="container">
                <DefaultHeading heading="Courses"/>
                <div className="course-list cat-course">
                {props.courses.length === 0 && 'No Course' }
                    {props.courses.map((course,index) => {
                        return <div className="col-md-3" key={`course-${index}`}>
                            <div className="courses-slider">
                                <Link to={UrlResolver.generateCourseUrl(course.id)}>
                                    <img src={UrlResolver.generateMediaUrl(course.thumbnail.url)}
                                         alt={course.thumbnail.hash}/>
                                    <div className="course-overlay">
                                        <div className="showAfterhover">
                                            <div className="coursevideo-back">
                                                <img src="../../../../images/Icons/coursevideo.png"/>
                                            </div>
                                            <div className="slider-desc">
                                                <h4>{course.Name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="course-cat-name">
                                        <p>{`${course.Description.substring(0, 140)}...`}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </div>
    );

}

export default CategoryCourseList;
