import React from 'react';
import { Link } from "react-router-dom";
import DefaultHeading from '../../../../components/Heading/DefaultHeading';

const ConnectDirectlywith = (props) => {

    return (
        <div className="cate-course-list" style={{borderBottom:'none'}}>
            <DefaultHeading heading="Connect Directly With" />
            <div className="course-list cat-course" >

                {/* loop Div */}
                <div className="col-md-2">
                    <div className="courses-slider">
                        <Link to='/'>
                            <img src='../../../../../../images/Icons/mentor-cat-1.png'
                                alt='' />
                            <div className="course-overlayfor-mentor">
                                <div className="showAfterhover">
                                    <div className="slider-desc">
                                        <h4>Restorative dentists</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="courses-slider">
                        <Link to='/'>
                            <img src='../../../../../../images/Icons/mentor-cat-2.svg'
                                alt='' />
                            <div className="course-overlayfor-mentor">
                                <div className="showAfterhover">
                                    <div className="slider-desc">
                                        <h4>Endodontists</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="courses-slider">
                        <Link to='/'>
                            <img src='../../../../../../images/Icons/mentor-cat-3.svg'
                                alt='' />
                            <div className="course-overlayfor-mentor">
                                <div className="showAfterhover">
                                    <div className="slider-desc">
                                        <h4>Periodontists</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="courses-slider">
                        <Link to='/'>
                            <img src='../../../../../../images/Icons/mentor-cat-4.svg'
                                alt='' />
                            <div className="course-overlayfor-mentor">
                                <div className="showAfterhover">
                                    <div className="slider-desc">
                                        <h4>Prosthodontists </h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="courses-slider">
                        <Link to='/'>
                            <img src='../../../../../../images/Icons/mentor-cat-5.svg'
                                alt='' />
                            <div className="course-overlayfor-mentor">
                                <div className="showAfterhover">
                                    <div className="slider-desc">
                                        <h4>Orthodontists</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="courses-slider">
                        <Link to='/'>
                            <img src='../../../../../../images/Icons/mentor-cat-6.svg'
                                alt='' />
                            <div className="course-overlayfor-mentor">
                                <div className="showAfterhover">
                                    <div className="slider-desc">
                                        <h4>Oral surgeons</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ConnectDirectlywith;