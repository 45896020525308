import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeading from '../../../../../components/Heading/DefaultHeading';
import FieldPracticeList from './FieldPracticeList';



const FieldPracticeItems = (props) => {

    return (
        <div className="foudation-years">
            <div className="container">
                <DefaultHeading heading="Field of Practice" />
                <FieldPracticeList/>
                <div className="personal-button-group">
                    <Link to="/planing" className="practice-back">Back </Link>
                    <Link className="next-plan" to="/choosepdp">Next</Link>
                </div>
            </div>
        </div>
    );
}

export default FieldPracticeItems;
