import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import ApiService from '../../../helpers/ApiService';
import SimpleButton from '../Buttons/SimpleButton';
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import SimpleInput from '../Input/SimpleInput';

const CardModal = (props)=> {

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        email: "",
        phone: "",
        name: ""
    });

    const processPayment = async (event) => {
        setLoading(true);
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: billingDetails
        });

        if (error) {
            setLoading(false);
            console.log('[error]', error);
        } else {
            
            console.log('[PaymentMethod]', paymentMethod);
            handleSubmit(event, paymentMethod)
        }
    };

    let api =  new ApiService();

    
    const [errors, setErrors] = useState({});

    const handleSubmit = (event, paymentMethod) => {
        event.preventDefault();
       

        // const date = new Date();
        // date.setFullYear(date.getFullYear() + 1);

        // let YYYY = date.getFullYear();
        // let DD = date.getMonth()+1;
        // let MM = date.getDate();
        // let dateNew = YYYY+'-'+MM+'-'+DD;

        const subPrice = props.planDone.price;
        const subPlan = props.planDone.plan;
        const subCourse = props.planDone.course;

        if(subCourse === 'patients') {

            var data = {
                PaymentResponse: {
                    paymentMethod
                },
                id: subPlan,
              }
    
            
            api.updatePatient(data)
               .then(response => {
                    setLoading(false);
                    window.location.reload(); 
                    
                }).catch(errors => {
                    console.log(errors);
                    //setErrors({error: errors.message[0].messages[0].message});
                 }).finally(()=>{
                    setLoading(false);
                });

        } else {

            var data = {
                PaymentResponse: {
                    paymentMethod
                },
                subscription: subPlan,
                course: subCourse,
                amount:subPrice,
             }
    
            api.subscription(data)
               .then(response => {
                    setLoading(false);
                    if(props.nextUrl == 'CourseDeatils'){
                        window.location.reload(); 
    
                    } else {
                        window.location.href = "/mentorship";
                    }
                    
                }).catch(errors => {
                    setErrors({error: errors.message[0].messages[0].message});
                 }).finally(()=>{
                    setLoading(false);
                });

        }

        

    }

        return (
            <div className="dark">
                <div className="card-modal-Header">
                    <div className="pay-card">
                        <span>Pay</span>
                       <p>£</p>
                        <span>{props.planDone.price}</span>
                    </div>
                    <div className="cross-photo">
                        <img onClick={props.close} src="../../../../images/Icons/closs-icon.svg"/>
                    </div>
                    { errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
                </div>
                <Form onSubmit={processPayment}>
                 <SimpleInput className="form-control"  name="name" type="text"  placeholder="Card holder name" onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}/>
                 <SimpleInput className="form-control"  name="email" type="email"  placeholder="Email" onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}/>
                 <SimpleInput className="form-control"  name="phone" type="text"  placeholder="Phone Number" onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value });
          }}/>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#ffffff',
                                        borderWidth:1,
                                        borderStyle:'solid',
                                        borderColor:'#ffffff',
                                        '::placeholder': {
                                            color: '#ffffff',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        />
                    {/* <SimpleInput className="form-control"   type="text" placeholder="Card number"/>
                    <SimpleInput className="form-control"   type="text" placeholder="Card holder name"/>
                    <div className="col-md-6 pl-0">
                        <SimpleInput className="form-control"   type="text" placeholder="Expiry month"/>
                    </div>
                    <div className="col-md-6 pr-0   ">
                        <SimpleInput className="form-control"   type="text" placeholder="Cvv"/>
                    </div>                */}
                    {/* <Link onClick={props.showNext} className="defaultButton">Pay Now</Link> */}
                    <div className="button-col payment">
                            <SimpleButton
                                name="Pay Now"
                                onClick={processPayment}
                                loading={loading}
                            />
                        </div>
                    { errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
                </Form>
            </div>
        );
}

export default CardModal;
