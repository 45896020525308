import React from 'react';
import { Link } from "react-router-dom";
import UrlResolver from '../../../../../../helpers/UrlResolver';

const EventsList = (props) => {

    if (!props.Items) {
        return <></>
    }
    return (
        <>
            <div className="events-list-items">
                {props.Items.map(item => {
                    return <div className="events-box">
                        <div className="events-image-col">
                            <img src={UrlResolver.generateMediaUrl(item.banner.url)} alt={item.banner.hash} />
                        </div>
                        <div className="Events-meta">
                            <div className="event-meta-info">
                                <span>Name </span>
                                <p>{item.title}</p>
                            </div>
                            <div className="event-meta-info">
                                <span>Description: </span>
                                <p>{item.description}</p>
                            </div>
                            <div className="event-meta-info">
                                <span>Location </span>
                                <p>{item.location}</p>
                            </div>
                            <div className="event-meta-info">
                                <span>Date </span>
                                <p>{item.date}</p>
                            </div>
                            <div className="event-links">
                                <a href={item.link} target="_blank" >View Detail</a>
                                {/* <Link to={item.link}>View Detail</Link> */}
                            </div>
                        </div>
                    </div>
                })}
                {/* <div className="events-box">
                    <div className="events-image-col">
                        <img src='../../../../images/Icons/career2.png' />
                    </div>
                    <div className="Events-meta">
                        <div className="event-meta-info">
                            <span>Name </span>
                            <p>Name of the Event 1</p>
                        </div>
                        <div className="event-meta-info">
                            <span>Description: </span>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                        </div>
                        <div className="event-meta-info">
                            <span>Location </span>
                            <p>Strt 30, London</p>
                        </div>
                        <div className="event-meta-info">
                            <span>Location </span>
                            <p>23rd September 2020</p>
                        </div>
                        <div className="event-links">
                            <Link>View Detail</Link>
                        </div>
                    </div>
                </div> */}

            </div>
        </>
    );

}

export default EventsList;