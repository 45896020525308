import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const MentorUpcomingCalls = (props) => {
    //console.log(props.scheduleCalls);

    const d = new Date();

    let startdate = d.toISOString().slice(0, 10);

    let userscheduleCalls = [];
    if (props.scheduleCalls) {

        

        let objects = props.scheduleCalls;

        //console.log(objects);

        if (objects.length === 0) {
            userscheduleCalls = [];
        } else {
            userscheduleCalls = objects;

            //console.log(userscheduleCalls);
        }
    }
  
    return (
        <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Requester</th>
                        <th>Patients</th>
                        <th>Date & Time</th> 
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {userscheduleCalls.map(userscheduleCall => {
                    if (userscheduleCall.status === 'Scheduled' && userscheduleCall.callDate >= startdate) {
                        return <tr>
                        <td>{userscheduleCall.createdby.fullName}</td>
                        <td>
                        {userscheduleCall.patient.patientName}
                        </td>
                        <td>{userscheduleCall.callDate}, {userscheduleCall.callSlot}</td>
                        <td>{userscheduleCall.status}</td>
                        <td>
                            <div className='mentor-actions'>
                                <div className='view-conversation' >
                                    {/* <Link to={`/viewconversation/${userscheduleCall.patient.id}`} >Reshedule</Link> */}
                                    <Link  >View</Link>
                                </div>
                                <div className='mentor-edit'>
                                    <span >Cancel</span>
                                </div>
                            </div>

                        </td>
                    </tr>
                    }
                })}
                </tbody>
            </table>

        </div>
    );
}

export default MentorUpcomingCalls;
