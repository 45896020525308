import React, {useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import FeedBackBredcrumbs from './component/FeedBackBredcrumbs.';
import FeedBackItems from './component/FeedBackItems';
import ApiService from '../../../helpers/ApiService';
import {Link ,useParams} from "react-router-dom";
import { connect } from "react-redux";


const FeedBack =(props)=> {

    const [feedback,setFeedback] = React.useState(null);
    const [coures,setCourses] = React.useState(null);

    const parameter = useParams();

    useEffect(async ()=>{

        let api = new ApiService();

        api.getFeedbackQuestions().then(_feeds => {
            setFeedback(_feeds);

            api.getCourse(parameter.id).then(_cou => {
                setCourses(_cou);
             }).catch((e) => {
         
             });
          }).catch((e) => {
      
          });

        },[])

    if(!feedback) {
        return <></>
    }

    if(!coures) {
        return <></>
    } 

    return (
        <>
            <MainHeader/>
            <FeedBackBredcrumbs courses={coures} />
            <FeedBackItems Feedbacks={feedback} courses={coures} result={parameter.type}/>
            <Footer/>
        </>
    );

}

function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(FeedBack);