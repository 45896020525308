import React from 'react'
import {Route} from 'react-router-dom';
import Registered from "../auth/Registered";

const AuthRoute = ({component: Component}) => {

    return (
        <Route
            render={props => (
                <div className="auth-screen">
                    <Route exact path="/register" component={Registered}/>
                </div>                    
            )}
        />
    )
};

export default AuthRoute;