import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import ApiService from '../../../../../helpers/ApiService';
import Footer from '../../../../components/Footer/Footer';
import MainHeader from '../../../../components/Header/MainHeader';
import CategoryBreadcrumbs from '../../components/CategoryBreadcrumbs';
import LearningResourcesItems from './components/LearningResourcesItems';

const LearningResources =(props)=> {

    const [foundationYears, setFoundationYears] = React.useState([]);
    const [webniars, setWebniars] = React.useState([]);
    const [other1, setOther1] = React.useState([]);
    const [other2, setOther2] = React.useState([]);
    const [pageTitle, setPageTitle] = React.useState('');
    const [pageType, setPageType] = React.useState('');
    let { id } = useParams();

    useEffect(async () => {

        let api = new ApiService();
       

        if(id == 'foundations'){

            setPageTitle('Foundations Learning Resources');

            setPageType('foundations');

            api.getFoundationYears().then(_foundations => {
                setFoundationYears(_foundations);

                setWebniars(_foundations.LearningResources.filter(cat => {
                    return cat.category === 'Webinars';
                }));

                setOther1(_foundations.LearningResources.filter(cat => {
                    return cat.category === 'Other1';
                }));

                setOther2(_foundations.LearningResources.filter(cat => {
                    return cat.category === 'Other2';
                }));

             }).catch((e) => {
         
             });
        } else if(id == 'students'){
            setPageTitle('Students Learning Resources');
            setPageType('students');

            api.getAspireStudents().then(_foundations => {
                setFoundationYears(_foundations);

                setWebniars(_foundations.LearningResources.filter(cat => {
                    return cat.category === 'Webinars';
                }));

                setOther1(_foundations.LearningResources.filter(cat => {
                    return cat.category === 'Other1';
                }));

                setOther2(_foundations.LearningResources.filter(cat => {
                    return cat.category === 'Other2';
                }));

             }).catch((e) => {
         
             });
        } else {
            return <></>
        }

        
        }, [])
        
        if(!foundationYears) {
            return <></>
        }

    return ( 
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={pageTitle} /> 
            <LearningResourcesItems foundationYears={foundationYears} cat1={webniars} cat2={other1} cat3={other2}  heading={pageTitle} type={pageType} />
            <Footer/>
            
        </>
    );
}

export default LearningResources;