import React from 'react'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; 
import rehypeRaw from 'rehype-raw';



const PageDetails = (props) => {
   return (
        <>
            <div className="talk-detail-items">
                <div className="detail-section"
                     style={{backgroundImage: `url(${'.../../../../images/Icons/courses2.png'})`}}>
                    <div className="titlebox-meta">
                        <div className="container">
                            <div className="meta-detail">
                                <h2>{props.PageDetail.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-descrption"> 
                    <div className="container">
                    <ReactMarkdown children={props.PageDetail.description} rehypePlugins={[rehypeRaw]} ></ReactMarkdown>
                       
                    </div>

                </div>
            </div>
        </>
    );

}

export default PageDetails;