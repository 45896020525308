
export default class UrlResolver {
    static generateCourseUrl(id){
        return `/coursedetail/${id}`
    }

    static generateMediaUrl(path) {
       return `https://backend.aspire-online.itmatic.io${path}`;

        //return `http://localhost:1337${path}`;
    }
}
