import React, { useEffect } from 'react';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import CertificateItems from './CertificateItems';
import CertificatesBredcrumbs from './CertificatesBredcrumbs';
import ApiService from '../../../../helpers/ApiService';


const MyCertificates = (props) => {

    let api = new ApiService();

    const [certificate,setCertificates] = React.useState(null);

    useEffect(()=>{
        loadCerficates();
    },[])

    const loadCerficates = ()=>{

        api.getMyCertificates().then(_certs => {
            setCertificates(_certs);
          }).catch((e) => {
      
          });

    }
    if(!certificate) {
        return <></>
    }
    return (
        <>
            <MainHeader/>
            <CertificatesBredcrumbs/>
            <CertificateItems certificate={certificate} onUploaded={loadCerficates}/>
            <Footer/>
        </>
    );

}

export default MyCertificates;