import React from 'react';
import {Link} from "react-router-dom";

const SimpleButton =(props)=> {
    
        return (
            <Link className="defalut-button-with-icon" onClick={props.onClick} to={props.link}>
                {props.name}
                <img width="20" src={props.icon} alt="icon"/>
            </Link>
        );

}

export default SimpleButton;