import React, { useState } from 'react';
import { Link } from "react-router-dom";

const CheckboxInput = (props) => {
    const [open, setOpen] = useState(false);
    const [showcheckbox, setShowcheckbox] = useState(false);
    return (
        <div className="radio-checkbox">
            <div  onClick={() => setShowcheckbox(!showcheckbox)}>
                <img className={showcheckbox ? "select-checkboxhide" : ""}  src="../../../../images/Icons/checkbox.svg" />
            </div>
            <div onClick={() => setShowcheckbox(!showcheckbox)} >
                <img className={showcheckbox ? "select-checkboxshow" : "select-checkboxhide"} src="../../../../images/Icons/check-click.png" />
            </div>
            <span>{props.radiolabel} <Link className="term-color" to="/">{props.termtext}</Link></span>
        </div>
    );

}

export default CheckboxInput;