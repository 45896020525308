import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const PreviousTable = (props) => {

    return (
        <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Courses</th>
                        <th>Duration</th>
                        <th>When will I complete <br /> this course</th>
                        <th>GDC development <br /> outcomes</th>
                        <th>
                            <div className="reflection-heading">
                                <span>Complete <br /> reflections now</span>
                                <p>(Can also be completed after the <br /> course)</p>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-left  pl-4" >Disinfection and Decontamination</td>
                        <td>2:00 Hours</td>
                        <td>11/12/2020</td>
                        <td>A</td>
                        <td><Link to="/coursereflection">Reflection</Link></td>
                    </tr>
                    <tr>
                        <td className="text-left  pl-4">Radiation Protection</td>
                        <td>2:00 Hours</td>
                        <td>11/12/2020</td>
                        <td>B</td>
                        <td><Link to="/coursereflection">Reflection</Link></td>
                    </tr>
                    <tr>
                        <td className="text-left pl-4">Medical Emergency</td>
                        <td>2:00 Hours</td>
                        <td>11/12/2020</td>
                        <td>C</td>
                        <td><Link to="/coursereflection">Reflection</Link></td>
                    </tr>
                </tbody>
            </table>

        </div>
    );
}

export default PreviousTable;
