import React from 'react';
import DefaultHeading from "../../../../../components/Heading/DefaultHeading";
import TalkDentalBoxes from "./TalkDentalBoxes";
import TalkDentalNews from "./TalkDentalNews";

const TalkDentalItems = (props) => {
    if(!props.foundationYears) {
        return <></>
    }
    return (
        <>
            <div className="talkdental-items">
                <div className="container">
                    <DefaultHeading heading={props.heading} />
                    <TalkDentalBoxes talkDentals={props.foundationYears.TalkDental} type={props.type}/>
                    {/* <TalkDentalNews talkDentals={props.foundationYears.TalkDental} type={props.type}/> */}
                </div>
            </div>
        </>
    );

}

export default TalkDentalItems;