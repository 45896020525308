import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeading from '../../../../components/Heading/DefaultHeading';
import SelectInput from '../../../../components/Input/SelectInput';
import Gdc5YearContentList from './Gdc5YearContentList';

const Gdc5YearItems = (props) => {

    const Years = [
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
    ]

    return (
        <div className="foudation-years">
            <div className="container">
                <DefaultHeading heading="GDC 5-year Cycle Statement" />
                <div className="gdc-head">
                    <div className="gdc-year">
                        <span>Year -</span>
                        <SelectInput
                            placeholder="2020"
                            isSearchable={false}
                            keepValue={true}
                            options={Years}
                            name="year"

                        />
                    </div>
                    <Link to="pdphub">PDP Hub</Link>
                </div>
                <div className="annual-statement-content">
                    <Gdc5YearContentList/>
                </div>
                <div className="annual-gdc-download">
                    <Link  to="">
                        <span>Download Annual GDC Statement</span>
                        <img src="../../../../images/Icons/annual-icon.svg" />
                    </Link>
                </div>
                <div className="gdc-note-line">
                    <span>For more information, Please visit the following link : </span>
                    <a href="http:/www.egdc-uk.org/" >http:/www.egdc-uk.org/</a>
                </div>
            </div>
        </div>
    );
}

export default Gdc5YearItems;
