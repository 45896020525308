import React, { useState } from 'react';
import SimpleInput from "../Input/SimpleInput";
import {Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import SimpleButton from '../Buttons/SimpleButton';
import ApiService from '../../../helpers/ApiService';


const UploadModal = (props)=> {

    let api =  new ApiService();

        //DatePicker
        const [startDate, setStartDate] = useState();

        const [fileData, setFileData] = useState(null);

        const [input, setInput] = useState({});
        const [errors, setErrors] = useState({});
        const [loading, setLoading] = useState(false);

        const handleChange = (event) => {
            let i = {...input};
            i[event.target.name] = event.target.value;
            setInput(i);
        }

        const handleFileChange = (event) => {
            //console.log(event.target.files);
            setFileData(event.target.files[0]);
         }

        const handleSubmit = async (event) => {
            event.preventDefault();
            if (validate()) {
                setLoading(true);
    
                const birthDate = startDate.toISOString().slice(0, 10);

                const dataFile = new FormData();

                 dataFile.append('files', fileData);

                api.uploadFileCertificate(dataFile).then(response => {
                     
                    const docFileId = response[0].id;

                        var data = {
                            course: input.couName,
                            issueDate: birthDate,
                            source: input.traingProvider,
                            type:'external',
                            document:docFileId
                        }

                        //console.log(data);

                        api.uploadCertificate(data)
                        .then(response => {
                            alert("uploaded");
                            setLoading(false);
                            props.close();
                            if(props.onUploaded){
                                props.onUploaded();
                            }
                            
                          }).catch(errors => {
                              //console.log(errors);
                              setErrors({error: errors.message[0].messages[0].message});
                                setLoading(false);
                            })
                  }).catch(errors => {

                      //console.log(errors.data.errors[0].message);
                      setErrors({error: errors.data.errors[0].message});
                    });
                }
        }
    
        const validate = () => {
            let errors = {};
            let isValid = true;
            if (!input["couName"]) {
                isValid = false;
                errors["couName"] = "Please enter course name";
            }
    
    
            if (!input["traingProvider"]) {
                isValid = false;
                errors["traingProvider"] = "Please enter training provider";
            }

            if(fileData === null){
                isValid = false;
                errors["doc"] = "Please Select Document First";
            }
    
            
    
            setErrors(errors);
            return isValid;
        }

        return (
            <div className="dark">
                <div className="card-modal-Header">
                    <div className="pay-card">
                        <span>Upload Certificates</span>
                    </div>
                    <div className="cross-photo" onClick={props.close}>
                        <img src="../../../../images/Icons/closs-icon.svg"/>
                    </div>
                </div>               
                <Form onSubmit={handleSubmit}>
                       <SimpleInput
                            className={errors.couName ? "form-control error" : "form-control"}
                            type="text"
                            name="couName"
                            placeholder="Course name"
                            value={input.couName}
                            onChange={handleChange}
                        />
                         <SimpleInput
                            className={errors.traingProvider ? "form-control error" : "form-control"}
                            type="text"
                            name="traingProvider"
                            placeholder="Training Provider"
                            value={input.traingProvider}
                            onChange={handleChange}
                        />
                    <Form.Group controlId="formBasicEmail" className="simple-input">
                       <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                placeholderText="Select Issue Date"
                                className="form-control"
                                name="dob"
                            />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="simple-input">
                       <input type="file" className={errors.doc ? "form-control error" : "form-control"} name="doc" onChange={handleFileChange} placeholder="Upload Document"
                              style={{padding:0, }} />
                    </Form.Group>

                    {/* <Link onClick={props.showNext} className="defaultButton">Upload</Link> */}
                    <div className="button-col">
                            <SimpleButton
                                name="Upload"
                                onClick={handleSubmit}
                                loading={loading}
                            />
                        </div>
                        { errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
                </Form>
            </div>
        );
}

export default UploadModal;