import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import MainRoute from "./views/components/MainRoute";
import AuthRoute from "./views/components/AuthRoute";
import {ThemeProvider} from "styled-components";
import {GlobalStyles} from "./components/GlobalStyles";
import {darkTheme, lightTheme} from "./components/Themes"
import {connect} from "react-redux";


const App = ({theme}) => {
    return (

        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <GlobalStyles/>
            <BrowserRouter>
                <Switch>
                    <AuthRoute path="/register" name="register"/>
                    <MainRoute path="/" name="home"/>
                </Switch>
            </BrowserRouter>

        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme.theme,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {//You should put here your dispatch methods later
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
