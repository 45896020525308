import React, { useEffect, useState } from 'react';
import {Form, Modal} from "react-bootstrap";
import {Link, useHistory, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import InputWithIcon from '../../../../components/Input/InputWithIcon';
import SimpleInput from '../../../../components/Input/SimpleInput';
import SimpleButton from '../../../../components/Buttons/SimpleButton';
import ApiService from '../../../../../helpers/ApiService';
import SelectInput from '../../../../components/Input/SelectInput';


const MentorRegister = (props) => {  

    let api = new ApiService();

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

   //console.log(goToPreviousPath);

    const eye = <FontAwesomeIcon icon={faEye}/>;

   // Tooltip Show
    const [showTooltip, setShowtooltip] = useState(false);

    

    const [input, setInput] = useState({mentorSpeciality: '__select__'});
    const [errors, setErrors] = useState({});
    const [failed, setFailed] = useState({});
    const [loading, setLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const [subcrib, setSubcrib] = useState(false);

    const mentorSpeciality = [
        {value: '__select__', label: 'Mentor Speciality', isDisabled: true, },
        {value: 'Restorative', label: 'Restorative'},
        {value: 'Endodontics', label: 'Endodontics'},
        {value: 'Periodontics', label: 'Periodontics'},
        {value: 'Prosthodontics', label: 'Prosthodontics'},
        {value: 'Peadratric', label: 'Peadratric'},
        {value: 'Implants', label: 'Implants'},
    ]

    const handleChange = (event) => {
        let i = {...input};
        i[event.target.name] = event.target.value;
        setInput(i);
    }

        useEffect(() => {
            if(props.user){
                //console.log(props.user);
              setInput({ ...props.user.data }); 
            }
        }, [props.user]) 

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);
            
            var data = {
                mentorSpeciality: input.mentorSpeciality,
                homeAddress: input.homeAddress,
                mentorNote: input.mentorNote,
                status: true
            }

            api.mentorRequest(data)
                .then(response => {
                    //alert("updated");
                    setLoading(false);
                    alert("Send Request");
                    window.location.href = "/mentorship";
                }).catch(errors => {
                    //console.log(errors);
                    setErrors({ error: errors.message[0].messages[0].message });
                    setLoading(false);
                })

        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;
       

        if (!input["mentorSpeciality"]) {
            isValid = false;
            errors["mentorSpeciality"] = "Please Select Role first";
        }

        setErrors(errors);
        return isValid;
    }

    let jwt = localStorage.getItem('jwt');

    if (!jwt) {
        return <Redirect to={{
            pathname: "/register",
            state: { url: "/becomementor" },
          }}
           />
    }

    return (
        <div className="register-box">
            <div className="register-left"
                 style={{backgroundImage: `url(${'.../../../images/Icons/register-back.svg'})`}}>
                <h3>Aspire <br/> Online</h3>
                <span>Mentorship Request</span>

            </div>
            <div className="register-right">
                <div className="signheading-box">
                    <Link to="/" className="registration-arrow">
                        <img src="../../../../images/Icons/left-arrow.svg"/>
                        <span>REQUEST FOR MENTORSHIP ACCOUNT</span>
                        {/* <span className="validation-error">{errors.msg}</span> */}

                    </Link>
                </div>
                <Form onSubmit={handleSubmit}>
                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.Fname ? "form-control error" : "form-control"}
                            type="text"
                            placeholder="Name"
                            name="Fname"
                            value={input.fullName}
                            onChange={handleChange}
                            errors={errors.Fname}
                            disabled="disabled"
                        />
                    </div>
                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.email ? "form-control error" : "form-control"}
                            type="email"
                            name="email"
                            placeholder="Enter Email"
                            value={input.email}
                            onChange={handleChange}
                            errors={errors.email}
                            disabled="disabled"
                        />
                    </div>
                    
                    
                    <div className="col-md-6">
                        <InputWithIcon
                            className="form-control"
                            type="text"
                            placeholder="GDC number"
                            icon="../../../../images/Icons/tooltip.svg"
                            onClick={() => setShowtooltip(!showTooltip)}
                            name="gdcnumber"
                            value={input.gdcNumber}
                            onChange={handleChange}
                            errors={errors.gdcnumber}
                            disabled="disabled"
                        />
                        {/*onClick Tooltip Show   */}
                        <div className={showTooltip ? "subscribe-toolshow for-register" : "subscribe-toolhide"}>
                            <p>Required to complete PDP. Can continue without this, but cannot produce a PDP without it.</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.homeAddress ? "form-control error" : "form-control"}
                            type="textarea"
                            name="homeAddress"
                            placeholder="Home Address"
                            value={input.homeAddress}
                            onChange={handleChange}
                            errors={errors.homeAddress}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="simple-input form-group">
                            <SelectInput
                                placeholder="Mentor Speciality"
                                isSearchable={false}
                                keepValue={true}
                                value={input.mentorSpeciality}
                                options={mentorSpeciality}
                                error={errors.mentorSpeciality}
                                name="mentorSpeciality"
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <SimpleInput
                            className={errors.mentorNote ? "form-control error" : "form-control"}
                            type="text"
                            name="mentorNote"
                            placeholder="Mentor Notes"
                            value={input.mentorNote}
                            onChange={handleChange}
                            errors={errors.mentorNote}
                        />
                    </div>

                    


                    <div className="col-md-12 buttons-box " style={{float:'left' , marginTop:0}}>
                        { errors.error && <span className="validation-error donthave-acc text-center mt-3"><h5>{errors.error}</h5></span>}
                        <div className="button-col">
                            <SimpleButton
                                name="Request"
                                onClick={handleSubmit}
                                loading={loading}
                            />
                        </div>
                    </div>
                </Form>
            </div>

         </div>
    );
}

function mapStateToProps(state) {
    return {user:state.user}
}



export default connect(mapStateToProps)(MentorRegister);

