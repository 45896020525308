import React from 'react';
import MainHeader from "../../components/Header/MainHeader";
import Footer from "../../components/Footer/Footer";
import MentorshipBreadcrumbs from './Components/MentorshipBreadcrumbs';
import MentorshipItems from './Components/MentorshipItems';
import { connect } from "react-redux";


const Mentorship =(props)=> {
        
        return (
            <>
                {/* Header */}
                <MainHeader />
                <MentorshipBreadcrumbs hubname="Mentorship" />

                {/* Body */}
                <MentorshipItems  user={ props.user }/>              

                {/* Footer */}
                <Footer/>
            </>
        );
}

function mapStateToProps(state) {
    return {user:state.user}
}



export default connect(mapStateToProps)(Mentorship);
