import React, { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';

const CourseListItems = (props) => {
    return (
        <>
            <div className="courseList-Section">
                <div className="container">
                    <div className="courses-cat-sub">
                        <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                            <Tab label=" GDC Recommended CPD">
                                <div className="course-list">
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Regulatory CPD ">
                                <div className="course-list">
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab label="Health and Safety CPD ">
                                <div className="course-list">
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="courses-slider" >
                                            <img src="../../../../images/Icons/course4.png" alt='' />
                                            <div className="course-overlay" >
                                                <div className="showAfterhover">
                                                    <div className="coursevideo-back">
                                                        <img src="../../../../images/Icons/coursevideo.png" />
                                                    </div>
                                                    <div className="slider-desc">
                                                        <h4>Medical Emergencies</h4>
                                                        <span>5 lessons</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-cat-name">
                                                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>

                </div>
            </div>
        </>
    );

}

export default CourseListItems;
