import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


class RandomSlider extends Component {
    state= {
        responsive:{
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 1,
            },
        },
    };

    render() {
       
        return (
            <div className="float-left w-100 mt-4">
                <OwlCarousel ref={this.sliderRef}
                    className="owl-theme"
                    items={1}
                    autoplay={true}
                    dots={false}
                    loop={true}
                    margin={0}
                    nav={false}                   
                    autoplayHoverPause={true}
                    autoFocus={true}
                    autoplaySpeed={2000}
                    animateIn="linear"
                    smartSpeed={300}
                    responsive={this.state.responsive}
                >
                     <div className="item">
                        <div className="courses-slider" >
                            <img src='../../../../images/Icons/Sliderleft.jpg' alt="" />                                                      
                        </div>                       
                    </div>
                    <div className="item">
                        <div className="courses-slider" >
                            <img src='../../../../images/Icons/Sliderright.jpg' alt="" />                                                      
                        </div>                       
                    </div>
                </OwlCarousel>
            </div>
        );
    }
}

export default RandomSlider;