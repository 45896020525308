import React from 'react';
import { Link } from "react-router-dom";

const PaymentTable = (props) => {

    if(!props.payment){
        return <></>  
      }  else {
          console.log(props.payment);
      }
    return (
        <>
            <div className="responsive-payment-table">
                <table className="table responsive">
                    <thead>
                        <tr>
                            <th style={{minWidth:40,}}></th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th style={{minWidth:320}}>Description</th>
                            <th>Receipt</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.payment.map((item, index) => {

                        let d = new Date(item.updated_at);

                         let sdated = d.toISOString().slice(0, 10);
                        return <tr>
                            <td className="red-color" style={{minWidth:40,}}>{index + 1}</td>
                            <td>{sdated}</td>
                            <td >
                                <div className="price-currency">
                                    <span>£</span>
                                    <p>{item.amount}</p>
                                </div>
                            </td>
                            <td style={{minWidth:320}}>{item.subscription != "null" ? 
                                  `${item.subscription == 2 ? "CPD Only Subscription" : "" } ${item.subscription == 3 ? "Silver Subscription" : "" } ${item.subscription == 4 ? "Gold Subscription" : "" }` 
                                   : `Course ${item.course}` }</td>
                            
                            <td><Link>View</Link></td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default PaymentTable;