import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import ExternalPdpBreadcrumbs from './components/ExternalPdpBreadcrumbs';
import ExternalPdpItems from './components/ExternalPdpItems';



const ExternalPdpCourse =(props)=> {
    
    return (
        <>
            <MainHeader/>
            <ExternalPdpBreadcrumbs/>
            <ExternalPdpItems/>
            <Footer/>
        </>
    );

}

export default ExternalPdpCourse;