import React, { useEffect } from 'react';
import LeftJournalSection from './LeftJournalSection';
import PaperReview from './PaperReview';
import RightJournalSection from './RightJournalSection';


const JournalClubCategoryItems = (props) => {
   if(!props.journals) {
        return <></>
    } else {
        if(!props.paperReview) {
            return <></>
        } 
    }

    if(!props.jpaper) {
        return <></>
    }
    
    return (
        <div className="journal-club">
            <div className="container">
                <div className="course-detail-info">
                    <LeftJournalSection journals={props.journals}  video={props.video}/>
                    <RightJournalSection read={props.journals.journalread} user={props.user } onhandles={props.onhandle} activeId={props.activeId}/>
                </div>
                <div className="paper-review-section">
                    <div className="paper-filter">
                        <h4>Paper reviews</h4>

                        <select name="jourpapers" id="cajourpapersrs" onChange={props.onpaperhandle}>
                            <option value="all">All</option>
                            {props.paperReview.map(papers => {
                                return <option value={papers}>{papers}</option>
                            })}
                        </select>
                        
                    </div>
                    <PaperReview papers={props.jpaper}/>
                </div>
            </div>
        </div>
    );
}

export default JournalClubCategoryItems;