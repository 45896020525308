import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ApiService from '../../../../helpers/ApiService';

const VerticalTabs =(props)=> {

    const [categories,setCategories] = React.useState([]); 
    const [courses,setCourses] = React.useState([]);

    useEffect(async ()=>{

        let api = new ApiService();

        api.getCategories().then(_cats => {
            let sortCat =   _cats.sort((a, b) => a.verticalPosition - b.verticalPosition);
            setCategories(sortCat.filter(cat=>{
                return cat.parent === null && cat.verticalPosition != 0;
            }));
         }).catch((e) => {
     
         });

         api.getCourses().then(_courses => {
            setCourses(_courses);
          }).catch((e) => {
      
          });

    },[]);

    let [activeCategory, setActiveCategory] = useState(null);

        return (
            <>
                <div className="vertical-tab-section">
                    <div className="container">
                        <div className="category-tabs">
                            <div className="category-verticaltabs" >
                                <div className="all-cat-text">All Categories</div>
                                <div className="left-categories">
                                    <ul>
                                        {categories.map((category, index) => {
                                            
                                            return <li className="active" onMouseOver={() => {setActiveCategory(category);}} key={index}>

                                            {category.name == 'Aspire Journal Club' ? <Link to="/journalcategory" className={`${activeCategory && activeCategory.id === category.id ? "active" : ""}`}>{category.name}</Link> 
                                              : category.name  == 'Aspire Students' ? <Link to="/students/2" className={`${activeCategory && activeCategory.id === category.id ? "active" : ""}`}>{category.name}</Link>
                                               : category.name == 'Aspire Foundation' ? <Link to="foudationyears/1" className={`${activeCategory && activeCategory.id === category.id ? "active" : ""}`}>{category.name}</Link>
                                               : category.name == 'Aspire Live' ? <Link to="#" className="">{category.name}</Link>
                                               : category.name == 'Aspire News' ? <Link to="/aspirenews" className={`${activeCategory && activeCategory.id === category.id ? "active" : ""}`}>{category.name}</Link>
                                               : category.name == 'Aspire Mentorship' ? <Link to="/mentorship" className="">{category.name}</Link>
                                               : <Link to={category.id} className={`${activeCategory && activeCategory.id === category.id ? "active" : ""}`} key={index}>{category.name}</Link> 
                                               }

                                            </li>
                                        })}
                                        
                                    </ul>
                                </div>
                                <div className="right-categories-list">
                                    <ul>
                                        {courses.map((course, index) => {
                                            return <li>
                                                <Link to={`/coursedetail/${course.id}`} class={`${activeCategory && activeCategory.id === course.course_category.id ? "active" : ""}`} key={index} >{course.Name}</Link>
                                            </li>
                                        })}
                                    </ul>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        );
}

export default VerticalTabs;
