import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import MainHeader from "../../../../components/Header/MainHeader";
import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";

import Footer from "../../../../components/Footer/Footer";
import EventsItems from "./components/EventsItems";
import ApiService from '../../../../../helpers/ApiService';




const Events =(props)=> {
    const [foundationYears, setFoundationYears] = React.useState([]);
    const [pageTitle, setPageTitle] = React.useState('');
    let { id } = useParams();

    useEffect(async () => {
         let api = new ApiService();
       

        if(id == 'foundations'){

            setPageTitle('Foundations Events');

            api.getFoundationYears().then(_foundations => {
                setFoundationYears(_foundations.Events);
                //console.log(_foundations);
             }).catch((e) => {
         
             });
        } else if(id == 'students'){
            setPageTitle('Students Events');

            api.getAspireStudents().then(_foundations => {
                setFoundationYears(_foundations.Events);
                //console.log(_foundations);
             }).catch((e) => {
         
             });
        } else {
            return <></>
        }

        
        }, [])
        
        if(!foundationYears) {
            return <></>
        }

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename={pageTitle} /> 
            <EventsItems EventsItem={foundationYears}  heading={pageTitle}/>
            <Footer/>

        </>
    );

}

export default Events;