import React, { useState } from 'react';
import MessagesConversation from './MessagesConversation';
import { Modal } from "react-bootstrap";
import ScheduleModal from '../../../../../../components/Modals/ScheduleModal';


const ConversationRightCol = (props) => {

    const [selectedPatient, setSelectedPatient] = React.useState({ patient: "" });
    const [selectedslot, setSelectedSlot] = React.useState(null); 

    // Schedule Modal
    const [scheduleModal, setScheduleModal] = useState(false);
    const scheduleModalClose = () => setScheduleModal(false);
    const scheduleModalShow = (patient) => {
         setSelectedPatient(patient);
         setScheduleModal(true);
       };


       if(!props.patient){
          return <></>;
        } else {
            console.log(props.patient);
            console.log(props.user);
        }

        if(!props.user){
            return <></>  
          }  

    return (
        <div className='patient-chat'>
            <div className='chat-head'>
                <span>Chat</span>
                {props.patient.status !== 'Archived' && props.user.data.id === props.patient.user &&
                <button onClick={(e) =>{ 
                     e.preventDefault()
                    scheduleModalShow(`${props.patient.id}`)}}
                    >
                    <img src='../../../../../../images/Icons/chat-phone.svg' alt='PhoneIcon' /> 
                    <span>Schedule a Call</span>
                </button>
                }
            </div>

            {/* Chat box */}
            <div className='chat-box'>
                <MessagesConversation />
            </div>

            {/* Typing Box */}
            {props.patient.status !== 'Archived' &&
            <div className='input-tool'>
                <input type='text' placeholder='Type here.........' />
                <button>Send</button>
            </div>
            }


            {/*  Schedule Modal */}
            <Modal show={scheduleModal} onHide={scheduleModalClose} 
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="authModal cardModal Login-modals schedule"
            >
                <ScheduleModal patientId={selectedPatient} slotid={selectedslot} close={scheduleModalClose} />
            </Modal>
        </div>
    );
}

export default ConversationRightCol;
