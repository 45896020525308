import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeading from '../../../../components/Heading/DefaultHeading';
import { Tabs, Tab, } from 'react-bootstrap-tabs';
import ExternalPdpTable from './ExternalPdpTable';

const ExternalPdpItems = (props) => {

    return (
        <div className="foudation-years">
            <div className="container">
                <DefaultHeading heading="Add External PDP Courses" />
                <div className="choose-courses-tabs">
                    <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                        <Tab label="eCPD">
                            <div className="">
                                <ExternalPdpTable/>
                            </div>
                        </Tab>
                        <Tab label="Dental">
                        </Tab>
                        <Tab label="Sheild">
                        </Tab>
                        <Tab label="Journal Club">
                        </Tab>
                        <Tab label="Finance">
                        </Tab>
                    </Tabs>
                </div>
                <div className="personal-button-group">
                    <Link to="/pdphub" className="practice-back">Back </Link>
                    <Link className="next-plan" to="/pdphub">Done</Link>
                </div>
            </div>
        </div>
    );
}

export default ExternalPdpItems;
