import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import { Form } from "react-bootstrap";
import SimpleButton from '../../../components/Buttons/SimpleButton';
import QuizSubmitModal from '../../../components/Modals/QuizSubmitModal';
import Radio from '@material-ui/core/Radio';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import ApiService from '../../../../helpers/ApiService';
import { connect } from "react-redux";
import QuizFailed from '../QuizFailed.';

const QuizQuestions = (props) => {

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [peopleInfo, setPeopleInfo] = useState({});



    // Submit Quiz
    const [quizModal, setquizModal] = useState(false);
    const quizModalClose = () => {
         setquizModal(false);
         setLoading(false);
    }
    const quizModalShow = () => setquizModal(true);

    const [state, setState] = React.useState({
        checkedC: true,
    });

    const handleChange = (event) => {
        let i = { ...input };
        i[event.target.name] = event.target.value;
        setInput(i);
        console.log(i);
    }

    const checkChange = (event) => {
        let a = { ...peopleInfo };

        if (event.target.checked) {
            a[event.target.name] = event.target.value;
            setInput(a);

        } else {

        }

        var isChecked = event.target.checked;
        var item = event.target.value;
        let i = { ...input };
        const value =
        event.target.type === "checkbox" ? event.target.checked : event.target.value;
        i[event.target.name] = value;
        setInput(i);
        console.log(i);

    }

    let api =  new ApiService();

    const quizModalFeedback = () => {
        setquizModal(false);
        
        let data = {
            results :input,
            course: props.courseId
         }

         api.setquiz(data)
         .then(response => {

            //console.log(response);

            setLoading(false);

            if(response.percentage > 84){
                setLoading(false);
                window.location.href = "/feedback/"+props.courseId+"/"+response.id;
            } else {

                window.location.href = "/quizfailed/"+props.courseId+"/"+response.id;

            }

            }).catch(errors => {
              setErrors({error: errors.message[0].messages[0].message});
           }).finally(()=>{
              setLoading(false);
          });

        

    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);
            //console.log(input);
            setquizModal(true);
           
        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;
        props.Quizs.map((quiz, index)=>{
            console.log(input[`answer${index+1}`])
           if(input[`answer${index+1}`] === undefined){
               isValid = false;
               errors['msg'] = "Please answer all questions";
           }
        });

        setErrors(errors);
        return isValid;
    }

     return (
        <>
        <Form onSubmit={handleSubmit}>
        {props.Quizs.map((quiz , index) => {

            return <div className="question-box">
                <div className="question-text">
                    <span>Q.{index + 1}</span>
                    <p>{quiz.Question}</p>
                </div>
                <div className="question-options">
                {quiz.QuizType === 'radio' ?
                    <FormControl component="fieldset">
                        <RadioGroup aria-label={`answer${index + 1}`} name={`answer${index + 1}`} value={input[`answer${index + 1}`]} onChange={handleChange}>
                        {quiz.Answers.map((quizans,ind)  => {
                            return <FormControlLabel value={ind} control={<Radio checked={input[`answer${index + 1}`] == ind}/>} label={quizans.Answer} />
                        })}
                        </RadioGroup>
                    </FormControl>
                :

                <FormControl component="fieldset" >
                    <FormGroup>
                    {quiz.Answers.map((quizans,ind) => {
                    return <FormControlLabel
                        control={<Checkbox  onChange={checkChange} name={`answer${index + 1}`} value={ind} />}
                        label={quizans.Answer}
                    />

                     })}
                    </FormGroup>
                </FormControl>

                }
            </div>
            </div>
        })}

        <div className="quiz-submit">
                        {/* <SimpleButton
                            name="Submit"
                            onClick={quizModalShow}
                        /> */}
                        <span className="validation-error">{errors.msg}</span>

                        <SimpleButton
                                name="Submit"
                                onClick={handleSubmit}
                                loading={loading}
                            />
                    </div>


                    {/* Quiz Modal */}
            <Modal show={quizModal} onHide={quizModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="authModal cardModal emailverification-modal"
            >
                <QuizSubmitModal close={quizModalClose} no={quizModalClose} yes={quizModalFeedback} />
            </Modal>
            </Form>
        </>
    );
}


function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(QuizQuestions);
