import React from 'react';


const MessagesConversation = (props) => {

    return (
        <div className='messages-conversation'>

            {/* Messages Reply body */}
            <div className='reply-body'>
                <div className='user-reply-info'>
                    <h5>Mentor</h5>
                    <span>08:34 pm</span>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
            </div>

            {/* Messages Sent body */}
            <div className='sent-body'>
                <div className='sent-display'>
                    <div className='user-sent-info'>
                        <h5>Mentor</h5>
                        <span>08:34 pm</span>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                </div>
            </div>
            {/* Messages Reply body */}
            <div className='reply-body'>
                <div className='user-reply-info'>
                    <h5>Mentor</h5>
                    <span>08:34 pm</span>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
            </div>

            {/* Messages Sent body */}
            <div className='sent-body'>
                <div className='sent-display'>
                    <div className='user-sent-info'>
                        <h5>Mentor</h5>
                        <span>08:34 pm</span>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                </div>
            </div>
            {/* Messages Reply body */}
            <div className='reply-body'>
                <div className='user-reply-info'>
                    <h5>Mentor</h5>
                    <span>08:34 pm</span>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
            </div>

            {/* Messages Sent body */}
            <div className='sent-body'>
                <div className='sent-display'>
                    <div className='user-sent-info'>
                        <h5>Mentor</h5>
                        <span>08:34 pm</span>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                </div>
            </div>
        </div>
    );
}

export default MessagesConversation;
