import * as Actions from '../actions/user';

const initialState = null;
export const userReducer = (state = initialState, action) => {
    //console.log(action);
    switch (action.type) {
        case Actions.UPDATTE_USER:
            return Object.assign({}, { ...action.payload } );
        default:
            return state;
    }
};
