import React, {useState } from 'react';
import { Link } from 'react-router-dom';


const ArchivedTable = (props) => {

    let userpatients = [];
    if(props.patients){
         
        let objects = props.patients;

        //console.log(objects);

        if(objects.length === 0){
            userpatients = [];
        } else {
            userpatients = objects;

            //console.log(userpatients);
        }
      }

    return (
        <div className="pdp-table">
            <table className="table responsive">
                <thead>
                    <tr>
                        <th>Patients</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {userpatients.map(userpatient => {
                    let d = new Date(userpatient.created_at);
                    let sdated = d.toISOString().slice(0, 10); 
                    if(userpatient.status === 'Archived'){
                    return <tr>
                        <td className="text-left  pl-4" >
                            <div className='patient-detail'>
                                <img src='../../../../../../images/Icons/mentor-pic.svg' alt='ProfilePic' />
                                <span>{userpatient.patientName}</span>
                            </div>
                        </td>
                        <td>{sdated}</td>
                        <td>{userpatient.status}</td>
                        <td>
                            <div className='mentor-actions justify-content-center'>
                             {userpatient.paymentStatus === 'Done' &&
                                <div className='view-conversation'>
                                    <Link to={`/viewconversation/${userpatient.id}`}>View Conversation</Link>
                                </div>
                                }
                            </div>
                            
                            </td>
                    </tr>
                    }
                    })}
                </tbody>
            </table>

        </div>
    );
}

export default ArchivedTable;
