import React from 'react';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import MentorshipBreadcrumbs from '../Components/MentorshipBreadcrumbs';
import MentorItems from './components/MentorItems';
import { connect } from "react-redux";


const MentorDashboard =(props)=> {
  
   return (
            <>
                {/* Header */}
                <MainHeader/>

                {/* Body */}
                <MentorshipBreadcrumbs hubname="Mentor Dashboard"/>
                <MentorItems  user={ props.user }/>

                {/* Footer */} 
                <Footer/>
            </>
        );
}

function mapStateToProps(state) {
    return {user:state.user}
}

export default connect(mapStateToProps)(MentorDashboard);
