import React, {useState, useEffect} from 'react';
import ApiService from '../../../../helpers/ApiService';
import Footer from '../../../components/Footer/Footer';
import MainHeader from '../../../components/Header/MainHeader';
import CategoryBreadcrumbs from '../components/CategoryBreadcrumbs';
import JournalClubCategoryItems from './components/JournalClubCategoryItems';
import { connect } from "react-redux";

const JournalClubCategory =(props)=> {
     
    const [journals, setJournals] = React.useState([]);

    const [joupapers, setJouPapers] = React.useState([]);

    const [filterBy, setFilterBy] = useState({ category: "all" });

    const [jourVideoId, setJourVideoId] = useState('276707407');

    const [activeId, setActiveId] = useState('preview');

    const [catjour, setCatJour] = useState(null);

    const handleJourClick =  (value, actid) => {
        setJourVideoId(value);
        setActiveId(actid);
    }

    const changeHandler = (e) => {
        console.log(journals.journalpaper);
        updatePaperData(e.target.value);
        setFilterBy(() => ({category : e.target.value}))
        //console.log(filterBy)
      };

    useEffect(async () => {

        window.scrollTo(0, 0);

        let api = new ApiService();

        api.getJournalClub().then(_jours => {

            let newArr = [];
            _jours.journalpaper.map((item)=> {
            item.journal_categories.map((item2)=> {
                if(newArr.indexOf(item2.name)===-1){    newArr.push(item2.name)}
            })
            })
            //console.log(newArr);

            setCatJour(newArr)
            setJournals(_jours); 

            if (filterBy.category !== "all") {
                setJouPapers(_jours.journalpaper.filter(jpap => {
                    return jpap.journal_categories.length > 0 && jpap.journal_categories.find(o => o.name === filterBy.category);
    
                }));
                //filteredProducts = filteredProducts.filter((product) => product.tag === filterBy.category);
              } else {

                setJouPapers(_jours.journalpaper);
              }

            setJourVideoId('276707407');
         }).catch((e) => {
     
         });
        }, [])

        const updatePaperData = (sizeValue) => { 
            console.log(journals.journalpaper);
            if (sizeValue !== "all") {
                setJouPapers(journals.journalpaper.filter(jpap => {
                    return jpap.journal_categories.length > 0 && jpap.journal_categories.find(o => o.name === sizeValue);
    
                }));
                //filteredProducts = filteredProducts.filter((product) => product.tag === filterBy.category);
              } else {

                setJouPapers(journals.journalpaper);
              }

        };
    
        
        if(!journals) {
            return <></>
        } 

    return (
        <>
            <MainHeader/>
            <CategoryBreadcrumbs catename="Journal Club" />
            <JournalClubCategoryItems journals={journals} video={jourVideoId} user={props.user } onhandle={handleJourClick}  onpaperhandle={changeHandler} paperReview={catjour} jpaper={joupapers} activeId={activeId}/>
            <Footer/>
        </>
    );
}

function mapStateToProps(state) {
    return {user: state.user}
}

export default connect(mapStateToProps)(JournalClubCategory);