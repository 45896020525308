export const lightTheme = {
    name: 'light',
    body: '#FFF',
    heading:'#000000',
    text: '#000000',
    toggleBorder: '#FFF',
    background: '#ffffff',
    activehover:'#FF4D4D',
    catcolor:'#000000',
    borderColor:'#00000038',
    textColor:'#000000',
    dotsColor1:'#000000',
    dotsColor:'#000000',
    breadcrumbsColor:'#000000',
    headerdrop:'#ffffff',
    descrption:'#000000',
    buyButton:'#000000',
    beadcrumbsBorder: '#00000030',
    courseName: '#000000',
    courseNameActive:'#000000',
    detailButton:'#000000',
    buyButtonColor:'#ffffff85',
    tableBorder:' #000000',
    tabletabsback:'#fafafa',
    tabscolor:'#ffffff',
    profileInputBorder: '#000000',
    profiletablecolorheading:'#000000',
    profiletablecolorbody:'#000000',
    certificatebuttonback:'#000000',
    mentoreditback: '#000000',
}
export const darkTheme = {
    name: 'dark',
    body: '#000000',
    heading:'#ffffff',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#000000',
    activehover:'#FF4D4D',
    catcolor:'rgba(255, 255, 255, 0.5)',
    borderColor:'rgba(255, 255, 255, 0.2)',
    textColor:'rgba(255, 255, 255, 0.5)',
    dotsColor:'rgba(255, 255, 255, 0.2)',
    dotsColor1:'#ffffff',
    breadcrumbsColor:'#ffffff',
    headerdrop:'rgba(255, 255, 255, 0.5)',
    descrption:'rgba(255, 255, 255, 0.7)',
    buyButton:'rgba(255, 255, 255, 0.2)',
    buyButtonColor:'rgba(255, 255, 255, 0.5)',
    beadcrumbsBorder: 'rgba(255, 255, 255, 0.2)',
    courseName: 'rgba(255, 255, 255, 0.5)',
    courseNameActive:'#ffffff',
    detailButton:'#ffffff',
    tableBorder:' rgba(255, 255, 255, 0.6)',
    tabletabsback:'#000000',
    tabscolor:'rgba(18, 18, 18, 0.5)',
    profileInputBorder: 'rgba(255, 255, 255, 0.5)',
    profiletablecolorheading:'rgba(255, 255, 255, 0.7)',
    profiletablecolorbody:'#ffffff',
    certificatebuttonback:'rgba(255, 255, 255, 0.3)',
    mentoreditback: 'rgba(255, 255, 255, 0.3)',
}
