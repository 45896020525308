import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeading from '../../../components/Heading/DefaultHeading';
import MyCoursesTabs from './MyCoursesTabs';


const DashBoardItems = (props) => {

    return (
        <div className="foudation-years">
            <div className="container">
                <DefaultHeading heading="Personal Training Dashboard" />
                <div className="board-subheader">
                    <h4>CPD Achivement Progress</h4>
                    <Link to="/pdphub">PDP Hub</Link>
                </div>
                <div className="dasboard-my-courses">
                    <h4>My Courses</h4>
                    <MyCoursesTabs/>
                </div>
            </div>
        </div>
    );
}

export default DashBoardItems;
