import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DefaultHeading from "../../../components/Heading/DefaultHeading";
import ApiService from '../../../../helpers/ApiService';
import UrlResolver from '../../../../helpers/UrlResolver';

class MobileTestimonial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            testimonials: [],
            isLoading: false,
            error: null,
            responsive: {
                0: {
                    items: 1,
                },
                450: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                1000: {
                    items: 1,
                },
            },
        };
    }

    async componentDidMount() {
        this.setState({ isLoading: true });

        let api = new ApiService();

        try {

            api.getTestimonials().then(_tests => {
                this.setState({
                    testimonials: _tests,
                    isLoading: false
                });
             }).catch((e) => {
         
             });

        } catch (error) {
            this.setState({
                error,
                isLoading: false
            });
        }
    }



    render() {
        const { testimonials } = this.state;

        if (testimonials.length === 0) {
            return <></>
        }
        return (
            <div className="mobile-testimonial-section">
                <div className="container">
                    <DefaultHeading
                        heading="What students are saying"
                    />
                    <div className="testimonial-boxes">
                        <div className="float-left w-100 mt-4">
                            <OwlCarousel ref={this.sliderRef}
                                className="owl-theme"
                                items={1}
                                autoplay={true}
                                dots={false}
                                loop={true}
                                margin={10}
                                nav={false}
                                autoplayHoverPause={true}
                                autoFocus={true}
                                autoplaySpeed={2000}
                                animateIn="linear"
                                smartSpeed={300}
                                responsive={this.state.responsive}
                            >

                                {testimonials.map(testimonial => {
                                    return <div className="item" key={testimonial.id}>
                                        <div className="slider-testi d-flex flex-column  align-items-start">
                                            <div className="d-flex flex-row align-items-center designation">
                                                <img src={UrlResolver.generateMediaUrl(testimonial.profilePic.url)} alt={testimonial.profilePic.hash} />
                                                <div className="pl-3">
                                                    <span>{testimonial.name}</span>
                                                    <p>{testimonial.title}</p>
                                                </div>
                                            </div>
                                            <div className="clientdescritpion ">
                                                <p className="p-small">{testimonial.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileTestimonial;