import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const PdpHubBreadCrumbs = (props) => {

    return (
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="category-bredcrumbs">
                    <div className="breadcums-info">
                        <div className="breadcrumbs-arrow">
                            <Link to="">
                                <img src="../../../../images/Icons/bredcumes-back.png" />
                            </Link>
                        </div>
                        <div className="d-flex align-items-center breadcrums-titles">
                            <Link to="/">Home </Link>
                            <Link> / {props.hubname}</Link>
                        </div>
                    </div>
                    <div className="category-search">
                        <img src="../../../../images/Icons/search.svg" />
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
            </div>

             
        </div>
    );
}

export default PdpHubBreadCrumbs;
