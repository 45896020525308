import React from 'react';
import { Link } from 'react-router-dom';

const ChatBreadcrumbs = (props) => {

    if(!props.user){
        return <></>  
      }

    return (
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="category-bredcrumbs">
                    <div className="breadcums-info">
                        <div className="breadcrumbs-arrow">
                            <Link to="">
                                <img src="../../../../images/Icons/bredcumes-back.png" />
                            </Link>
                        </div>
                        <div className="d-flex align-items-center breadcrums-titles">
                            <Link to="/">Home </Link>
                            {props.user && props.user.data.mentor !== 'yes' &&
                                <Link to='/mentorshipdashboard'> / Mentorship Dashboard </Link>
                                }
                                {props.user && props.user.data.mentor === 'yes' &&
                                <Link to='/mentordashboard'> / Mentor Dashboard </Link>
                                }
                            <Link>  /  {props.hubname}</Link>
                        </div>
                    </div>
                    
                </div>
            </div>

             
        </div>
    );
}

export default ChatBreadcrumbs;
