import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import UrlResolver from '../../../../helpers/UrlResolver';


class CategorySlider extends Component {
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    render() {
       
        let {courses} = this.props;
        if(courses.length === 0) {
            return <></>
        }
        return (
            <div className="float-left w-100 mt-4 dots-style">
                <OwlCarousel ref={this.sliderRef}
                    className="owl-theme"
                    items={1}
                    autoplay={false}
                    dots={true}
                    loop={true}
                    margin={30}
                    nav={false}
                    autoplayHoverPause={true}
                    autoFocus={true}
                    stagePadding={20}
                    autoplaySpeed={2000}
                    animateIn="linear"
                    smartSpeed={300}
                    responsive={this.state.responsive}
                >
                {courses.map(course => {
                    if(!course.thumbnail){
                            return <></>;
                        }
                    return <div className="item">
                    <Link to={UrlResolver.generateCourseUrl(course.id)} >
                        <div className="courses-slider " >
                            <img src={UrlResolver.generateMediaUrl(course.thumbnail.url)} alt={course.thumbnail.hash} />
                            <div className="course-overlay" >
                                <div className="showAfterhover">
                                    <div className="coursevideo-back">
                                        <img src="../../../../images/Icons/coursevideo.png" />
                                    </div>
                                    <div className="slider-desc">
                                        <h4>{course.Name}</h4>
                                        {/* <span>5 lessons</span>  */}
                                    </div>
                                </div>
                            </div>
                            <div className="course-cat-name">
                            <p>{`${course.Description.substring(0, 140)}...`}</p></div>
                        </div>
                        </Link>
                    </div>
                })}
                </OwlCarousel>
            </div>
        );
    }
}

export default CategorySlider;
