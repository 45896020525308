import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, } from 'react-bootstrap-tabs';

const MyCoursesTabs = (props) => {

    return (
        <div className="my-courses-tabs">
            <div className="dasboard-tabs-main">
                <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                    <Tab label="Currently undertaking ">
                        <div className="my-courses-tabs-category">
                            <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                                <Tab label="All ">
                                    <div className="course-list">
                                        <div className="col-md-4">
                                            <div className="courses-slider" >
                                                <img src="../../../../images/Icons/course4.png" alt='' />
                                                <div className="course-overlay" >
                                                    <div className="showAfterhover">
                                                        <div className="coursevideo-back">
                                                            <img src="../../../../images/Icons/coursevideo.png" />
                                                        </div>
                                                        <div className="slider-desc">
                                                            <h3>Medical Emergencies</h3>
                                                            <span>5 lessons</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="course-cat-name">
                                                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="courses-slider" >
                                                <img src="../../../../images/Icons/course4.png" alt='' />
                                                <div className="course-overlay" >
                                                    <div className="showAfterhover">
                                                        <div className="coursevideo-back">
                                                            <img src="../../../../images/Icons/coursevideo.png" />
                                                        </div>
                                                        <div className="slider-desc">
                                                            <h3>Medical Emergencies</h3>
                                                            <span>5 lessons</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="course-cat-name">
                                                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="courses-slider" >
                                                <img src="../../../../images/Icons/course4.png" alt='' />
                                                <div className="course-overlay" >
                                                    <div className="showAfterhover">
                                                        <div className="coursevideo-back">
                                                            <img src="../../../../images/Icons/coursevideo.png" />
                                                        </div>
                                                        <div className="slider-desc">
                                                            <h3>Medical Emergencies</h3>
                                                            <span>5 lessons</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="course-cat-name">
                                                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab label="eCPD  ">
                                </Tab>
                                <Tab label="Endodontics ">
                                </Tab>
                                <Tab label="Restorative ">
                                </Tab>
                                <Tab label="Paediatrics ">
                                </Tab>
                                <Tab label="Periodontics ">
                                </Tab>
                            </Tabs>
                        </div>
                    </Tab>
                    <Tab label="Completed Courses ">

                    </Tab>
                    <Tab label="Book Marked Courses  ">

                    </Tab>
                    <Tab label="PDP Courses ">

                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default MyCoursesTabs;
