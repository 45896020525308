import React, { useEffect } from 'react';
import { Link } from "react-router-dom";



const LeftJournalSection = (props) => {
     if(!props.journals){
        return <></>
     } 

    return (

        <div className="left-section">
            <h3>How to Read Journal</h3>
            <p>{props.journals.description}</p> 
            <div className="video-box">
              <div className="embed-responsive embed-responsive-16by9">  
                    <iframe className="embed-responsive-item"
                            src={`https://player.vimeo.com/video/${props.video}`} frameborder="0"
                            allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
                {/* <img src='../../../../images/Icons/video-detail.png' alt='' />
                <div className="video-overlay-back">
                    <img src='../../../../images/Icons/coursevideo.png' alt='' /> 
                </div> */}
            </div>
        </div>
    );

}

export default LeftJournalSection;