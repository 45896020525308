import React, { useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import ApiService from '../../../helpers/ApiService';
import UrlResolver from '../../../helpers/UrlResolver';
import customConfirmDialog from '../../CustomConfirmDialog';
import SimpleButton from '../Buttons/SimpleButton';
import InputWithoutLabel from '../Input/InputWithoutLabel';
import SelectInput from '../Input/SelectInput';
import SimpleInput from '../Input/SimpleInput';


const EditPatientModal = (props) => {

    let { id } = props.patientId;

    let api = new ApiService();

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [filesPatients, setFiles] = useState(null);
    const [mentorUser, setUserMentor] = useState([]);

    const [Mentor, setMentor] = useState([{ value: '__select__', label: 'Select Mentor', isDisabled: true, }]);

    const Mentorship = [
        {value: '__select__', label: 'Mentor Speciality', isDisabled: true, },
        {value: 'Restorative', label: 'Restorative'},
        {value: 'Endodontics', label: 'Endodontics'},
        {value: 'Periodontics', label: 'Periodontics'},
        {value: 'Prosthodontics', label: 'Prosthodontics'},
        {value: 'Peadratric', label: 'Peadratric'},
        {value: 'Implants', label: 'Implants'},
    ]

    

    const Gender = [
        { value: '__select__', label: 'Gender', isDisabled: true, },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ]

    const handleChange = (event) => {
        let i = { ...input };
        i[event.target.name] = event.target.value;
        setInput(i);

        if(event.target.name === 'mentorshipType'){

            api.getMentor(event.target.value).then(_mentor => {
                setUserMentor(_mentor);
            if(_mentor) {
                    //console.log(mentorUser);
                let firstMentor = {
                    "value": "__select__",
                    "label": "Select Mentor",
                    "isDisabled": true
                }
        
                _mentor.unshift(firstMentor);
        
                function getUniqueListBy(arr, key) {
                    return [...new Map(arr.map(item => [item[key], item])).values()]
                }
        
                const arr1 = getUniqueListBy(_mentor, 'value');

                setMentor(arr1);}
            }).catch((e) => {
        
            });
        }
    }

    const handleFileChange = (event) => {
        //console.log(event.target.files);
        setFileData(event.target.files);
    }

    const fileDeleted = (fileId) => {
        customConfirmDialog("Are you sure", "Are you sure you want to delete this file?", () => {
            api.deletePatientfile(fileId).then(_filedelete => {
                api.getPatient(props.patientId).then(_cou => {
                    setFiles(_cou.files);
                }).catch((e) => {
        
                });
               
            }).catch((e) => {
                console.log(e);
            });
            
        }, "Yes", () => {

        }, "No")

    };

    useEffect(() => {

        window.scrollTo(0, 0);

        api.getPatient(props.patientId).then(_cou => {
            
            setFiles(_cou.files);
            if (_cou.mentor) {
                _cou.mentor = _cou.mentor.id
            }
            setInput({ ..._cou });
            
            if(_cou.mentorshipType !== '__select__'){
            
                api.getMentor(_cou.mentorshipType).then(_mentor => {
                    setUserMentor(_mentor);
                if(_mentor) {
                        //console.log(mentorUser);
                    let firstMentor = {
                        "value": "__select__",
                        "label": "Select Mentor",
                        "isDisabled": true
                    }
            
                    _mentor.unshift(firstMentor);
            
                    function getUniqueListBy(arr, key) {
                        return [...new Map(arr.map(item => [item[key], item])).values()]
                    }
            
                    const arr1 = getUniqueListBy(_mentor, 'value');

                    setMentor(arr1);}
                }).catch((e) => {
            
                });}

          
       
        }).catch((e) => {

        });

    }, [])



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validate()) {
            setLoading(true);

            if(fileData.length < 1){
                var data = {
                    mentorshipType: input.mentorshipType,
                    age: input.age,
                    gender: input.gender,
                    patientName: input.patientName,
                    patientInfromation: input.patientInfromation,
                    question: input.question,
                    mentor: input.mentor,
                    pid: parseInt(props.patientId)
                }

                //console.log(data);

                api.editPatient(data)
                .then(response => {
                    //console.log(response);
                    //alert("updated");
                    setLoading(false);
                    window.location.reload();
                }).catch(errors => {
                    //console.log(errors);
                    setErrors({ error: errors.message[0].messages[0].message });
                    setLoading(false);
                })

            } else {
                const formData = new FormData();

            Array.from(fileData).forEach(file => {
                formData.append('files', file);
            });

            api.uploadFileCertificate(formData).then(response => {

                let ids = [];

                Array.from(filesPatients).forEach(data => {
                    ids.push(data.id);
                });

                Array.from(response).forEach(res => {
                    //console.log(res.id);

                    ids.push(res.id);

                });

               var data = {
                    mentorshipType: input.mentorshipType,
                    age: input.age,
                    gender: input.gender,
                    patientName: input.patientName,
                    patientInfromation: input.patientInfromation,
                    question: input.question,
                    mentor: input.mentor,
                    files: ids,
                    pid: parseInt(props.patientId)
                }

                //console.log(data);

                api.editPatient(data)
                .then(response => {
                    //console.log(response);
                    //alert("updated");
                    setLoading(false);
                    window.location.reload();
                }).catch(errors => {
                    //console.log(errors);
                    setErrors({ error: errors.message[0].messages[0].message });
                    setLoading(false);
                })

            }).catch(errors => {

                //console.log(errors.data.errors[0].message);
                setErrors({ error: errors.data.errors[0].message });
            });
            }
             
        }
    }

    const validate = () => {
        let errors = {};
        let isValid = true;


        if (!input["mentorshipType"]) {
            isValid = false;
            errors["mentorship"] = "Please Select mentorship type";
        } else if (input["mentorshipType"] === '__select__') {
            isValid = false;
            errors["mentorship"] = "Please Select mentorship type";
        }

        if (!input["mentor"]) {
            isValid = false;
            errors["mentor"] = "Please Select mentor";
        } else if (input["mentor"] === '__select__') {
            isValid = false;
            errors["mentor"] = "Please Select Mentor";
        }

        if (!input["age"]) {
            isValid = false;
            errors["age"] = "Please enter age.";
        }

        if (!input["gender"]) {
            isValid = false;
            errors["gender"] = "Please Select gender";
        } else if (input["gender"] === '__select__') {
            isValid = false;
            errors["gender"] = "Please Select Gender";
        }

        if (!input["patientName"]) {
            isValid = false;
            errors["patientName"] = "Please enter patient name.";
        }



        setErrors(errors);
        return isValid;
    }

    if(!filesPatients){
       return <></>;
    }

    return (
        <div className="dark">
            <div className="d-flex align-items-center justify-content-between patient-header">
                <div className="pay-card">
                    <span>Edit Patient</span>
                </div>
                <div className="cross-photo" onClick={props.close}>
                    <img src="../../../../images/Icons/closs-icon.svg" />
                </div>
            </div>
            <div className='edit-scroll'>
            <Form onSubmit={handleSubmit}>
                {errors.error && <div className="donthave-acc text-center mt-3"><h4>{errors.error}</h4></div>}
                <div className="simple-input form-group">
                    <SelectInput
                        placeholder="Type of mentorship required"
                        isSearchable={false}
                        keepValue={true}
                        value={input.mentorshipType}
                        options={Mentorship}
                        name="mentorshipType"
                        className={errors.mentorship ? "form-control error" : "form-control"}
                        onChange={handleChange}
                    />
                </div>
                <div className="simple-input form-group">
                    <SelectInput
                        placeholder="Mentor"
                        isSearchable={false}
                        keepValue={true}
                        value={input.mentor}
                        options={Mentor}
                        name="mentor"
                        className={errors.mentor ? "form-control error" : "form-control"}
                        onChange={handleChange}
                        errors={errors.mentor}
                    />
                </div>
                
                <SimpleInput
                    className={errors.age ? "form-control error" : "form-control"}
                    type="text"
                    name="age"
                    placeholder="Enter Age"
                    value={input.age}
                    onChange={handleChange}
                />
                <div className="simple-input form-group">
                    <SelectInput
                        placeholder="Gender"
                        isSearchable={false}
                        keepValue={true}
                        value={input.gender}
                        options={Gender}
                        name="gender"
                        className={errors.gender ? "form-control error" : "form-control"}
                        onChange={handleChange}
                    />
                </div>
                <InputWithoutLabel
                    type='text'
                    placeholder='Patient name'
                    className='form-control'
                    icon='../../../../images/Icons/tooltip.svg'
                    tooltiptext='Please do not use patients real name, Please give
                    the patient a code e.g. 1x'
                    name="patientName"
                    value={input.patientName}
                    onChange={handleChange}
                    errors={errors.patientName}

                />
                <SimpleInput
                    type="text"
                    placeholder="Patient information, please be as thorough as possible"
                    name="patientInfromation"
                    className={errors.patientInfromation ? "form-control error" : "form-control"}
                    onChange={handleChange}
                    value={input.patientInfromation}
                />
                <InputWithoutLabel
                    type='text'
                    placeholder='Questions'
                    className='form-control'
                    icon='../../../../images/Icons/tooltip.svg'
                    tooltiptext='Please be specific and ask in bullet point format'
                    name="question"
                    value={input.question}
                    onChange={handleChange}
                    errors={errors.question} 

                />
                <div className='edit-photos'>
                {filesPatients.map((file, index) => {
                    return <div className='edit-col'>
                            {file.mime === 'image/jpeg' || file.mime === 'image/png' || file.mime === 'image/jpg' ?
                                <div className='file-image-lighter1' onClick={() => fileDeleted(`${file.id}`)}>
                                    <img src={UrlResolver.generateMediaUrl(file.url)} alt={file.hash}/>
                                    <div className='remove-overlay'>
                                        <img src='../../../../images/Icons/remove-icon.png' />
                                    </div>
                                </div>
                                :
                                 <div className='file-image-lighter2' onClick={() => fileDeleted(`${file.id}`)}>
                                    <img src='../../../../images/Icons/video-detail.png' />
                                    <div className='remove-overlay'>
                                        <img src='../../../../images/Icons/remove-icon.png' />
                                    </div>
                                </div>
                            }
                        </div>
                    })}
                </div>
                <div className="simple-input">
                    <input type="file" className={errors.files ? "form-control error" : "form-control"} name="files" onChange={handleFileChange} placeholder="Choose file"
                        style={{ padding: 0 }} multiple />
                </div>

                <div className="button-col payment">
                    <SimpleButton
                        name="Upload"
                        onClick={handleSubmit}
                        loading={loading}
                    />
                </div>
            </Form>
        </div>
        </div >
    );
}

export default EditPatientModal;
