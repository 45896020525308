import React from 'react';
import {Link} from "react-router-dom";

const UserDrop =(props)=> {

    let maxObj = 0;
    
    if(!props.userplan){
        return <></>  
      } else {
          maxObj = props.userplan;
      }

    const handleLogout = (event) => {

        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        localStorage.clear();
        window.location.assign("/")
      }

        return (
            <div className="user-drop-box">
                <ul>
                    <li className="border-none">
                        <Link>Notifications</Link>
                    </li>
                    <li>
                        <Link>Messages</Link>
                    </li> 
                    <li>
                        <Link>Personal Training Dashboard </Link>
                    </li>
                    <li>
                        <Link>PDP Hub</Link>
                    </li>
                    {maxObj && maxObj !== 0 && maxObj.subscription === 4 &&
                    <li>
                        <Link to='/mentorshipdashboard'>Mentorship Dashboard </Link>
                    </li>
                    }
                    <li>
                        <Link to="/certificates">My Certificates </Link>
                    </li>
                    <li>
                        <Link to="/profile">My Profile </Link>
                    </li>
                    <li>
                        <Link onClick={handleLogout}>Logout </Link>
                    </li>
                </ul>
            </div>
        );
}

export default UserDrop;