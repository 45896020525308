import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';



const PdpBoxesList = (props) => {

    return (
        <div className="pdp-boxes-list">
            <div className="col-md-4">
                <div className="pdp-boxes">
                    <h3>Create new plan</h3>
                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim aliqua dolor do amet sint. Velit officia consequat duis enim</p>
                    <Link to="/planing">Create new plan</Link>
                </div>
            </div>
            <div className="col-md-4">
                <div className="pdp-boxes">
                    <h3>Current PDP</h3>
                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim aliqua dolor do amet sint. Velit officia consequat duis enim</p>
                    <Link to="/choosepdp">Current PDP</Link>
                </div>
            </div>
            <div className="col-md-4 borderright" >
                <div className="pdp-boxes">
                    <h3>Previous PDP learning cycles</h3>
                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim aliqua dolor do amet sint. Velit officia consequat duis enim</p>
                    <Link to="/previouslearning">Previous PDP learning cycles</Link>
                </div>
            </div>
            <div className="col-md-4 borderbottom" >
                <div className="pdp-boxes">
                    <h3>Add external CPD to PDP plan</h3>
                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim aliqua dolor do amet sint. Velit officia consequat duis enim</p>
                    <Link to="/externalpdp">Add external CPD to PDP plan</Link>
                </div>
            </div>
            <div className="col-md-4 borderbottom " >
                <div className="pdp-boxes">
                    <h3>Annual GDC CPD statement</h3>
                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim aliqua dolor do amet sint. Velit officia consequat duis enim</p>
                    <Link to="/gdcannual">Annual GDC CPD statement</Link>
                </div>
            </div>
            <div className="col-md-4 borderright borderbottom" >
                <div className="pdp-boxes">
                    <h3>5 Year cycel CPD statement</h3>
                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim aliqua dolor do amet sint. Velit officia consequat duis enim</p>
                    <Link to="/gdc5year">5 Year cycel CPD statement</Link>
                </div>
            </div>
        </div>
    );
}

export default PdpBoxesList;
