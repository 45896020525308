import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';

const PreviousLearningBreadcrumbs = (props) => {

    return (
        <div className="breadcrumbs-section">
            <div className="container">
                <div className="category-bredcrumbs">
                    <div className="breadcums-info">
                        <div className="breadcrumbs-arrow">
                            <Link to="">
                                <img src="../../../../images/Icons/bredcumes-back.png" />
                            </Link>
                        </div>
                        <div className="d-flex align-items-center breadcrums-titles">
                            <Link to="/">Home </Link>
                            <Link> / Personal Training dashboard</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreviousLearningBreadcrumbs;
