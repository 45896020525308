import React, {useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import MainHeader from '../../components/Header/MainHeader';
import QuizBreadcrumbs from './component/QuizBredcrumbs';
import QuizItems from './component/QuizItems';
import ApiService from '../../../helpers/ApiService';
import {Link, useParams} from "react-router-dom";

const Quiz =(props)=> {

    const [coures,setCourses] = React.useState(null);

    let { id } = useParams();

    useEffect(async ()=>{

        let api = new ApiService();

        api.getQuizs(id).then(_quiz => {
            setCourses(_quiz);
         }).catch((e) => {
     
         });

    },[])
    if(!coures) {
        return <></>
    }
    return (
        <>
            <MainHeader/>
            <QuizBreadcrumbs courses={coures}/>
            <QuizItems courses={coures}/>
            <Footer/>
        </>
    );

}

export default Quiz;